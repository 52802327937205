import React, { useState, useEffect } from 'react'
import { EditShape, Cross, SearchIcon } from '../../../assets/images'
import DataAccessGroup from '../DataAccess/DataAccessGroup';
import AddUserForm from './AddUserForm';
import AssignFRsToSiteGroup from './AssignFRsToSiteGroup';
import { useLocation } from 'react-router-dom';
import { CONFIG_DATACCESSGROUP, CONFIG_FR, CONFIG_USER } from '../../../navigation/CONSTANTS';


export default function UserTemplateStep({
    pageState,
    users,
    setSelectedDataAccessGroupId,
    userGroups,
    onSaveUser,
    selectedUser,
    onSelectUser,
    setIsNotValidPassword,
    setChangePassword,
    setIsPassUpdated,
    onMoveNext,
    isUserEdit,
    setIsUserEdit,
    siteRef,
    usersToBind,
    selectedUsers,
    setSelectedUsers,
    onAddSelectedUsers,
    onAddNewUserGroup,
    onUpdateUserGroup,
    filteredUsers,
    onSearchChange,
    selectedUserTypeList,
    setselectedUserTypeList,
    filteredUserGroups,
    siteGroups,
    siteGroupOnClick,
    selectedSiteGroup,
    workflows,
    nodes,
    setNodes,
    onLoad,
    editMode,
    setEditMode,
    isNodeSelected,
    setIsNodeSelected,
    setSelectedNode,
    selectedNode,
    disableAdd,
    setDisableAdd,
    onNavigate,
    setIsItemClick,
    setSelectedSubMenu,
    setIsUserVerified,
    isDarkTheme,
    validationError,
    savebtnClick,
    setSavebtnClick,
    buttonDisable,
    setButtonDisable,
    setTimezome,
    timezone,
    setPageState,
    availableModules,
    setAvailableModules
}) {

    //region useState start
    const [issearch, setissearch] = useState(true)
    const location = useLocation()
    //region useState end

    function toggleSearch() {
        try {
            setissearch(!issearch)
            document.getElementById("Search_txt").value = ""
            onSearchChange("", selectedUserTypeList)
        } catch (error) {
            console.error(error)
        }
    }

    
    useEffect(() => {
        if(location.pathname === CONFIG_USER){
            setPageState('Add User')
            setSelectedSubMenu('Add User')
        } else if (location.pathname === CONFIG_FR) {
            setPageState('FR Assignment')
            setSelectedSubMenu('FR Assignment')
        } else if (location.pathname === CONFIG_DATACCESSGROUP) {
            setPageState('Data Access Group')
            setSelectedSubMenu('Data Access Group')
        }
    },[location.pathname])

    return (
        <>
            <div className="col-lg-12 mt-2" style={{ height: "calc(100% - 80px)" }}>
                {pageState == "Add User" &&
                    <AddUserForm
                        nodes={nodes}
                        setSelectedDataAccessGroupId={setSelectedDataAccessGroupId}
                        setNodes={setNodes}
                        onSubmitUser={onSaveUser}
                        user={selectedUser}
                        onSelectUser={onSelectUser}
                        isUserEdit={isUserEdit}
                        setIsUserEdit={setIsUserEdit}
                        ref={siteRef}
                        workflows={workflows}
                        onNavigate={onNavigate}
                        setIsItemClick={setIsItemClick}
                        setSelectedSubMenu={setSelectedSubMenu}
                        setIsNotValidPassword={setIsNotValidPassword}
                        setChangePassword={setChangePassword}
                        setIsPassUpdated={setIsPassUpdated}
                        setIsUserVerified={setIsUserVerified}
                        isDarkTheme={isDarkTheme}
                        filteredUsers={filteredUsers}
                        selectedUser={selectedUser}
                        onSearchChange={onSearchChange}
                        validationError={validationError}
                        savebtnClick={savebtnClick}
                        setSavebtnClick={setSavebtnClick}
                        buttonDisable={buttonDisable}
                        setButtonDisable={setButtonDisable}
                        timezone={timezone}
                        setTimezome={setTimezome}
                        availableModules={availableModules}
                        setAvailableModules={setAvailableModules}
                    />
                }
                {
                    pageState == "FR Assignment" &&
                    <AssignFRsToSiteGroup
                        userGroups={userGroups}
                        onFinish={onMoveNext}
                        usersToBind={usersToBind}
                        selectedUsers={selectedUsers}
                        setSelectedUsers={setSelectedUsers}
                        onAddSelectedUsers={onAddSelectedUsers}
                        onAddNewUserGroup={onAddNewUserGroup}
                        onUpdateUserGroup={onUpdateUserGroup}
                        siteGroups={siteGroups}
                        users={users}
                        siteGroupOnClick={siteGroupOnClick}
                        selectedSiteGroup={selectedSiteGroup}
                    />
                }
                {pageState == "Data Access Group" && (
                    <DataAccessGroup workflows={workflows}
                        nodes={nodes}
                        setNodes={setNodes}
                        onLoad={onLoad}
                        isNodeSelected={isNodeSelected}
                        setIsNodeSelected={setIsNodeSelected}
                        setSelectedNode={setSelectedNode}
                        selectedNode={selectedNode}
                        disableAdd={disableAdd}
                        setDisableAdd={setDisableAdd}
                        editMode={editMode}
                        setEditMode={setEditMode}
                        isDarkTheme={isDarkTheme}
                        setButtonDisable={setButtonDisable}
                        buttonDisable={buttonDisable}
                    />
                )}
                {
                    pageState == "Data Access Group" || pageState == "Add User" ? null : (
                        <div className="RightListSide">
                            <div className="HeaderSiteList m-2">
                                {issearch && <p className={selectedUserTypeList == "User List" ? "active-all-light active-block active-text button-common-margin" : "inactive-text button-common-margin"}
                                    onClick={() => setselectedUserTypeList('User List')}>User List</p>}

                                {!issearch &&
                                    <input autoFocus id="Search_txt" className="SearchViewInput1" type="text"
                                        onChange={e => onSearchChange(e.target.value, selectedUserTypeList)} placeholder="Search User" />
                                }

                                <div className="SearchTextDivConfigTool">
                                    {issearch && <img className="EyeIcon" alt='search icon' src={SearchIcon} onClick={() => toggleSearch()} />}
                                    {!issearch && <img className="EyeIcon" src={Cross} alt='cancel icon' onClick={() => toggleSearch()} />}
                                </div>
                            </div>
                            <div className="SiteListMain">
                                {selectedUserTypeList && selectedUserTypeList == "User List" &&
                                    filteredUsers && (
                                        filteredUsers?.length > 0 ?
                                            filteredUsers.map(item => {
                                                return <div className={selectedUser?.Email === item.Email ? "OneList SelectedList" : "OneList"} key={item.UserID} onClick={() => onSelectUser(item)}>
                                                    <p className="ListofText">{item.FirstName || item.FullName}
                                                        <span className="SiteNameText"> - {item.RoleName || item.roleName}</span></p>

                                                    <div>
                                                        <img className="m-2" src={EditShape} alt="edit icon" />
                                                    </div>
                                                </div>
                                            }) : <div className="no-data-found"><p>No User Found.</p></div>
                                    )
                                }

                                {selectedUserTypeList && selectedUserTypeList == "User List" && !filteredUsers &&
                                    <div class="no-report-found"><p class="noRecordFoundText">No Users Available.</p></div>

                                }

                                {selectedUserTypeList && selectedUserTypeList == "User Group" &&
                                    filteredUserGroups && filteredUserGroups.map(item => {
                                        return <div className="OneList" key={item.userGroupID} onClick={() => onSelectUser(item)}>
                                            <p className="ListofText">{item.groupTitle}
                                            </p>
                                            <div>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    )
                }
            </div>
        </>
    )
}
