import React from 'react'
import ColumnChart from './Common/ColumnChart'
import DonutChart from './Common/DonutChart'
import PieChart from './Common/PieChart'
import LineChart from './Common/LineChart'
import StackChart from './Common/StackChart'
import GroupChart from './Common/GroupChart'
import MultiLineChart from './Common/MultiLineChart'
import { HorizontalColumnChart } from './Common/HorizontalColumnChart'

const DashboardModule = () => {
  return (
    <div className='MainContentMargin'>  
        <div className='dashboardScroll mt-3' style={{height:"calc(100vh - 100px)",overflow:"auto",padding:"10px"}}>
            <div style={{display:"flex", flexWrap:"wrap", gap:"15px"}}>
                <div className="systemHealth m-0" style={{flex: "1 1 0", minWidth:"30%"}} >
                    <ColumnChart/>
                </div>

                <div className="systemHealth m-0" style={{flex: "1 1 0",minWidth:"30%"}}>
                    <DonutChart/>
                </div>

                <div className="systemHealth m-0" style={{flex: "1 1 0", minWidth:"30%"}}>
                    <PieChart/>
                </div>
                <div className="systemHealth m-0" style={{flex: "1 1 0", minWidth:"30%"}}>
                    <LineChart/>
                </div>

                <div className="systemHealth m-0" style={{flex: "1 1 0", minWidth:"30%"}}>
                    <StackChart/>
                </div>

                <div className="systemHealth m-0"  style={{flex: "1 1 0", minWidth:"30%"}}>
                    <GroupChart/>
                </div>

                <div className="systemHealth m-0" style={{flex: "1 1 0", minWidth:"30%"}}>
                    <MultiLineChart/>
                </div>

                <div className="systemHealth m-0" style={{flex: "1 1 0", minWidth:"30%"}}>
                    <HorizontalColumnChart/>
                </div>
            </div>
        </div>
    </div>
  )
}

export default DashboardModule