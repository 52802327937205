import React from 'react'
import { Pie } from 'react-chartjs-2'
import { FilterClearIcon } from '../../../assets/images';

const PieChart = () => {
  const data = {
    labels: [
      'Red',
      'Blue',
      'Yellow'
    ],
    datasets: [{
      label: 'My First Dataset',
      data: [300, 50, 100],
      backgroundColor: [
        'rgb(255, 99, 132)',
        'rgb(54, 162, 235)',
        'rgb(255, 205, 86)'
      ],
      hoverOffset: 4
    }]
  };

  const config = {
    type: 'pie',
    data: data,
  };


  return (
    <div style={{height:"100%"}}>
        <div className='ChartHeader'>
            <p>Pie Chart</p>
            <button style={{background:"transparent"}}>
            <img src={FilterClearIcon} alt="Clear" />
            </button>
        </div>
        <div style={{padding:"10px", height:"90%", display:"flex", justifyContent:"center"}}>
            <Pie config={config} data={data}/>
        </div>
    </div>
  )
}

export default PieChart