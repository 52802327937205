import React from 'react'
import { Bar } from 'react-chartjs-2';
import { FilterClearIcon } from '../../../assets/images';

const GroupChart = (props) => {
  let { isDarkTheme } = props;

  const data = {    
    labels: ["Mon","Tues", "Wed", "Thurs", "Fri", "Sat", "Sun"],  
    datasets: [{
      label: 'Dataset1',
      data: [98, 59, 80, 14, 20, 55, 40],
      fill: false,
      backgroundColor: 'rgba(255, 99, 132)',
      borderWidth: 0,
      borderRadius: 5,
    },
    {
      label: 'Dataset2',
      data: [65, 10, 25, 55, 44, 55, 40],
      fill: false,
      backgroundColor: 'rgba(255, 159, 64)',
      borderWidth: 0,
      borderRadius: 5,
    },
    {
      label: 'Dataset3',
      data: [45, 59, 10, 65, 56, 30, 4],
      fill: false,
      backgroundColor: 'rgba(255, 205, 86)',
      borderWidth: 0,
      borderRadius: 5,
    }
  ]
}

const config = {
  type: 'bar',
  data: data,
  options: {
    scales: {
      y: {
        beginAtZero: true
      }
    }
  },
};

const option = {
      responsive: true,
      maintainAspectRatio: false,
      interaction :{
          mode:'index',
      },
      plugins: {
          legend: {
              display: false,
          },
          tooltip: {
            display:true,
          }
      },
      scales: {
        x: {
            stacked: false,
            border: {
                display: true,
                color: isDarkTheme ? '#ffffff' : "#e6e6e6",
              },
            grid:{
                display: false,
                color:"red",
                borderColor: "red"
            },
            title:{
                display:true,
                //text:xLabel,
                text:"Day",
                color: isDarkTheme ? "#ffffff" : "#000000",
            },
            ticks: {
                color: isDarkTheme ? "" : "#bababa",
            }, 
        },
        y: {
            stacked: false,
            border: {
                display: true,
                color: isDarkTheme ? '#ffffff' : "#e6e6e6",
              },
            beginAtZero: true,
            title:{
                display:true,
                text:"Status in hours",
                //text:yLabel,
                color: isDarkTheme ? "#ffffff" : "#000000",
            },
            grid:{
                display:false,
                color:"#faf7f7"
            },
            ticks: {
                color: isDarkTheme ? "" : "#bababa",
            },
        },
        
    },
}
  return (
    <div style={{height:"100%"}}>
        <div className='ChartHeader'>
            <p>Group Bar Chart</p>
            <button style={{background:"transparent"}}>
            <img src={FilterClearIcon} alt="Clear" />
            </button>
        </div>
        <div style={{padding:"10px", height:"90%"}}>
          <Bar options={option} data={data}/>
        </div>
    </div>
  )
}

export default GroupChart