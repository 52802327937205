import React from 'react'
import { Bar } from 'react-chartjs-2';
import { FilterClearIcon } from '../../../assets/images';

const StackChart = (props) => {
    let { isDarkTheme } = props;
    const data = {    
        labels: ["Mon","Tues", "Wed", "Thurs", "Fri", "Sat", "Sun"],  
        datasets: [
            {
            label:  'Online',
            // backgroundColor: "#8BB0F4",
            backgroundColor: "#7BB526",
            data: [14, 2, 10, 6, 12, 16,15],
            barPercentage: 0.3,
            categoryPercentage: 1,
            borderRadius: 5,
        },
        {
            label:  'Offline',
            // backgroundColor: "#6C6C6C",
            backgroundColor: "#F32C22",
            data: [2, 21, 13, 3, 24, 7,3],
            barPercentage: 0.3,
            categoryPercentage: 1,
            borderRadius: 5,        
        }],
    }                         

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        interaction :{
            mode:'index',
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
              display:true,
            }
        },
        scales: {
            x: {
                stacked: true,
                border: {
                    display: true,
                    color: isDarkTheme ? '#ffffff' : "#e6e6e6",
                  },
                grid:{
                    display: false,
                    color:"red",
                    borderColor: "red"
                },
                title:{
                    display:true,
                    //text:xLabel,
                    text:"Day",
                    color: isDarkTheme ? "#ffffff" : "#000000",
                },
                ticks: {
                    color: isDarkTheme ? "" : "#bababa",
                }, 
            },
            y: {
                stacked: true,
                border: {
                    display: true,
                    color: isDarkTheme ? '#ffffff' : "#e6e6e6",
                  },
                beginAtZero: true,
                title:{
                    display:true,
                    text:"Status in hours",
                    //text:yLabel,
                    color: isDarkTheme ? "#ffffff" : "#000000",
                },
                grid:{
                    display:false,
                    color:"#faf7f7"
                },
                ticks: {
                    color: isDarkTheme ? "" : "#bababa",
                },
            },
            
        },
    }
  return (
      <div style={{height:"100%"}}>
          <div className='ChartHeader'>
              <p>Stack Chart</p>
              <button style={{background:"transparent"}}>
                <img src={FilterClearIcon} alt="Clear" />
                </button>
          </div>
          <div style={{padding:"10px", height:"90%"}}>
            <Bar options={options} data={data}/>
          </div>
      </div>
  )
}

export default StackChart