import React, { useEffect, useRef, useState } from 'react'

import { AlertDetailIcon, ArrowLeft, ArrowRight, CarousalSelectedBlue, CarousalUnselected, ClearFilter, DeleteIcon, ExitFullscreen, ExitStretchVideo, FilterClearIcon, FilterIcon, InfoIcon1, LoadingAlert, MosaicExitFullscreen, MosaicFullscreen, NoMoreAlert, RefreshBtnIcon, RemoveIcon, SaveFilter, StretchVideo, WarningDelete, BlueBackgroundImg, KPI_ImgAlertGrid, RightArrowAlertGrid, KPI_AlertGrid, LeftArrowAlertGrid, BlueBackgroundLeft } from '../../assets/images';

import FilterToolDDLTemp from '../AlertViewNew/FilterToolDDLTemp';

import { getAlertDetailsById, getAlertList } from '../../services';
import { addrasaconversationindb } from '../../services/chatFlowService';
import { deviceconfigcrud } from '../../services/configurationServices';
import { closedAlertGridBtn, DeleteFilter, FilterSaved, getCustomerList, GetFilter, getFilterAlertTypeList, getInfoList, getLatestAlertStatus, getSeverity, getSiteList } from '../../services/alertGridServices';

import AlertTemplateMessage from '../Configuration/AlertTemplate/AlertTemplateMessage';
import ChatflowContainer from '../Chatflow/ChatflowContainer';
import WebRTCPlayer from '../Cameras/WebRTCPlayer';

import { ConvertDate, multiTimeZoneHelper } from '../../utils/utils';
import { ALERT_INTERVAL_TIMER, AUTO_ALERT_CLOSE, CHAT_CAM_CHANGES, LOAD_BALANCE, MULTI_TIME_ZONE } from '../../config';

import { Modal } from "react-bootstrap";
import { Tooltip, OverlayTrigger, Dropdown } from 'react-bootstrap';
import AliceCarousel from 'react-alice-carousel';
import { toast, ToastContainer } from 'react-toastify';
import Swal from 'sweetalert2';

import { ErrorToast } from '../../CustomHooks/ErrorToast';
import { SuccessToast } from '../../CustomHooks/SuccessToast';
import { WarningToast } from '../../CustomHooks/WarningToast';
import InfoComponent from './Common/InfoComponent';


export default function AlertMosaicView1({ associateCam, setAssociateCam, isDarkTheme }) {
  const options = [
    { label: "Grapes 🍇", value: "grapes" },
    { label: "Mango 🥭", value: "mango" },
    { label: "Strawberry 🍓", value: "strawberry" },
  ];

  const buttonRef = useRef(null);

  //#region useState 
  const [selected, setSelected] = useState([]);
  const [alserMosaicOption, setAlertMosaicOption] = useState(1) // option value
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [masterData, setMasterData] = useState([]);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [siteOptions, setSiteOptions] = useState([]);
  const [deviceTypeOptions, setDeviceTypeOptions] = useState([]);
  const [alertTypeOptions, setAlertTypeOptions] = useState([]);
  const [severityOptions, setSeverityOptions] = useState([]);
  const [customerSelected, setCustomerSelected] = useState([]);
  const [siteSelected, setSiteSelected] = useState([]);
  const [deviceTypeSelected, setDeviceTypeSelected] = useState([]);
  const [alertTypeSelected, setAlertTypeSelected] = useState([]);
  const [severitySelected, setSeveritySelected] = useState([]);
  const [deviceNameSelected, setDeviceNameSelected] = useState([]);
  const [singleAlertDet, setSingleAlertDet] = useState(null);
  const [updateChatflow, setUpdateChatflow] = useState(0);
  const [imageData, setImageData] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [filterName, setFilterName] = useState("")
  const [alertInProgress, setAlertInProgress] = useState(false)
  const [shouldPollData, setShouldPollData] = useState(false);
  const [switchAlertModal, setSwitchAlertModal] = useState(false);
  const [alertClicked, setAlertClicked] = useState("");
  const messagesEndRef = useRef(null);
  const [singleAlertDetChatFlow, setSingleAlertDetChatFlow] = useState(null)
  const [filterList, setFilterList] = useState([])
  const [saveDisabled, setSaveDisabled] = useState(false)
  const [openInfoFilter, setOpenInfoFilter] = useState(false);
  const [openDeleteFilter, setOpenDeleteFilter] = useState(false);
  const [selectedFilterName, setSelectedFilterName] = useState("")
  const [selectedFilter, setSelectedFilter] = useState(0);
  const [customerString, setCustomerString] = useState("")
  const [siteString, setSiteString] = useState("")
  const [deviceTypeString, setDeviceTypeString] = useState("")
  const [alertTypeString, setAlertTypeString] = useState("")
  const [severityString, setSeverityString] = useState("")
  const [statusString, setStatusString] = useState("1,2")
  const [modalFilterName, setModalFilterName] = useState("")
  const [modalFilterId, setModalFilterId] = useState(0)
  const [maxAlertId, setMaxAlertId] = useState(0);
  const [associateData, setAssociateData] = useState([]);
  const [filterApplied, setFilterApplied] = useState(false);
  const [customerMasterArr, setCustomerMasterArr] = useState([])
  const [customerInfoArr, setCustomerInfoArr] = useState([])
  const [siteMasterArr, setSiteMasterArr] = useState([])
  const [siteInfoArr, setSiteInfoArr] = useState([])
  const [deviceTypeMasterArr, setDeviceTypeMasterArr] = useState([])
  const [devieceTypeInfoArr, setDeviceTypeInfoArr] = useState([])
  const [alertTypeMasterArr, setalertTypeMasterArr] = useState([])
  const [alertTypeInfoArr, setAlertTypeInfoArr] = useState([])
  const [alertSeverityMasterArr, setAlertSeverityMasterArr] = useState([])
  const [alertSeverityInfoArr, setAlertSeverityInfoArr] = useState([])
  const [customerSearch, setCustomerSearch] = useState("")
  const [siteSearch, setSiteSearch] = useState("")
  const [deviceTypeSearch, setDeviceTypeSearch] = useState("")
  const [alertTypeSearch, setAlertTypeSearch] = useState("")
  const [alertSeveritySearch, setAlertSeveritySearch] = useState("")
  const [alertToClose, setAlertToClose] = useState([]);
  const [stretchVideo, setStretchVideo] = useState(false)
    
  const [showFirstDiv, setShowFirstDiv] = useState(true);

  const [countPending, setCountPending] = useState(0);
  const [countServed, setCountServed] = useState(0);
  const [clear, setClear] = useState(false);
  
  //#endregion useState end


  const [isFullScreen, setIsFullScreen] = useState(false);
  const currentWindowHeightRef = useRef(window.innerHeight);
  const btnRef = useRef(null);

  const getRequestFullScreen = () => {
    return (
      document.documentElement.requestFullscreen ||
      document.documentElement["mozRequestFullscreen"] ||
      document.documentElement["msRequestFullscreen"] ||
      document.documentElement["webkitRequestFullscreen"]
    );
  };

  const getExitFullscreen = () => {
    return (
      document["webkitExitFullscreen"] ||
      document["msExitFullscreen"] ||
      document.exitFullscreen
    );
  };

  const useCustomFullScreen = (event) => {
    if (event.key === "F11") {
      event.preventDefault();
      toggleFullScreen();
    }
  };

  const isExitingFullScreen = () => {
    if (isFullScreen && currentWindowHeightRef.current >= window.innerHeight) {
      setIsFullScreen(false);
      currentWindowHeightRef.current = window.innerHeight;

      updateBtn();
    }
  };

  const updateBtn = () => {
    if (isFullScreen) {
      btnRef.current.classList.remove("enter-fullscreen");
      btnRef.current.classList.add("exit-fullscreen");
    } else {
      btnRef.current.classList.remove("exit-fullscreen");
      btnRef.current.classList.add("enter-fullscreen");
    }
  };

  const toggleFullScreen = () => {
    if (!isFullScreen && getRequestFullScreen()) {
      getRequestFullScreen().call(document.documentElement);
      showToastMsg("Fullscreen mode active")
    } else {
      getExitFullscreen().call(document);
      showToastMsg("Fullscreen mode exited")
    }

    currentWindowHeightRef.current = window.innerHeight;
    setIsFullScreen(!isFullScreen);

    // Do your full screen mode stuff
    updateBtn();
  };

  useEffect(() => {
    window.addEventListener("keydown", useCustomFullScreen);
    window.addEventListener("resize", isExitingFullScreen);

    return () => {
      window.removeEventListener("keydown", useCustomFullScreen);
      window.removeEventListener("resize", isExitingFullScreen);
    };
  }, []);

  const CLIENT = "Client"
  const SITE = "Site"
  const DEVICE_TYPE = "Device Type"
  const ALERT_TYPE = "Alert Type"
  const ALERT_SEVERITY = "Alert Severity"

  const filterToolDDLProps = {
    visibleSavedfilter: false,
    savedFilterProps: {
      labelledBy: "Saved Filter",
      options: options,
      value: [],
      onChange: (value) => setSelected(value),
      valueRenderer: null
    },
    filterList: [
      {
        labelledBy: "Customer",
        options: customerOptions,
        value: customerSelected,
        onChange: (value) => setCustomerSelected(value),
        valueRenderer: null,
        className: customerSelected.length > 0 ? "dropdownSelectionContainer multi-select" : "multi-select",
      },
      {
        labelledBy: "Site",
        options: siteOptions,
        value: siteSelected,
        onChange: (value) => setSiteSelected(value),
        valueRenderer: null,
        className: siteSelected.length > 0 ? "dropdownSelectionContainer multi-select" : "multi-select",
      },
      {
        labelledBy: "Device Type",
        options: deviceTypeOptions,
        value: deviceTypeSelected,
        onChange: (value) => setDeviceTypeSelected(value),
        valueRenderer: null,
        className: deviceTypeSelected.length > 0 ? "dropdownSelectionContainer multi-select" : "multi-select",
      },
      {
        labelledBy: "Alert Type",
        options: alertTypeOptions,
        value: alertTypeSelected,
        onChange: (value) => setAlertTypeSelected(value),
        valueRenderer: null,
        className: alertTypeSelected.length > 0 ? "dropdownSelectionContainer multi-select" : "multi-select",
      },
      {
        labelledBy: "Alert Severtity",
        options: severityOptions,
        value: severitySelected,
        onChange: (value) => setSeveritySelected(value),
        valueRenderer: null,
        className: severitySelected.length > 0 ? "dropdownSelectionContainer multi-select" : "multi-select",
      },
    ]
  }

  //#region useEffect start
  useEffect(() => {
    if (selectedFilter != 0) {
      let selected = filterList.find((item) => item.FilterId == selectedFilter);
      if (selected != null) {
        extractIds(selected.FilterValues);
      }
    }
  }, [selectedFilter]);

  useEffect(() => {
    // API Call for customer list
    getCustomerList()
      .then((res) => {
        if (res.Status == "success") {
          let arr = res.Data.map((item) => ({
            value: item.ClientId,
            label: item.ClientName
          }))
          setCustomerOptions(arr);
        }
      })
      .catch(err => console.log(err))

    // API Call for Severity List
    getSeverity()
      .then((res) => {
        if (res.Status == "success") {
          let arr = res.Data.map((item) => ({
            value: item.SeverityId,
            label: item.SeverityTitle
          }))
          setSeverityOptions(arr);
        }
      })
      .catch(err => console.log(err))

    // API Call for Device Type List
    getidsmasterslist();
    getInitialAlertData();
    getFilterList();
    getAlertType();
  }, [])

  useEffect(() => {
    if (shouldPollData) {
      getAlertData(alserMosaicOption, customerString, siteString, deviceTypeString, alertTypeString, "", severityString, maxAlertId);
    }
  }, [shouldPollData])


  // Auto close alert (Parent Component way) // Don't remove this code.
  // useEffect(() => {
  //   let timer;
  //   timer = setInterval(() => {
  //     if(AUTO_ALERT_CLOSE){
  //       console.log("masterDataCLOSEDALERTARR", masterData);
  //       // alertCloseBulk(masterData);
  //     }
  //   }, 10000);

  //   return () => {
  //     clearInterval(timer);
  //   }
  // },[masterData])

  // Auto close alert (Child Component way)
  useEffect(() => {
    let timer
    timer = setInterval(() => {
      if (AUTO_ALERT_CLOSE) {
        if (alertToClose.length > 0) {
          let arr = data.map((item) => {
            if (alertToClose.includes(item.alertId)) {
              return { "closed": true }
            }
            return item;
          })
          let loggedInUser = JSON.parse(localStorage.getItem('user'))
          Promise.all(alertToClose.map((i) => closedAlertGridBtn(i, loggedInUser?.UserID)))
            .then((res) => {
              if (res[0].Status == "success") {
                SuccessToast(`${alertToClose.length > 1 ? + alertToClose.length + " alerts" : "1 alert"} auto closed.`)
                setData(arr)
                setMasterData(arr)
                setShouldPollData(true);
                setAlertToClose([]);
              }
            })
        }
      }
    }, 5000)

    return () => {
      clearInterval(timer);
    }
  }, [alertToClose, masterData, data])

  useEffect(() => {
    if (customerSelected.length > 0) {
      // Call API for getting sitedata.
      let customerIds = customerSelected.map((item) => item.value).join(',');
      getSiteList(customerIds)
        .then((res) => {
          if (res.Status == "success") {
            let arr = res.Data.map((item) => ({
              value: item.SiteID,
              label: item.SiteName
            }))
            setSiteOptions(arr);
          }
        })
        .catch(err => console.log(err));
    } else {
      setSiteOptions([]);
    }
  }, [customerSelected])

  useEffect(() => {
    let timer = setInterval(() => {
      if (alserMosaicOption == 1) {
        //Logic based on 9 alerts
        let temp = checkClosedAlert(masterData)
        if (masterData.length < 9 || temp) {
          setShouldPollData(true);
        }
      } else {
        //Logic based on 16 alerts
        let temp = checkClosedAlert(masterData)
        if (masterData.length < 16 || temp) {
          setShouldPollData(true);
        }
      }
    }, ALERT_INTERVAL_TIMER);
    return () => {
      clearInterval(timer);
    }
  }, [masterData, alserMosaicOption]);

  useEffect(() => {
    if(!shouldPollData){
      let timer;
      if(clear){
        setClear(false)
        clearInterval(timer)
      }
  
      timer = setInterval(() => {
        getAlertGridCount();
      }, 60000)
  
      return () => {
        clearInterval(timer)
      }
    }
  }, [clear, shouldPollData])

  useEffect(() => {
    if (customerSelected.length > 0 || siteSelected.length > 0 || deviceTypeSelected.length > 0 || alertTypeSelected.length > 0 || severitySelected.length > 0) {
      setSaveDisabled(true)
      if (selectedFilterName != "") {
        setSelectedFilterName("")
        setSelectedFilter(0)
      } else {
        setSelectedFilterName("");
      }
    } else {
      setSaveDisabled(false)
    }
    if (customerSelected.length == 0 && siteSelected.length == 0 && deviceTypeSelected.length == 0 && alertTypeSelected.length == 0 && severitySelected == 0 && filterApplied) {
      setFilterApplied(false);
      setCustomerString("");
      setSiteString("");
      setDeviceTypeString("");
      setAlertTypeString("");
      setSeverityString("");
      getInitialAlertData(alserMosaicOption, "", "", "", "", "", "", "");
    }

  }, [customerSelected, siteSelected, deviceTypeSelected, alertTypeSelected, severitySelected])

  // useEffect(() => {
  //   const adjustContainerWidth = () => {
  //     const mainContainer = document.getElementById('mainContainer');
  //     const contents = mainContainer.children;
  //     let totalWidth = 0;

  //     for (let content of contents) {
  //       totalWidth += content.offsetWidth + 20;
  //     }

  //     setContainerWidth(Math.min(totalWidth, 1410));
  //   };

  //   // adjustContainerWidth();
  //   window.addEventListener('resize', adjustContainerWidth);

  //   return () => window.removeEventListener('resize', adjustContainerWidth);
  // }, []);

  //#endregion useEffect end

  //#region functions start api

  const getAlertType = () => {
    getFilterAlertTypeList()
      .then((res) => {
        if (res.Status == "success") {
          let arrTwo = res.Data.map((item) => ({
            value: item.AlertTypeId,
            label: item.AlertTypeName
          }))
          setAlertTypeOptions(arrTwo);
        }
      })
      .catch(err => console.log(err));
  }

  //#endregion

  //#region functions start component
  const checkClosedAlert = (arr) => {
    return arr.find(item => item.hasOwnProperty('noAlertFound') || item.hasOwnProperty('closed'));
  }

  //Function for calling api of devicetype list.
  const extractIds = (str) => {
    getDataFromFilter(str);
  }

  const getDataFromFilter = (str) => {
    let arr = str.split(";");
    let cString, sString, dString, aTString, aSString, statusString = "";
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].split(":")[0] == "Client") {
        cString = arr[i].split(":")[1]
      } else if (arr[i].split(":")[0] == "Site") {
        sString = arr[i].split(":")[1]
      } else if (arr[i].split(":")[0] == "DeviceType") {
        dString = arr[i].split(":")[1]
      } else if (arr[i].split(":")[0] == "AlertType") {
        aTString = arr[i].split(":")[1]
      } else if (arr[i].split(":")[0] == "AlertSeverity") {
        aSString = arr[i].split(":")[1]
      }
      else if (arr[i].split(":")[0] == "Status") {
        statusString = arr[i].split(":")[1]
      }
    }
    setCustomerString(cString);
    setSiteString(sString);
    setDeviceTypeString(dString);
    setAlertTypeString(aTString);
    setSeverityString(aSString);
    setStatusString(statusString);
    getInitialAlertData(alserMosaicOption, cString, sString, dString, aTString, "", aSString, true);
  }

  const getidsmasterslist = async () => {
    try {
      let loggedInUser = JSON.parse(localStorage.getItem('user'))
      var obj = {
        "action": "getDevicesMasterList",
        "userUniqueId": loggedInUser.UserUniqueID || loggedInUser.userUniqueID
      }
      await deviceconfigcrud(obj).then((resp) => {
        if (resp.status == "Ok") {
          if (resp.message != null) {
            let arr = resp.message.deviceTypeList.map((item) => ({
              value: item.deviceTypeID,
              label: item.deviceType
            }))
            setDeviceTypeOptions(arr);
          }
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  const onMosaicTypeChange = (e) => {
    let data = e.target.value;
    if (alertInProgress) {
      Swal.fire({
        title: 'Unable to change grid structure.',
        text: "Kindly close the alert you are working on before changing the alert grid structure!",
        icon: 'warning',
        //showCancelButton: true,
        confirmButtonColor: '#027aaa',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ok',
        allowOutsideClick: false
      }).then((result) => {
        if (result.isConfirmed) {
          return
          setAlertMosaicOption(data)
          getInitialAlertData(data, customerString, siteString, deviceTypeString, alertTypeString, "", severityString);
          setSingleAlertDet(null)
          setAlertInProgress(false);
        } else {
          return
        }
      })
    } else {
      setAlertMosaicOption(e.target.value)
      getInitialAlertData(e.target.value, customerString, siteString, deviceTypeString, alertTypeString, "", severityString);
      setSingleAlertDet(null)
      setAlertInProgress(false);
    }
  }

  const getAlertData = async (value = alserMosaicOption, customerIds = "", siteIds = "", deviceTypeIds = "", alertTypeIds = "", deviceNameIds = "", severityIds = "", lastAlertId = maxAlertId) => {
    let numberOfElements = value == 2 ? 16 : 9;
    try {
      let loggedInUser = JSON.parse(localStorage.getItem('user'))
      var obj = {
        "action": "getAlertListNinePoll",
        "severity": "all",
        "userUniqueId": loggedInUser?.UserUniqueID,
        "startIndex": 0,
        "numberOfElements": numberOfElements,
        "authCode": localStorage.getItem('authCode'),
        "customerIds": customerIds,
        "siteIds": siteIds,
        "deviceTypeIds": deviceTypeIds,
        "alertTypeIds": alertTypeIds,
        "deviceNameIds": deviceNameIds,
        "severityIds": severityIds,
        "lastAlertId": lastAlertId,
        "loadBalance": loggedInUser?.ApplicationFlag || "0"
        // "lastAlertId": "10510"
      }
      await getAlertList(obj).then((resp) => {
        if (resp.status == "Ok") {
          if (resp.message) {
            // Check the master data and find the not selected data and replace it with the new alerts data.

            // Check if new alerts are there or not.
            if (resp.message.length > 0) {
              let dockStartDate = new Date();
              if (alserMosaicOption == 1) {
                //Check for 9 alerts in masterdata
                if (masterData.length < 9) {
                  let arr = masterData
                  let counter = 0;
                  for (let i = 0; i < 9 - masterData.length; i++) {
                    if (counter >= resp.message.length) {
                      break;
                    }
                    let obj = {
                      ...resp.message[counter++],
                      isSelected: false,
                      dockStartDateTime: dockStartDate.getTime()
                    }
                    arr.push(obj);
                  }
                  getMaxAlertId(arr);
                  getStatus(arr)
                  if (arr.length == 16) {
                    // setShouldPollData(false);
                  }
                } else if (masterData.length == 9) {
                  let counter = 0;
                  let tempArr = masterData.map((i) => {
                    if (resp.message.length <= counter) {
                      return i;
                    }
                    if (i.noAlertFound || i.closed) {
                      return {
                        ...resp.message[counter++],
                        isSelected: false,
                        dockStartDateTime: dockStartDate.getTime()
                      }
                    } else {
                      return i;
                    }
                  });
                  getMaxAlertId(tempArr)
                  getStatus(tempArr);
                  if (tempArr.length == 9) {
                    // setShouldPollData(false);
                  }
                  // tempArr has the list of closed alerts, we now need to replace it with the new alerts
                }
              } else {
                //Check for 16 alerts in masterdata
                if (masterData.length < 16) {
                  let arr = masterData
                  let counter = 0;
                  for (let i = 0; i < 16 - masterData.length; i++) {
                    if (counter >= resp.message.length) {
                      break;
                    }
                    let obj = {
                      ...resp.message[counter++],
                      isSelected: false,
                      dockStartDateTime: dockStartDate.getTime()
                    }
                    arr.push(obj);
                  }
                  getMaxAlertId(arr)
                  getStatus(arr)
                  if (arr.length == 16) {
                    // setShouldPollData(false);
                  }
                } else if (masterData.length == 16) {
                  let counter = 0;
                  let tempArr = masterData.map((i) => {
                    if (counter >= resp.message.length) {
                      return i;
                    }
                    if (i.noAlertFound || i.closed) {
                      return {
                        ...resp.message[counter++],
                        isSelected: false,
                        dockStartDateTime: dockStartDate.getTime()
                      }
                    } else {
                      return i;
                    }
                  });
                  getMaxAlertId(tempArr)
                  getStatus(tempArr)
                  if (tempArr.length == 16) {
                    // setShouldPollData(false);
                  }
                }
              }
            } else {
              let arr = masterData.map((i) => {
                if (i.closed) {
                  return {
                    "noAlertFound": true
                  }
                }
                return i;
              })
              getMaxAlertId(arr);
              getStatus(arr);
            }
           
            setCountPending(resp.countPending);
            setCountServed(resp.countServed);
            setShouldPollData(false);
          }
        }
        //To be uncommented later.
        else {
          getStatus(masterData)
          setShouldPollData(false);
        }
      })
    } catch (error) {
      console.error(error)
    }
  }

  const getInitialAlertData = async (value = alserMosaicOption, customerIds = "", siteIds = "", deviceTypeIds = "", alertTypeIds = "", deviceNameIds = "", severityIds = "", showToast = false) => {
    let numberOfElements = value == 2 ? 16 : 9;
    try {
      let loggedInUser = JSON.parse(localStorage.getItem('user'))
      var obj = {
        "action": "getAlertListNine",
        "severity": "all",
        "userUniqueId": loggedInUser?.UserUniqueID,
        "startIndex": 0,
        "numberOfElements": numberOfElements,
        "authCode": localStorage.getItem('authCode'),
        "customerIds": customerIds,
        "siteIds": siteIds,
        "deviceTypeIds": deviceTypeIds,
        "alertTypeIds": alertTypeIds,
        "deviceNameIds": deviceNameIds,
        "severityIds": severityIds,
        "loadBalance": loggedInUser?.ApplicationFlag || "0"
      }
      await getAlertList(obj).then((resp) => {
        if (resp.status == "Ok") {
          if (resp.message) {
            let dockStartDate = new Date();
            let tempArr = resp.message.map((item) => {
              return {
                ...item,
                isSelected: false,
                dockStartDateTime: dockStartDate.getTime()
              }
            })
            setData(tempArr);
            setMasterData(tempArr);
            getMaxAlertId(tempArr)
            if (showToast) {
              SuccessToast("Filter applied successfully.")
              if (loggedInUser?.ApplicationFlag == "1" && loggedInUser?.UserRoleID == 3) {
                WarningToast("Since load balancing is enabled, applying filter is not recommended.")
              }
            }
          }

          setCountPending(resp.countPending);
          setCountServed(resp.countServed);          
        }
      })
    } catch (error) {
      console.error(error)
    }
  }

  const getAlertGridCount = () => {
    let loggedInUser = JSON.parse(localStorage.getItem('user'))
    let obj = {
      "action": "getGridAlertCount",
      "userUniqueId": loggedInUser?.UserUniqueID,
      "authCode": localStorage.getItem('authCode')
  }
    getAlertList(obj)
      .then((res) => {
        if(res.status == "Ok"){
          setCountPending(res.countPending);
          setCountServed(res.countServed); 
        }
      })
  }

  const getMaxAlertId = (tempArr) => {
    if (tempArr.length == 0) {
      setMaxAlertId(0);
      return;
    }
    let highestAlertId = Math.max(...tempArr.map(obj => {
      return obj.hasOwnProperty("alertId") ? obj.alertId : 0
    }));
    setMaxAlertId(highestAlertId);
  }

  const getAlertDetails = async (alertId) => {
    try {
      await getAlertDetailsById(alertId).then((resp) => {
        if (resp.status == "Ok") {
          if (resp.message) {
            setSingleAlertDet(resp.message[0])
            setSingleAlertDetChatFlow(resp.message[0])
            if (resp.message[0].associatedCamList.length > 0) {
              setAssociateData(resp.message[0].associatedCamList)
            }
            if (CHAT_CAM_CHANGES) {
              let secondObj = {
                alertId: alertId,
                alertDetail: resp.message[0],
                display: false,
                logText: "",
                shouldRefresh: false
              }
              setAssociateCam([...associateCam, secondObj]);
            }
          }
        }
      })
    } catch (error) {
      console.error(error)
    }
  }

  const getAlertChatFlowDetails = async (alertId) => {
    try {
      await getAlertDetailsById(alertId).then((resp) => {
        if (resp.status == "Ok") {
          if (resp.message) {
            setSingleAlertDetChatFlow(resp.message[0])
            if (CHAT_CAM_CHANGES) {
              let secondObj = {
                alertId: alertId,
                alertDetail: resp.message[0],
                display: false,
                logText: "",
                shouldRefresh: false
              }
              setAssociateCam([...associateCam, secondObj]);
            }
          }
        }
      })
    } catch (error) {
      console.error(error)
    }
  }

  const chatFlowClicked = (alertId, byPass = false) => {
    if (alertInProgress && !byPass) {
      setSwitchAlertModal(true);
      setAlertClicked(alertId);
    } else {
      getAlertDetails(alertId);
      let arr = data.map((item) => {
        if (item.alertId == alertId) {
          return {
            ...item,
            isSelected: true
          }
        } else {
          return {
            ...item,
            isSelected: false
          }
        }
      })
      setData(arr);
      setMasterData(arr);
      setAlertInProgress(true);
      setSwitchAlertModal(false);
    }
  }

  const scrollToBottom = () => {
    setTimeout(() => {
      messagesEndRef.current?.scrollIntoView({ behavior: "auto" })
    }, 1000)
  }

  const getDate = () => {
    let d;
    if (MULTI_TIME_ZONE) {
      let offsetTemp = singleAlertDet.OffsetMinutes != null ? singleAlertDet.OffsetMinutes : 330
      d = multiTimeZoneHelper(offsetTemp);
    } else {
      d = new Date();
    }
    return d
  }

  const attachEvidence = (text) => {
    let d = getDate();
    let myCurrentDatetime = d;
    let _datetime = ConvertDate(myCurrentDatetime)
    let rasaconvobj = {
      "evidenceType": "comment",
      "text": `${text}.`,
      "timestamp": _datetime,
      "type": "user"
    }
    let LoggedInUser = JSON.parse(localStorage.getItem('user'))
    let ConversationId = "AlertID_" + singleAlertDet.alertId + "_UserID_" + localStorage.getItem("UserID")
    addrasaconversationindb({
      "assignedTo": LoggedInUser.UserID,
      "rasaConversationID": ConversationId,
      "uploadType": "file",
      "evidence": rasaconvobj,
      "conversation": null,
      "userType": "operator",
      "createdOn": _datetime
    }).then((resp) => {
      if (resp.status === "Ok") {
        let temp = updateChatflow + 1;
        setUpdateChatflow(temp);
      }
    }).catch(err => console.log(err));
  }

  const AttachalertevidencesParent = (e, fileName) => {
    setImageData(e);
    setFileName(fileName);
  }

  const clearFilterList = () => {
    setSeveritySelected([]);
    setDeviceTypeSelected([]);
    setAlertTypeSelected([]);
    setSiteSelected([]);
    setCustomerSelected([]);
    setDeviceNameSelected([]);
    setCustomerString("");
    setSiteString("");
    setDeviceTypeString("");
    setAlertTypeString("");
    setSeverityString("");
    setStatusString("1,2");
    getInitialAlertData(alserMosaicOption, "", "", "", "", "", "", "");
  }
  const clearSelectedFilter = () => {
    if (alertInProgress) {
      Swal.fire({
        title: 'Unable to clear filter.',
        text: "Kindly close the alert you are working on before clearing the filter selection!",
        icon: 'warning',
        //showCancelButton: true,
        confirmButtonColor: '#027aaa',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ok',
        allowOutsideClick: false
      }).then((result) => {
        if (result.isConfirmed) {
          return
          setCustomerString("");
          setSiteString("");
          setDeviceTypeString("");
          setAlertTypeString("");
          setSeverityString("");
          setStatusString("1,2");
          setSelectedFilterName("")
          setSelectedFilter(0)
          getInitialAlertData(alserMosaicOption, "", "", "", "", "", "", "");
          setAlertInProgress(false);
          setSingleAlertDet(null)
        }
      })
    } else {
      setCustomerString("");
      setSiteString("");
      setDeviceTypeString("");
      setAlertTypeString("");
      setSeverityString("");
      setStatusString("1,2");
      setSelectedFilterName("")
      setSelectedFilter(0)
      getInitialAlertData(alserMosaicOption, "", "", "", "", "", "", "");
    }
  }

  const saveFilter = () => {
    if (filterName == "") {
      ErrorToast("Kindly Enter a Filter Name")
      return
    }
    if (customerSelected.length == 0 && siteSelected.length == 0 && deviceTypeSelected.length == 0 && alertTypeSelected.length == 0 && severitySelected.length == 0) {
      ErrorToast("Kindly select at least one filter")
      return
    }
    let pType = "all alerts";
    let loggedInUser = JSON.parse(localStorage.getItem('user'))
    let clientIds = customerSelected.map((i) => i.value).join(",");
    let siteIds = siteSelected.map((i) => i.value).join(",");
    let deviceTypeIds = deviceTypeSelected.map((i) => i.value).join(",");
    let alertTypeIds = alertTypeSelected.map((i) => i.value).join(",");
    let severityIds = severitySelected.map((i) => i.value).join(",");
    let statusIds = "1,2";
    const fileterTextstring = `clientIds:${clientIds};siteIds:${siteIds};deviceTypeIds:${deviceTypeIds};alertTypeIds:${alertTypeIds};alertSeverityIds:${severityIds};statusIds:${statusIds}`;
    FilterSaved(filterName, pType, loggedInUser?.UserUniqueID, fileterTextstring).then((resp) => {
      if (resp.Status == "success") {
        SuccessToast(resp.Message)
        setShow(false);
        setFilterName("");
        getFilterList();
      } else {
        ErrorToast(resp.Message)
      }
    }).catch(err => console.log(err));
  }

  const getFilterList = () => {
    let pType = "all alerts";
    let loggedInUser = JSON.parse(localStorage.getItem('user'))
    GetFilter(pType, loggedInUser?.UserUniqueID)
      .then((res) => {
        if (res.Status == "success") {
          setFilterList(res.Data)
        } else {
          setFilterList([]);
        }
      }).catch(err => console.log(err))

  }

  const deleteFilterList = (pId) => {
    DeleteFilter(pId)
      .then((res) => {
        if (res.Status == "success") {
          SuccessToast(res.Message)
          setModalFilterId(0)
          setModalFilterName("")
          setOpenDeleteFilter(false);
          getFilterList();

        } else {
          ErrorToast(res.Message)
        }
      }).catch((err) => {
        console.log(err);
      })
  }
  const applyFilterOnClick = () => {
    if (alertInProgress) {
      Swal.fire({
        title: 'Unable to apply filter.',
        text: "Kindly close the alert you are working on before applying a new filter!",
        icon: 'warning',
        //showCancelButton: true,
        confirmButtonColor: '#027aaa',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ok',
        allowOutsideClick: false
      }).then((result) => {
        if (result.isConfirmed) {
          return
          let customerIds = customerSelected.map((i) => i.value).join(",");
          setCustomerString(customerIds);
          let siteIds = siteSelected.map((i) => i.value).join(",");
          setSiteString(siteIds)
          let deviceTypeIds = deviceTypeSelected.map((i) => i.value).join(",");
          setDeviceTypeString(deviceTypeIds)
          let alertTypeIds = alertTypeSelected.map((i) => i.value).join(",");
          setAlertTypeString(alertTypeIds)
          let deviceNameIds = deviceNameSelected.map((i) => i.value).join(",");
          let severityIds = severitySelected.map((i) => i.value).join(",");
          setSeverityString(severityIds);
          let statusIds = statusString;
          setFilterApplied(true);
          setAlertInProgress(false);
          setSingleAlertDet(null)
          getInitialAlertData(alserMosaicOption, customerIds, siteIds, deviceTypeIds, alertTypeIds, deviceNameIds, severityIds, true);
        } else {
          return;
        }
      })
    } else {
      let customerIds = customerSelected.map((i) => i.value).join(",");
      setCustomerString(customerIds);
      let siteIds = siteSelected.map((i) => i.value).join(",");
      setSiteString(siteIds)
      let deviceTypeIds = deviceTypeSelected.map((i) => i.value).join(",");
      setDeviceTypeString(deviceTypeIds)
      let alertTypeIds = alertTypeSelected.map((i) => i.value).join(",");
      setAlertTypeString(alertTypeIds)
      let deviceNameIds = deviceNameSelected.map((i) => i.value).join(",");
      let severityIds = severitySelected.map((i) => i.value).join(",");
      setSeverityString(severityIds);
      let statusIds = statusString;
      setFilterApplied(true);
      getInitialAlertData(alserMosaicOption, customerIds, siteIds, deviceTypeIds, alertTypeIds, deviceNameIds, severityIds, true);
    }
  }

  const alertClosed = (alertId) => {
    let arr = data.map((item) => {
      if (item.alertId == alertId) {
        return { "closed": true }
      }
      return item;
    })
    setData(arr);
    setMasterData(arr);
    setSingleAlertDet(null)
    setShouldPollData(true);
    setAlertInProgress(false);
    setImageData(null)
    setFileName(null)
    setSingleAlertDetChatFlow(null);
    setClear(true)
  }

  const closedAlertBtn = (alertId) => {
    let loggedInUser = JSON.parse(localStorage.getItem('user'))
    closedAlertGridBtn(alertId, loggedInUser?.UserID).then((res) => {
      if (res.Status == "success") {
        let arr = data.map((item) => {
          if (item.alertId == alertId) {
            return { "closed": true }
          }
          return item;
        })
        setData(arr);
        setMasterData(arr);
        setShouldPollData(true);
      }
    })
  }

  //Don't remove this function code.
  const alertCloseBulk = (arr) => {
    let closeAlertArr = [];
    let tempArr = arr.map((item) => {
      let currentTIme = new Date();
      if (item?.isSelected) {
        return {
          ...item,
        }
      } else if (item?.noAlertFound) {
        return {
          ...item,
        }
      }
      else {
        if (currentTIme.getTime() - item.dockStartDateTime > 20000) {
          closeAlertArr.push(item.alertId)
          return { "closed": true }
        } else {
          return {
            ...item
          }
        }
      }
    })

    let loggedInUser = JSON.parse(localStorage.getItem('user'))
    if (closeAlertArr.length > 0) {
      Promise.all(closeAlertArr.map((i) => closedAlertGridBtn(i, loggedInUser?.UserID)))
        .then((res) => {
          if (res[0].Status == "success") {
            setData(tempArr)
            setMasterData(tempArr)
            setShouldPollData(true);
          }
        })
    }
  }

  const returnItems = (arr) => {
    return arr.map((item, index) => (
      <div className="item" data-value={index}>
        <div className="chatflow">
          <WebRTCPlayer
            key={item.associatedCameraId}
            id={item.associatedCameraId + "473"}
            devId={item.associatedCameraId}
            url={item.rtspUrl}
            // url="rtsp://admin:2o2oImaging@192.168.0.64:554/cam/realmonitor?channel=3%38subtype=1"
            isEncode={item.isTranscoded}
            actualTime={singleAlertDet.actualTime}
            alertDetail={singleAlertDet}
            item={item}
            upperDivCss="cameraHoverMainDiv"
            videoCss="player-responsive1"
            //videoCss="chatbotliveplayer"
            fullVideoCss="player-responsive-fullScreen"
            buttonCss="cameraButtonsMainDiv"
            noStreamDivCss="no-stream-found"
            playback={false}
            prePostDisplay={false}
            liveButton={true}
            exportCurrentVideoHide={true}
            showPlaybackBtn={false}
            hideTwoWay={false}
            imageToChat={AttachalertevidencesParent}
            attachEvidence={attachEvidence}
            showDirectToChat={true}
            showHooterIcon={true}
          // buttonDisable={buttonDisable}
          />
        </div>
      </div>
    ))
  }

  const renderDotsItem = ({ isActive }) => {
    return isActive ? 'x' : 'o';
  };

  const renderPrevButton = ({ isDisabled }) => {
    return <span style={{ opacity: isDisabled ? '0.5' : 1 }}>&lt;</span>;
  };

  const renderNextButton = ({ isDisabled }) => {
    return <span style={{ opacity: isDisabled ? '0.5' : 1 }}>&gt;</span>;
  };

  const getStatus = (arr) => {
    if (LOAD_BALANCE) {
      setMasterData(arr);
      setData(arr);
    } else {
      let tempArr = arr.filter((item) => item.hasOwnProperty('alertId') && item.hasOwnProperty('isSelected') && !item.isSelected).map((i) => i.alertId);
      let str = tempArr.join(",");
      if (str == "") {
        setMasterData(arr);
        setData(arr);
        return;
      }
      getLatestAlertStatus(str)
        .then((res) => {
          if (res.status == 'Ok') {
            if (res.message.length > 0) {
              let list = [];
              for (let i = 0; i < res.message.length; i++) {
                list.push(res.message[i][0]);
              }
              let masterDataTemp = masterData.map((item) => {
                if (list.includes(item.alertId)) {
                  return { "closed": true }
                }
                return item;
              })
              setData(masterDataTemp);
              setMasterData(masterDataTemp);
            } else {
              setMasterData(arr);
              setData(arr);
            }
          } else {
            setMasterData(arr);
            setData(arr);
          }
        })
        .catch(err => console.log(err))
    }
  }

  const infoFilterList = (pId) => {
    getInfoList(pId)
      .then((res) => {
        if (res.Status == "success") {
          let temp = JSON.parse(res.Data)
          infoDataFilter(temp)
        } else {
          ErrorToast(res.Message)
        }
      }).catch((err) => {
        console.log(err);
      })
  }

  const infoDataFilter = (arr) => {
    let customerArr = arr.filter((item) => item.Entity == CLIENT);
    setCustomerInfoArr(customerArr)
    setCustomerMasterArr(customerArr)
    let siteArr = arr.filter((item) => item.Entity == SITE);
    setSiteInfoArr(siteArr)
    setSiteMasterArr(siteArr)
    let deviceTypeArr = arr.filter((item) => item.Entity == DEVICE_TYPE);
    setDeviceTypeInfoArr(deviceTypeArr)
    setDeviceTypeMasterArr(deviceTypeArr)
    let alertTypeArr = arr.filter((item) => item.Entity == ALERT_TYPE);
    setAlertTypeInfoArr(alertTypeArr)
    setalertTypeMasterArr(alertTypeArr)
    let alertSeverityArr = arr.filter((item) => item.Entity == ALERT_SEVERITY);
    setAlertSeverityInfoArr(alertSeverityArr)
    setAlertSeverityMasterArr(alertSeverityArr)

  }

  const searchCustomerHandler = (e) => {
    setCustomerSearch(e.target.value)
    let tempCustomer = customerMasterArr.filter(x => x.Values.toLowerCase().includes(e.target.value.toLowerCase()))
    setCustomerInfoArr(tempCustomer)
  }

  const searchSiteHandler = (e) => {
    setSiteSearch(e.target.value)
    let tempSite = siteMasterArr.filter(x => x.Values.toLowerCase().includes(e.target.value.toLowerCase()))
    setSiteInfoArr(tempSite)
  }

  const searchDeviceTypeHandler = (e) => {
    setDeviceTypeSearch(e.target.value)
    let tempDeviceType = deviceTypeMasterArr.filter(x => x.Values.toLowerCase().includes(e.target.value.toLowerCase()))
    setDeviceTypeInfoArr(tempDeviceType)
  }

  const searchAlertTypeHandler = (e) => {
    setAlertTypeSearch(e.target.value)
    let tempAlertType = alertTypeMasterArr.filter(x => x.Values.toLowerCase().includes(e.target.value.toLowerCase()))
    setAlertTypeInfoArr(tempAlertType)
  }

  const searchAlertSeverityHandler = (e) => {
    setAlertSeveritySearch(e.target.value)
    let tempAlertSeverity = alertSeverityMasterArr.filter(x => x.Values.toLowerCase().includes(e.target.value.toLowerCase()))
    setAlertSeverityInfoArr(tempAlertSeverity)
  }

  const refreshData = () => {
    if (alertInProgress) {
      Swal.fire({
        title: 'Unable to refresh.',
        text: "Kindly close the alert you are working on before refreshing the alert grid!",
        icon: 'warning',
        // showCancelButton: true,
        confirmButtonColor: '#027aaa',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ok',
        allowOutsideClick: false
      }).then((result) => {
        if (result.isConfirmed) {
          return;
          getInitialAlertData(alserMosaicOption, customerString, siteString, deviceTypeString, alertTypeString, "", severityString);
        } else {
          return
        }
      })
    } else {
      getInitialAlertData(alserMosaicOption, customerString, siteString, deviceTypeString, alertTypeString, "", severityString);
    }
  }

  const showToastMsg = (msg) => {
    toast.info(msg, {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      style: { background: '#606060' },
    });
  };

  const toggleStretchVideo = () => {
    setStretchVideo(!stretchVideo)
    if (!stretchVideo) {
      showToastMsg("Videos are now stretch to the grid")
    } else {
      showToastMsg("Videos are now displayed in there original aspect ratio")
    }
  }

  const handleShowFirstDiv = () => setShowFirstDiv(true);
  const handleShowSecondDiv = () => setShowFirstDiv(false);

  return (
    <div className="SiteMainContainer">
      <div className="soundContainer AlertGridHgtDiv" style={{backgroundColor:"#F2F6FE"}}>     
        <div style={{ display: "flex", flex: "0 1 auto" }}>
          <div className='HMWidgetMainDiv HM_KPImainDiv m-0'>

      {
        showFirstDiv ? 
              (
                <div  style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                  <div style={{display:"flex"}}>
                      <div className='KPI_DesignDiv'>
                        <div style={{display:"flex"}}>
                        <div className='KPI_ImageDiv' style={{background:"#FEE1D3"}}>
                        <img
                              style={{ padding:"2px"}}
                              src={AlertDetailIcon}
                              alt="Alert"
                            />
                        </div>
                        <div className='KpiTextValue ml-2'>
                          <h3>{ countPending }</h3>
                          <p>Queued Alert</p>
                        </div>
                        </div>
                      </div>
                      <div className='KPI_DesignDiv'>
                        <div style={{display:"flex"}}>
                        <div className='KPI_ImageDiv' style={{background:"#E1CDFC"}}>
                        <img
                              style={{ padding:"5px"}}
                              src={AlertDetailIcon}
                              alt="Alert"
                            />
                        </div>
                        <div className='KpiTextValue ml-2'>
                          <h3>{ countServed }</h3>
                          <p>Processed Alert</p>
                        </div>
                        </div>
                      </div>
                      {/* <div className='KPI_DesignDiv'>
                        <div style={{display:"flex"}}>
                        <div className='KPI_ImageDiv' style={{background:"#FEE1D3"}}>
                        <img
                              style={{ padding:"2px"}}
                              src={AlertDetailIcon}
                              alt="Alert"
                            />
                        </div>
                        <div className='KpiTextValue ml-2'>
                          <h3>25</h3>
                          <p>Pending Alert</p>
                        </div>
                        </div>
                      </div>
                      <div className='KPI_DesignDiv'>
                        <div style={{display:"flex"}}>
                        <div className='KPI_ImageDiv' style={{background:"#9ECEB3"}}>
                        <img
                              style={{ padding:"2px"}}
                              src={AlertDetailIcon}
                              alt="Alert"
                            />
                        </div>
                        <div className='KpiTextValue ml-2'>
                          <h3>125</h3>
                          <p>Pending Alert</p>
                        </div>
                        </div>
                      </div> */}
                  </div>
                  <OverlayTrigger 
                            placement='bottom'
                            overlay={<Tooltip>View Filter</Tooltip>}
                            >
                  <div className='mr-2' style={{cursor:"pointer"}} onClick={handleShowSecondDiv}>
                      <img src={BlueBackgroundImg} />
                      <img src={KPI_AlertGrid} style={{marginLeft:"-52px"}}/>
                      <img src={RightArrowAlertGrid} className='ml-2'/>
                  </div>
                  </OverlayTrigger>
                </div>
              ): 
              (
                <div style={{display:"flex", alignItems:"center"}}>
                   <OverlayTrigger 
                            placement='bottom'
                            overlay={<Tooltip>View KPI</Tooltip>}
                            >
                   <div style={{cursor:"pointer"}} onClick={handleShowFirstDiv}>
                      <img src={BlueBackgroundLeft} />
                      <img src={LeftArrowAlertGrid} style={{marginLeft:"-55px"}}/>
                      <img src={KPI_ImgAlertGrid} className='ml-2'/>                            
                    </div>
                    </OverlayTrigger>

                    <div className='FilterDropdownsDiv'  style={{ display: "flex", justifyContent: "space-between", height:"70px",width:"97%", marginLeft:"15px" }}>
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          width: "93%",
                        }}
                      >
                        <div
                          // className='SavedFilterDiv'
                          className={selectedFilterName == "" ? "SavedFilterDiv" : "SavedFilterDiv dropdownSelectionContainer"}
                        >
                          <Dropdown>
                            <Dropdown.Toggle variant="light">
                              {
                                selectedFilterName != ""
                                  ? `Saved Filter: ${selectedFilterName}`
                                  : "Saved Filter"
                              }
                            </Dropdown.Toggle>
                            {
                              selectedFilterName != ""
                                ?
                                <button className="SavedFilterCancelBtn" onClick={() => {
                                  if (selectedFilterName != "") {
                                    clearSelectedFilter()
                                  }
                                }}
                                ><img alt="Clear" src={FilterClearIcon} /></button>

                                : null
                            }


                            <Dropdown.Menu>
                              <>
                                {
                                  filterList.length > 0
                                    ?
                                    filterList.map((item) => (
                                      <Dropdown.Item className='p-0'>
                                        <div className="SavedFilterOption" onClick={() => {
                                          if (alertInProgress) {
                                            Swal.fire({
                                              title: 'Unable to select filter.',
                                              text: "Kindly close the alert you are working on before selecting a filter!",
                                              icon: 'warning',
                                              //showCancelButton: true,
                                              confirmButtonColor: '#027aaa',
                                              cancelButtonColor: '#d33',
                                              confirmButtonText: 'Ok',
                                              allowOutsideClick: false
                                            }).then((result) => {
                                              if (result.isConfirmed) {
                                                return
                                                setSelectedFilterName(item.FilterName)
                                                setSelectedFilter(item.FilterId)
                                                setSeveritySelected([]);
                                                setDeviceTypeSelected([]);
                                                setAlertTypeSelected([]);
                                                setSiteSelected([]);
                                                setCustomerSelected([]);
                                                setSaveDisabled(false);
                                                setAlertInProgress(false);
                                                setSingleAlertDet(null)
                                                setFilterApplied(false);
                                              }
                                            })
                                          } else {
                                            setSelectedFilterName(item.FilterName)
                                            setSelectedFilter(item.FilterId)
                                            setSeveritySelected([]);
                                            setDeviceTypeSelected([]);
                                            setAlertTypeSelected([]);
                                            setSiteSelected([]);
                                            setCustomerSelected([]);
                                            setSaveDisabled(false);
                                            setFilterApplied(false);
                                          }
                                        }}>
                                          <span>{item.FilterName}</span>
                                          <div className="SaveFilterOtionHover">
                                            <button style={{ background: "transparent" }} onClick={(e) => {
                                              e.stopPropagation();
                                              setOpenDeleteFilter(true)
                                              setModalFilterName(item.FilterName)
                                              setModalFilterId(item.FilterId)
                                            }}><img alt="Delete" src={DeleteIcon} /></button>
                                            <button style={{ background: "transparent" }} onClick={(e) => {
                                              infoFilterList(item.FilterId)
                                              e.stopPropagation();
                                              setOpenInfoFilter(true)
                                              setModalFilterName(item.FilterName)
                                              setModalFilterId(item.FilterId)
                                            }}><img alt="Info" src={InfoIcon1} /></button>
                                          </div>
                                        </div>
                                      </Dropdown.Item>
                                    ))
                                    :
                                    <Dropdown.Item style={{ cursor: 'default' }}>
                                      <span>No Saved Filters.</span>
                                    </Dropdown.Item>
                                }
                              </>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                        <h4>OR</h4>
                        <FilterToolDDLTemp {...filterToolDDLProps} />
                        <OverlayTrigger
                          placement="bottom"
                          overlay={<Tooltip>Apply Filter</Tooltip>}
                        >
                          <button
                            className={saveDisabled ? "IconsButtonDiv" : "buttonDisable"}
                            disabled={!saveDisabled} onClick={applyFilterOnClick}>
                            <img
                              src={FilterIcon}
                              className="camera-icon-height"
                              alt="filter icon"
                            />
                          </button>
                        </OverlayTrigger>
                        <OverlayTrigger
                          placement="bottom"
                          overlay={<Tooltip>Clear Filter</Tooltip>}
                        >
                          <button
                            className={saveDisabled ? "IconsButtonDiv" : "buttonDisable"}
                            onClick={clearFilterList}
                            disabled={!saveDisabled}
                          >
                            <img
                              src={ClearFilter}
                              className="camera-icon-height"
                              alt="Clear filter icon"
                            />
                          </button>
                        </OverlayTrigger>
                        <OverlayTrigger
                          placement="bottom"
                          overlay={<Tooltip>Save Filter</Tooltip>}
                        >
                          <button
                            className={saveDisabled ? "IconsButtonDiv" : "buttonDisable"}
                            disabled={!saveDisabled}
                            onClick={(e) => {
                              e.preventDefault();
                              setShow(!show);
                            }}
                          >
                            <img
                              src={SaveFilter}
                              className="camera-icon-height"
                              alt="Save filter icon"
                            />
                          </button>
                        </OverlayTrigger>
                        <OverlayTrigger
                          placement="bottom"
                          overlay={<Tooltip>Refresh</Tooltip>}
                        >
                          <button
                            className="DataAccessEdit"
                            style={{ width: "50px", height: "35px", float: "right" }}
                            onClick={refreshData}
                          >
                            <img src={RefreshBtnIcon} alt="Refresh" />
                            {/* <span className="ViewUpdate ml-1">Update Data</span> */}
                          </button>
                        </OverlayTrigger>
                        <div className="FilterOpenAlertsText">
                          <p className="ListofText">
                            {/* 111 <span className="SiteNameText"> Open alerts</span> */}
                          </p>
                          <p className="ListofText" style={{ marginLeft: "1rem" }}>
                            {
                              selectedFilterName || filterApplied
                                ?
                                <span className="SiteNameText">
                                  Filter Applied
                                </span>
                                :
                                <span className="SiteNameText">
                                  Default Data
                                </span>
                            }
                          </p>
                        </div>
                      </div>
                      <div className="inputMainContainer w40dot75rem alertMosaicSelection">
                        <div className="inputTypeLabelContainer">
                          <select
                            className="inputType ArrowSelectClass"
                            value={alserMosaicOption}
                            onChange={(e) => onMosaicTypeChange(e)}
                          >
                            <option value="1" id="1">
                              3 x 3
                            </option>
                            <option id="2" value="2">
                              4 x 4
                            </option>
                          </select>
                        </div>
                        <OverlayTrigger 
                          placement='bottom'
                          overlay={
                            !stretchVideo ? (
                              <Tooltip>Stretch Video</Tooltip>
                            ) : (
                              <Tooltip>Restore Video</Tooltip>
                            )
                          }
                          >
                          <button
                            className="IconsButtonDiv"
                            onClick={toggleStretchVideo}
                          >
                            {!stretchVideo ? (
                              <div style={{border: "2px solid #fff"}}>
                                <img
                                  style={{ padding: "2px", height: "24px" }}
                                  src={StretchVideo}
                                  alt="Full"
                                />
                              </div>
                            ) : (
                              <div style={{border: "2px solid #fff"}}>
                              <img
                                style={{ padding: "2px", height: "24px" }}
                                src={ExitStretchVideo}
                                alt="Exit"
                              />
                              </div>
                            )}
                          </button>
                        </OverlayTrigger>
                        <OverlayTrigger 
                            placement='bottom'
                            overlay={
                              !isFullScreen ? (
                                <Tooltip>Fullscreen View</Tooltip>
                              ) : (
                                <Tooltip>Exit Fullscreen View</Tooltip>
                              )
                            }
                            >
                          <button
                            className="IconsButtonDiv"
                            ref={btnRef} onClick={toggleFullScreen}
                          >
                            {!isFullScreen ? (
                              <img
                                style={{ padding: "2px", height: "24px" }}
                                src={MosaicFullscreen}
                                alt="Full"
                              />
                            ) : (
                              <img
                                style={{ padding: "2px", height: "24px" }}
                                src={ExitFullscreen}
                                alt="Exit"
                              />
                            )}
                          </button>
                        </OverlayTrigger>
                      </div>
                    </div>
                </div>
              )
      }
       

        {/* <AliceCarousel
                key={activeIndex}
                items={items}
                responsive={responsive}
                disableDotsControls
                disableButtonsControls
                activeIndex={activeIndex}
              /> */}
              <div
                key="btns"
                className="b-refs-buttons KPI_Buttons"
                style={{ textAlign: "center", position: "relative", bottom: "6px",display:"none" }}
              >
                <button
                  onClick={handleShowFirstDiv}
                  className={!showFirstDiv? "CarouselPrev": " CarouselPrev disabledCarouselNext"}
                >
                   {
                showFirstDiv ? 
                  (<img
                    style={{ height: "13px", width: "15px" }}
                    alt="Left"
                    src={CarousalUnselected}
                  />):(
                    <img
                    style={{ height: "13px", width: "15px" }}
                    alt="Right"
                    src={CarousalSelectedBlue}
                  />
                  )}
                </button>
                <button
                  className={showFirstDiv? "CarouselNext": " CarouselNext disabledCarouselNext"}
                  onClick={handleShowSecondDiv}
                >
                  
                   {
                showFirstDiv ? 
                  (<img
                    style={{ height: "13px", width: "15px" }}
                    alt="Right"
                    src={CarousalSelectedBlue}
                  />
                  
                  ):(
                    <img
                    style={{ height: "13px", width: "15px" }}
                    alt="Right"
                    src={CarousalUnselected}
                  />
                  )}
                </button>
              </div>
              </div>


              <div>
     {/*  <button onClick={handleShowFirstDiv}>Show Div 1</button>
      <button onClick={handleShowSecondDiv}>Show Div 2</button>

      {showFirstDiv ? (
        <div style={{ marginTop: "20px", padding: "10px", backgroundColor: "#f0f0f0" }}>
          <h2>Div 1</h2>
          <p>This is the first div.</p>
        </div>
      ) : (
        <div style={{ marginTop: "20px", padding: "10px", backgroundColor: "#d0d0f0" }}>
          <h2>Div 2</h2>
          <p>This is the second div.</p>
        </div>
      )} */}
    </div>
        </div>
        <div style={{ display: "flex", flex: "1 1", overflow: "hidden",marginTop:"20px" }}>
          <div className="mosaic-row">
            {
              alserMosaicOption == 1
                ?
                <>
                  {
                    data.length > 0
                      ?
                      <div className="camerafilter3x3">
                        {
                          data.map((i) => {
                            if (i.closed) {
                              return (
                                <div className='alertInprogressDiv'>
                                  <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "100%" }}>
                                    <img alt="Loading Alert" src={LoadingAlert} />
                                    <div className='alertInprogressDetail'>
                                      <p style={{ color: "#535353" }}>Loading new alert…</p>
                                    </div>
                                  </div>
                                </div>
                              )
                            }
                            else if (i.noAlertFound) {
                              return (
                                <div className='alertInprogressDiv'>
                                  <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "100%" }}>
                                    <img alt="No Alert" src={NoMoreAlert} />
                                    <div className='alertInprogressDetail'>
                                      <p>No new alerts to display.</p>
                                    </div>
                                  </div>
                                </div>
                              )
                            }
                            else {
                              <div className='alertInprogressDiv'>
                                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "100%" }}>
                                  <img alt="No Alert" src={NoMoreAlert} />
                                  <div className='alertInprogressDetail'>
                                    <p>No new alerts to display.</p>
                                  </div>
                                </div>
                              </div>
                            }
                            let sev = "";
                            if (i.severity == "High") {
                              sev = "high-bb"
                            } else if (i.severity == "Moderate") {
                              sev = "moderate-bb"
                            } else if (i.severity == "Low") {
                              sev = "low-bb"
                            } else if (i.severity == "Extreme") {
                              sev = "extreme-bb"
                            }

                            return (
                              <WebRTCPlayer
                                key={i.alertId}
                                id={i.associatedCamObject.length > 0 ? i.associatedCamObject[0].associatedCameraId + i.alertId : i.cameraObject.ParentCameraID + i.alertId}
                                url={i.associatedCamObject.length > 0 ? i.associatedCamObject[0].rtspUrl : i.cameraObject.RTSPURL}
                                isEncode={i.associatedCamObject.length > 0 ? i.associatedCamObject[0].isTranscoded : i.cameraObject.isTranscoded}
                                item={i.associatedCamObject.length > 0 ? i.associatedCamObject[0] : i.cameraObject}
                                upperDivCss={`HeightChildDiv ${sev}`}
                                videoCss="mosaic"
                                buttonCss="mosaicButtonsMainDiv"
                                alertCameraHeadingCss="alertCameraHeadingCss"
                                alertCameraDetailCss="alertCameraDetailCss"
                                noStreamDivCss="HeightChildDiv"
                                displayInfo={true}
                                alertData={i}
                                chatFlowClicked={chatFlowClicked}
                                closedAlertBtn={closedAlertBtn}
                                setAlertToClose={setAlertToClose}
                                isSelected={i.isSelected}
                                stretchVideo={stretchVideo}
                              />
                            )
                          }
                          )
                        }
                      </div>
                      :
                      <div style={{ margin: '1rem' }}>
                        <AlertTemplateMessage
                          message={
                            "No alerts found"
                          }
                        />
                      </div>
                  }
                </>
                :
                <>
                  {
                    data.length > 0
                      ?
                      <div className="camerafilter4x4">
                        {
                          data.map((i) => {
                            if (i.closed) {
                              return (
                                <div className='alertInprogressDiv'>
                                  <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "100%" }}>
                                    <img alt="No Alert" src={LoadingAlert} />
                                    <div className='alertInprogressDetail'>
                                      <p style={{ color: "#535353" }}>Loading new alert…</p>
                                    </div>
                                  </div>
                                </div>
                              )
                            }
                            else if (i.noAlertFound) {
                              return (
                                <div className='alertInprogressDiv'>
                                  <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "100%" }}>
                                    <img alt="No Alert" src={NoMoreAlert} />
                                    <div className='alertInprogressDetail'>
                                      <p>No new alerts to display.</p>
                                    </div>
                                  </div>
                                </div>
                              )
                            }
                            else {
                              <div className='alertInprogressDiv'>
                                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "100%" }}>
                                  <img alt="No Alert" src={NoMoreAlert} />
                                  <div className='alertInprogressDetail'>
                                    <p>No new alerts to display.</p>
                                  </div>
                                </div>
                              </div>
                            }
                            let sev = "";
                            if (i.severity == "High") {
                              sev = "high-bb"
                            } else if (i.severity == "Moderate") {
                              sev = "moderate-bb"
                            } else if (i.severity == "Low") {
                              sev = "low-bb"
                            } else if (i.severity == "Extreme") {
                              sev = "extreme-bb"
                            }
                            return (
                              <WebRTCPlayer
                                key={i.alertId + "44"}
                                id={i.associatedCamObject.length > 0 ? i.associatedCamObject[0].associatedCameraId + i.alertId : i.cameraObject.ParentCameraID + i.alertId}
                                url={i.associatedCamObject.length > 0 ? i.associatedCamObject[0].rtspUrl : i.cameraObject.RTSPURL}
                                isEncode={i.associatedCamObject.length > 0 ? i.associatedCamObject[0].isTranscoded : i.cameraObject.isTranscoded}
                                item={i.associatedCamObject.length > 0 ? i.associatedCamObject[0] : i.cameraObject}
                                upperDivCss={`HeightChildDiv ${sev}`}
                                videoCss="mosaic"
                                buttonCss="mosaicButtonsMainDiv"
                                alertCameraHeadingCss="alertCameraHeadingCss"
                                alertCameraDetailCss="alertCameraDetailCss"
                                noStreamDivCss="HeightChildDiv"
                                displayInfo={true}
                                alertData={i}
                                chatFlowClicked={chatFlowClicked}
                                closedAlertBtn={closedAlertBtn}
                                setAlertToClose={setAlertToClose}
                                isSelected={i.isSelected}
                                stretchVideo={stretchVideo}
                              />
                            )
                          }
                          )
                        }
                      </div>
                      :
                      <div style={{ margin: '1rem' }}>
                        <AlertTemplateMessage
                          message={
                            "No alerts found"
                          }
                        />
                      </div>
                  }
                </>
            }

          </div>
          <div className="alertChatflowContainer">
            {
              singleAlertDet == null
                ?
                <>
                  <div className="AlertFilterHeaderDiv">
                    <p className="AlterfilterText">
                      <span>No Alert Selected</span>
                    </p>
                  </div>
                  <div style={{ padding: "20px" }}>
                    <AlertTemplateMessage message={"Select an alert to view detail and take action."} />
                  </div>
                </>
                :
                <>
                  <div className="AlertFilterHeaderDiv">
                    <p className="AlterfilterText">
                      Alert ID:
                      <span>{singleAlertDet.alertId}</span>
                    </p>
                  </div>
                  <>
                    <div className="CameragridAssociateDiv">
                      {
                        singleAlertDet.associatedCamList.length > 0
                          // true
                          ?
                          <AliceCarousel
                            mouseTracking
                            items={returnItems(associateData)}
                            renderDotsItem={renderDotsItem}
                            renderPrevButton={renderPrevButton}
                            renderNextButton={renderNextButton}
                          />
                          :
                          <div className="chatflow">
                            {
                              singleAlertDet != null
                                // false
                                ?
                                <WebRTCPlayer
                                  key={singleAlertDet.deviceId}
                                  id={singleAlertDet.deviceId + "34"}
                                  devId={singleAlertDet.deviceId}
                                  url={singleAlertDet.rtspUrl}
                                  isEncode={singleAlertDet.isTranscoded}
                                  item={singleAlertDet}
                                  actualTime={singleAlertDet.actualTime}
                                  alertDetail={singleAlertDet}
                                  upperDivCss="cameraHoverMainDiv"
                                  videoCss="player-responsive1"
                                  //videoCss="chatbotliveplayer"
                                  fullVideoCss="player-responsive-fullScreen"
                                  buttonCss="cameraButtonsMainDiv"
                                  noStreamDivCss="no-stream-found"
                                  playback={false}
                                  prePostDisplay={false}
                                  liveButton={true}
                                  exportCurrentVideoHide={true}
                                  showPlaybackBtn={false}
                                  hideTwoWay={false}
                                  imageToChat={AttachalertevidencesParent}
                                  attachEvidence={attachEvidence}
                                  showDirectToChat={true}
                                  showHooterIcon={true}
                                // buttonDisable={buttonDisable}
                                />
                                : null
                            }
                          </div>
                      }

                    </div>
                    <div className='multi-chatbot-container-div'>
                      <ChatflowContainer
                        key={singleAlertDetChatFlow.alertId}
                        scrollToBottom={scrollToBottom}
                        AlertDetails={singleAlertDetChatFlow}
                        ConversationId={"AlertID_" + singleAlertDetChatFlow.alertId + "_UserID_" + localStorage.getItem('UserID')}
                        ResetAlertList={() => { }}
                        hootWay={[]}
                        setHootWay={() => { }}
                        associateCam={[]}
                        setAssociateCam={() => { }}
                        GetAlertDetails={getAlertChatFlowDetails}
                        updateChatflow={updateChatflow}
                        imageData={imageData}
                        fileName={fileName}
                        alertClosed={alertClosed}
                      />
                      <div ref={messagesEndRef} />
                    </div>
                  </>
                </>
            }
          </div>
        </div>
      </div>
      {/* Modal popup for save filter */}
      <Modal
        size="lg"
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="modal-90w"
        backdrop="static"
        className="saveCameraFilterModal"
      >
        <>
          <Modal.Header>
            <Modal.Title id="example-custom-modal-styling-title">
              Save Filter
            </Modal.Title>
            <img
              src={RemoveIcon}
              alt="Remove"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setShow(false);
                setFilterName("")
                //   setAddCustomerError('')
              }}
            />
          </Modal.Header>

          <Modal.Body>
            <div className="inputMainContainer w40dot75rem w-100">
              <div className="inputTypeLabelContainer">
                <label className="formFieldLabels">Filter Name</label>
                <input
                  style={{ width: "40vw" }}
                  type="text"
                  className="inputType"
                  placeholder="Ex. Filter for xyz company with 10 sites"
                  value={filterName}
                  onChange={(e) => setFilterName(e.target.value)}
                />
                <p className="Error_P" style={{ float: "left" }}>
                  <span>{ }</span>
                </p>
              </div>
            </div>
            <div style={{textAlign: "center"}}>
            <button
              className="DataAccessEdit mt-3"
              style={{ width: "250px" }}
              onClick={saveFilter}
            >
              <span className="ViewUpdate">Save</span>
            </button>
            </div>
          </Modal.Body>
        </>
      </Modal>

      {/* Modal popup for delete of scheduler */}
      <Modal
        show={openDeleteFilter}
        onHide={() => setOpenDeleteFilter(false)}
        className="deleteModalMainClass"
        backdrop="static"
      >
        <Modal.Body>
          <div className="ModalBodyDiv">
            <div style={{ padding: "37px" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "1rem",
                }}
              >
                <img
                  className="informationIcon infoorangeicon"
                  src={WarningDelete}
                  alt="Info"
                />
                <div style={{ width: "100%" }}>
                  <h3 className="No-Data-Access-Group">
                    Delete Saved Filter!
                  </h3>
                </div>
              </div>
              <p className="DeleteText mb-4">
                Are you sure you want to delete the saved filter{" "}
                <span className="font-weight-bold"> {modalFilterName}</span>
              </p>
              <div
                style={{
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <button
                  className="DataAccessEdit ml-0"
                  style={{ width: "45%", backgroundColor: "#FB3030" }}
                  onClick={() => deleteFilterList(modalFilterId)}
                >
                  <span className="ViewUpdate">Yes</span>
                </button>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenDeleteFilter(false);
                    setModalFilterId(0)
                    setModalFilterName("")
                  }}
                  className="DataAccessEdit ml-0"
                  style={{ width: "45%" }}
                >
                  <span className="ViewUpdate">No</span>
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Modal popup for info saved filter */}
      <Modal
        size="xl"
        show={openInfoFilter}
        onHide={() => setOpenInfoFilter(false)}
        className="deleteModalMainClass"
        backdrop="static"
        scrollable
      >
        <Modal.Header>
          <Modal.Title id="example-custom-modal-styling-title">
            {modalFilterName} - info
          </Modal.Title>
          <img
            src={RemoveIcon}
            alt="Remove"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setOpenInfoFilter(false);
            }}
          />
        </Modal.Header>
        <Modal.Body>
          <div id="mainContainer" className="main-container">
            {
              customerMasterArr?.length > 0
                ?
                <InfoComponent headerName={"Customer"} search={customerSearch} searchHandler={searchCustomerHandler} isDarkTheme={isDarkTheme} infoArr={customerInfoArr} />
                :
                null
            }
            {
              siteMasterArr?.length > 0
                ?
                <InfoComponent headerName={"Site"} search={siteSearch} searchHandler={searchSiteHandler} isDarkTheme={isDarkTheme} infoArr={siteInfoArr}/>
                :
                null
            }

            {
              deviceTypeMasterArr?.length > 0
                ?
                <InfoComponent headerName={"Device Type"} search={deviceTypeSearch} searchHandler={searchDeviceTypeHandler} isDarkTheme={isDarkTheme} infoArr={devieceTypeInfoArr}/>
                :
                null
            }

            {
              alertTypeMasterArr?.length > 0
                ?
                <InfoComponent headerName={"Alert Type"} search={alertTypeSearch} searchHandler={searchAlertTypeHandler} isDarkTheme={isDarkTheme} infoArr={alertTypeInfoArr}/>
                :
                null
            }
            {
              alertSeverityMasterArr?.length > 0
                ?
                <InfoComponent headerName={"Alert Severity"} search={alertSeveritySearch} searchHandler={searchAlertSeverityHandler} isDarkTheme={isDarkTheme} infoArr={alertSeverityInfoArr}/>
                :
                null
            }

          </div>
        </Modal.Body>
      </Modal>

      <Modal
        size="lg"
        show={switchAlertModal}
        onHide={() => setSwitchAlertModal(false)}
        className="deleteModalMainClass"
        backdrop="static"
      >
        <Modal.Body>
          <div className="ModalBodyDiv">
            <div style={{ padding: "37px" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "1rem",
                }}
              >
                <img
                  className="informationIcon infoorangeicon"
                  src={WarningDelete}
                  alt="Info"
                />
                <div style={{ width: "100%" }}>
                  <h3 className="No-Data-Access-Group">
                    {/* Confirm Alert Switch? */}
                    Unable to Switch Alert.
                  </h3>
                </div>
              </div>
              <p className="DeleteText">
                {/* You have an open alert. Switching to a new alert will only close the current alert view.{" "} */}
                Kindly close the alert you are working on before operating another alert!
                {/* <span>Do you want to close the current view and open the new alert?</span> */}
              </p>
              {/* <p className="DeleteTextPTag mb-4" style={{fontWeight: "800"}}>Do you want to close the current view and open the new alert?</p> */}
              <div
                style={{
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {/* <button
                          className="DataAccessEdit ml-0"
                          style={{ width: "45%" }}
                          onClick={(e) => {
                            e.preventDefault();
                            chatFlowClicked(alertClicked, true);
                          }}
                        >
                          <span className="ViewUpdate">Yes</span>
                        </button> */}
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setSwitchAlertModal(false);
                  }}
                  className="DataAccessEdit ml-0"
                  style={{ width: "45%", marginTop: "1rem" }}
                >
                  <span className="ViewUpdate">Ok</span>
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}
