import React from 'react'
import { Line } from 'react-chartjs-2';
import { FilterClearIcon } from '../../../assets/images';

const MultiLineChart = () => {

    
  const data = {
    labels: ["Mon","Tues", "Wed", "Thurs", "Fri", "Sat", "Sun"],  
    datasets: [{
      label: 'First Dataset',
      data: [65, 59, 80, 81, 56, 55, 40],
      fill: false,
      borderColor: 'rgb(75, 45, 450)',
      tension: 0.1
    },
    {
        label: 'Second Dataset',
        data: [40, 15, 96, 65, 40, 25, 31],
        fill: false,
        borderColor: 'rgb(45, 192, 200)',
        tension: 0.1
      }]
  };

  const config = {
    type: 'line',
    data: data,
    options: {
      responsive: true,
      interaction: {
        mode: 'index',
        intersect: false,
      },
      stacked: false,
      plugins: {
        title: {
          display: true,
          text: 'Chart.js Line Chart - Multi Axis'
        }
      },
      scales: {
        y: {
          type: 'linear',
          display: true,
          position: 'left',
        },
        y1: {
          type: 'linear',
          display: true,
          position: 'right',
  
          // grid line settings
          grid: {
            drawOnChartArea: false, // only want the grid lines for one axis to show up
          },
        },
      }
    },
  };

  return (
    <div style={{height:"100%"}}>
        <div className='ChartHeader'>
            <p>Multi Line Chart</p>
            <button style={{background:"transparent"}}>
            <img src={FilterClearIcon} alt="Clear" />
            </button>
        </div>
        <div style={{padding:"10px", height:"90%"}}>
            <Line config={config} data={data}/>
        </div>
    </div>
  )
}

export default MultiLineChart