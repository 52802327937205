import React from 'react'
import IndividualMosaiCameras from './IndividualMosaiCameras'

export default function Matrix({ cameras, gridType, height, width, playerRef, refreshTime, currentPage, stretchVideo }) {
    return <div className={gridType}>
    {
        cameras &&
        cameras.map(camera => (
            <IndividualMosaiCameras
                camera={camera}
                height={height}
                width={width}
                playerRef={playerRef}
                time={refreshTime}
                currentPage={currentPage}
                gridType={gridType}
                stretchVideo={stretchVideo}
            />
        ))}
</div>
}
