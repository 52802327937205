import React from 'react'

const DashboardMiniWidget = ({ text, label, icon, pointer }) => {
    return (
        <div className="dashboard-alerts-widget-box">
            {
                pointer == 'none' ?
                // <div className="bottomKpiCard"
                //      style={{pointer:'pointer'}}
                // >
                //     <div className="kpi2">
                //         <div className="smallKpiText">
                //             <span className='RestrictedText'>Restricted Access</span>
                //             <p>{label}</p>
                //         </div>
                //         <div className="ovalDiv">
                //             <img src={icon} alt="closed alert icon" className="alertkpiIcon ovalDiv-closedAlerts" />
                //         </div>
                //     </div>
                // </div>
                <div className='miniHomePageWidgetStyleAccDnd'>
                    <p className="RestrictedText">Restricted Access</p>
                </div>
                :
                <div className="bottomKpiCard">
                    <div className="kpi2">
                        <div className="smallKpiText">
                            <p>{text}</p>
                            <p>{label}</p>
                        </div>
                        <div className="ovalDiv">
                            <img src={icon} alt="closed alert icon" className="alertkpiIcon ovalDiv-closedAlerts" />
                        </div>
                    </div>
                </div>
            }

            {/* <div className="bottomKpiCard">
                <div className="kpi2">
                    <div className="smallKpiText">
                        <p>{text}</p>
                        <p>{label}</p>
                    </div>
                    <div className="ovalDiv">
                        <img src={icon} alt="closed alert icon" className="alertkpiIcon ovalDiv-closedAlerts" />
                    </div>
                </div>
            </div> */}
        </div>
    )
}

export default DashboardMiniWidget