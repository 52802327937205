export const VERSION = window.VERSION_TEST;
export const IS_WEBRTC = window.IS_WEBRTC;
export const HIDE_STUFF = window.HIDE_STUFF;
export const ASSOC_DIRECT_OPEN = window.ASSOC_DIRECT_OPEN;
export const CHAT_CAM_CHANGES = window.CHAT_CAM_CHANGES;
export const IS_PTZVISIBLE = window.IS_PTZVISIBLE;
export const MULTI_TIME_ZONE = window.MULTI_TIME_ZONE;
export const CCTV_BULK_UPLOAD_LINK = window.CCTV_BULK_UPLOAD_LINK;
export const PANEL_BULK_UPLOAD_LINK = window.PANEL_BULK_UPLOAD_LINK;
export const ACS_BULK_UPLOAD_LINK = window.ACS_BULK_UPLOAD_LINK;
export const CAMERA_NEW = window.CAMERA_NEW
export const LOAD_BALANCE = window.LOAD_BALANCE
export const HM_SELECT_SITE = window.HM_SELECT_SITE
export const CLOSE_CHATFLOW_BTN = window.CLOSE_CHATFLOW_BTN
export const AUTO_ALERT_CLOSE = window.AUTO_ALERT_CLOSE
export const HIDE_CLIENTLOGO = window.HIDE_CLIENTLOGO

export const AUTH_TOKEN = localStorage.getItem('authToken');
export const AUTH_CODE = localStorage.getItem('authCode');

export const MULTI_PLAYBACK_FLASK_URL = window.MULTI_PLAYBACK_FLASK_URL_TEST;
export const BASE_URL = window.BASE_URL_TEST;
export const BASE_CORE_URL = window.BASE_CORE_URL_TEST;
export const CLOUD_STREAMING = window.CLOUD_STREAMING;
export const CLIENT_COMPONENET_LINK = window.CLIENT_COMPONENET_LINK;
export const ALERT_INTERVAL_TIMER = window.ALERT_INTERVAL_TIMER;
export const SPINNER_WAIT_TIME = window.SPINNER_WAIT_TIME;
export const FILE_DOWNLOAD_CHECK_WAIT_TIME = window.FILE_DOWNLOAD_CHECK_WAIT_TIME;
export const MAX_TRIES = window.MAX_TRIES;
export const WAIT_TIME = window.WAIT_TIME;
export const INTERVAL_TIME = window.INTERVAL_TIME;
export const NO_STREAM_INTERVAL_TIMER = window.NO_STREAM_INTERVAL_TIMER;
export const HEALTHMONITORING_INTERVAL_TIMER = window.HEALTHMONITORING_INTERVAL_TIMER;
export const DASHBOARD_INTERVAL_TIMER = window.DASHBOARD_INTERVAL_TIMER;
export const MOSAIC_REFRESH_TIMER = window.MOSAIC_REFRESH_TIMER;
export const HM_STATUS_API = window.HM_STATUS_API;

//FOR SIDEBAR
export const IS_DASHBOARD_TAB_HIDDEN = window.IS_DASHBOARD_TAB_HIDDEN;
export const IS_ALERTS_TAB_HIDDEN = window.IS_ALERTS_TAB_HIDDEN;
export const IS_CAMERAS_TAB_HIDDEN = window.IS_CAMERAS_TAB_HIDDEN;
export const IS_HEALTH_MONITORING_TAB_HIDDEN = window.IS_HEALTH_MONITORING_TAB_HIDDEN;
export const IS_PANELCONTROL_TAB_HIDDEN = window.IS_PANELCONTROL_TAB_HIDDEN;
export const IS_REPORTS_TAB_HIDDEN = window.IS_REPORTS_TAB_HIDDEN;
export const IS_CUSTOM_REPORTS_TAB_HIDDEN = window.IS_CUSTOM_REPORTS_TAB_HIDDEN;
export const IS_DAILY_REPORTS_TAB_HIDDEN = window.IS_DAILY_REPORTS_TAB_HIDDEN;
export const IS_PANEL_UP_DOWN_REPORTS_TAB_HIDDEN = window.IS_PANEL_UP_DOWN_REPORTS_TAB_HIDDEN;
export const IS_ATM_REPORTS_TAB_HIDDEN = window.IS_ATM_REPORTS_TAB_HIDDEN;
export const IS_DASHBOARD_CHART_TAB_HIDDEN = window.IS_DASHBOARD_CHART_TAB_HIDDEN;

//FOR CONFIGURATION SIDEBAR
export const IS_SITE_MANAGEMENT_TAB_HIDDEN = window.IS_SITE_MANAGEMENT_TAB_HIDDEN;
export const IS_ALERT_TEMPLATE_TAB_HIDDEN = window.IS_ALERT_TEMPLATE_TAB_HIDDEN;
export const IS_USERS_TAB_HIDDEN = window.IS_USERS_TAB_HIDDEN;
export const IS_NOTIFICATION_SETTING_TAB_HIDDEN = window.IS_NOTIFICATION_SETTING_TAB_HIDDEN;
export const IS_PREPOSTSETTING_TAB_HIDDEN = window.IS_PREPOSTSETTING_TAB_HIDDEN;
export const IS_EMAILSETTING = window.IS_EMAILSETTING;
export const IS_SOUNDSETTING_TAB_HIDDEN = window.IS_SOUNDSETTING_TAB_HIDDEN


//FOR ABOUT SIDEBAR
export const IS_ABOUT_MH_TAB_HIDDEN = window.IS_ABOUT_MH_TAB_HIDDEN;
export const IS_SUPPORT_TAB_HIDDEN = window.IS_SUPPORT_TAB_HIDDEN;
export const IS_TERMS__TAB_HIDDEN = window.IS_TERMS__TAB_HIDDEN;

// Below lines are Original -RUPESH
// export const VERSION = "0.5.2";

// export const AUTH_TOKEN = localStorage.getItem('authToken');
// export const AUTH_CODE = localStorage.getItem('authCode');

// export const MULTI_PLAYBACK_FLASK_URL = 'http://testnewpoc.monitoringhub.in:5005/'

//export const BASE_URL = 'https://gadtpk0jig.execute-api.ap-south-1.amazonaws.com/api/dev/' // dev, For connecting with Lambda
// export const BASE_URL = 'http://127.0.0.1:8443/api/' //For connecting with LOCAL APIFLASK 
//export const BASE_URL = 'https://dotnet.monitoringhub.in:8443/api/';
//export const BASE_URL = 'https://abduldemo.monitoringhub.in:8443/api/' //For connecting with CLOUD APIFLASK 

// export const BASE_CORE_URL = 'https://localhost:7021/api/';
//export const BASE_CORE_URL = 'http://192.168.0.98:6001/'; //this url is to be used to frontend developers how don't have Vsiual Studio.
//export const BASE_CORE_URL = 'https://testnewpoc.monitoringhub.in:5001/api/';
//export const BASE_CORE_URL = 'https://dotnet.monitoringhub.in:5001/api/';

// export const AUTH_TOKEN = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjoiOUVTeEp3R2ozTTJRSFprRDlIcHE0R2V0N0t3QTlVUC8rZ3NadkhXcDhvM0tES2RPckRFT2hVL090bldSN3hyYStrUmVqaGM4a0IxL2JjWGNUZzBENEg2SE9NU3laNUkvSUF3MzJNc25jNHNEYnRzYnptSEZXZHZ2ZFpGMHJnL2FuYzJZd1IrNWNCWT0iLCJleHAiOjE2ODQ4MjE5MDgsImlzcyI6Imh0dHA6Ly9sb2NhbGhvc3Q6NTAwMCIsImF1ZCI6Imh0dHA6Ly9sb2NhbGhvc3Q6NDIwMCJ9.EAubpXfTxZIMoFMhP00WOB0e2xFH_8sBkZf94YWsVpc";
// export const AUTH_CODE = "FEXATXPO5NSYXMCBPXEGB4EG6G7PZQHK";
// IP1Gateway
// export const BASE_URL = 'https://zoyhh139h3.execute-api.ap-south-1.amazonaws.com/v1/'
// export const MULTI_PLAYBACK_FLASK_URL = 'http://demo.monitoringhub.in:5005/'

//zicom
// export const BASE_URL = 'https://gadtpk0jig.execute-api.ap-south-1.amazonaws.com/api/testing/'

//demo/UAT
// export const BASE_URL = 'https://c1eo1b16m6.execute-api.ap-south-1.amazonaws.com/api/demo/'

//testing
// export const BASE_URL =  'https://hyx2phnurc.execute-api.ap-south-1.amazonaws.com/V1/testing/'
// export const MULTI_PLAYBACK_FLASK_URL = 'http://test.monitoringhub.in:5005/'

//cstest
// export const BASE_URL = 'https://i3ek606eu8.execute-api.ap-south-1.amazonaws.com/v1/cstest/'

//imaxsolutions
// export const BASE_URL = ' https://ejycvwe7m7.execute-api.ap-south-1.amazonaws.com/imax/imaxsolutions/'

//poc
// export const BASE_URL =  'https://jqxq0qzrlc.execute-api.ap-south-1.amazonaws.com/poc/'

//saiservice
// export const BASE_URL = 'https://d0iq0dpqzb.execute-api.ap-south-1.amazonaws.com/poc/'

//vjNetworking - IP1 - deployed on ifibre-prajyot
// export const BASE_URL = 'https://77q9lrptrc.execute-api.ap-south-1.amazonaws.com/IP1V1/'

//Emiza - IP2 - deployed on ifibre-prajyot
//  export const BASE_URL = 'https://oety4btoyb.execute-api.ap-south-1.amazonaws.com/IP2V1/'

//bvc
// export const BASE_URL =  'https://oety4btoyb.execute-api.ap-south-1.amazonaws.com/IP2V1/'
// export const MULTI_PLAYBACK_FLASK_URL = 'https://bvc.monitoringhub.in:5005/'


//IP3Gateway - creative
// export const BASE_URL = 'https://o68uhueifa.execute-api.ap-south-1.amazonaws.com/IP3V1/'

//walking
// export const BASE_URL =  'https://o68uhueifa.execute-api.ap-south-1.amazonaws.com/IP3V1/'
// export const MULTI_PLAYBACK_FLASK_URL = 'https://walkingtree.monitoringhub.in:5005/'

//IP4Gateway - WETC
// export const BASE_URL = 'https://g677f52ual.execute-api.ap-south-1.amazonaws.com/IP4V1/'

//IP5Gateway - sscommunication
// export const BASE_URL = 'https://197070g3z1.execute-api.ap-south-1.amazonaws.com/IP5V1/'

//IP6Gateway - SWAN turbine service
// export const BASE_URL = 'https://rvaovsi730.execute-api.ap-south-1.amazonaws.com/IP6V1/'

//IP7Gateway - Chowgule industries
// export const BASE_URL = 'https://3ooi4xfvi7.execute-api.ap-south-1.amazonaws.com/IP7V1/'

//IP8Gateway i process
// export const BASE_URL = 'https://5v2v9c48sf.execute-api.ap-south-1.amazonaws.com/IP8V1/'

// Akshata Testing Code Commit
