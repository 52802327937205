import React from 'react'

import { Doughnut } from 'react-chartjs-2';

const HealthPercent = ({ systemHealthDevicesOnlineCount, systemHealthDevicesOfflineCount, onlinePercent, isDarkTheme, totalValue }) => {
    const data = {
        labels: ['Online', 'Offline'],
        datasets: [
            {
                label: 'Values',
                data: [systemHealthDevicesOnlineCount, systemHealthDevicesOfflineCount],
                borderRadius: 0,
                backgroundColor: ['#1ab243', '#dddddd'],
                borderWidth: 0,
                //hoverOffset: 4
            }
        ],
    }
    const options = {
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: false
            }
        },
        cutout: "83%",
    }
    let plugins = [{
        beforeDraw: function (chart) {
            var width = chart.width,
                height = chart.height,
                ctx = chart.ctx;
            ctx.restore();
            ctx.font = "bolder 28px sans-serif";
            ctx.textBaseline = "middle";
            var text = `${onlinePercent}%`,
                textX = Math.round((width - ctx.measureText(text).width) / 2),
                textY = height / 2;
            ctx.fillStyle = isDarkTheme ? '' : '#BABABA';
            ctx.fillText(text, textX, textY);
            ctx.save();
        }
    }]


    let value = Number(systemHealthDevicesOnlineCount) + Number(systemHealthDevicesOfflineCount);
    //console.log("Value is-------" , value);
    
    const data1 = {
        labels: ['Online', 'Offline'],
        datasets: [{
            label: 'Count - ',
            data: [systemHealthDevicesOnlineCount, systemHealthDevicesOfflineCount],
            backgroundColor: ['#7BB526', '#F32C22'],
            borderColor:  ['#7BB526', '#F32C22'],
        }]
    }

    const option1 = {
        plugins: {
            legend: {
                display: false,
                labels: {
                    position: 'bottom'
                  }
            },
            tooltip: {
                enabled: true,
            }
        },
        cutout: "83%",
    }
    const stackedText = {
        id: 'stackedText',
        afterDatasetsDraw(chart, args, options) {
            const { ctx, data, chartArea: { top, bottom, left, right, width, height } } = chart;
            console.log(data.datasets[0].data[0]);

            ctx.save();
            const fontHeight = 30;
            const halfFontHeight = fontHeight / 2;

            ctx.font = `bold ${fontHeight}px sans-serif`;
            ctx.fillStyle = isDarkTheme ? '' : '#BABABA';
            ctx.textAlign = 'center';
            var text = value;
            ctx.fillText(text, width / 2, height / 2 - 5); 

            ctx.restore();

            ctx.font = ` ${halfFontHeight}px Segoe UI`;
            ctx.fillStyle = isDarkTheme ? '' : '#BABABA';
            ctx.textAlign = 'center';
            ctx.fillText('Integrated', width / 2, height / 2 + 15);

            ctx.restore();

            ctx.font = ` ${halfFontHeight}px Segoe UI`;
            ctx.fillStyle = isDarkTheme ? '' : '#BABABA';
            ctx.textAlign = 'center';
            ctx.fillText('devices', width / 2, height / 2 + 30);
        }
    }

    return (
        <>
        {/* <Doughnut key={`${onlinePercent}_${isDarkTheme}`} data={data} options={options} plugins={plugins} /> */}
        <Doughnut key={`${onlinePercent}_${isDarkTheme}`}  data={data1}  plugins={[stackedText]} options={option1} />
        <div className='LegendTextDiv'>
            <div className='mr-3'>
                <p style={{fontSize:"14px"}}>{systemHealthDevicesOnlineCount}</p>
                <p className='d-flex align-items-center'><div style={{background: "#7BB526", marginRight:"5px"}}></div>Online</p>
            </div>
           
           <div className='mr-3'>
                <p style={{fontSize:"14px"}}>{systemHealthDevicesOfflineCount}</p>
                <p className='d-flex align-items-center'><div style={{background: "#F32C22", marginRight:"5px"}}></div>Offline</p>
           </div>
        </div>
        </>
    )
}

export default HealthPercent