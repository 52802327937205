import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";

import {
  AlertIDIcon,
  AlertInProgressImage,
  AlertOption1,
  AlertSiteIcon,
  AlertTypeIcon,
  BackArrowDevice,
  BackToLive,
  BranchIcon,
  CloseIcon,
  InfoBlue,
  InformationBlue,
  InformationWhite,
  LoadingAlert,
  MosaicExitFullscreen,
  MosaicFullscreen,
  MosaicHooterOff,
  MosaicHooterOn,
  MosaicNoStream,
  MosaicSnapshot,
  MosaicTwowayStart,
  MosaicTwowayStop,
  NoMoreAlert,
  Pause,
  Play,
  PlayBack,
  PlayBackWhite,
  PrePost,
  PtzDownArrow,
  PtzHomeIcon,
  PtzIcon,
  PtzLeftArrow,
  PtzRightArrow,
  PtzUpArrow,
  PtzZoomInIcon,
  PtzZoomOutIcon,
  Rewind,
  Save,
  SiteIcon,
  Video_Export,
} from "../../assets/images";
import "react-toastify/dist/ReactToastify.css";

import { Modal, Spinner } from "react-bootstrap";
import screenfull from "screenfull";
import { addMinutes, subMinutes, differenceInMinutes } from "date-fns";
import { toast } from "react-toastify";

import {
  GetHooterPanelDetails,
  HooterOnOffClick,
  LocalHooterRequest,
  TwoWayCommunicationRequest,
  VideoExport,
  alertCreation,
  attachalertevidences,
  checkFileDownloaded,
  localFileDownload,
} from "../../services/alertAuthService";
import {
  addrasaconversationindb,
  getstreamingurl,
} from "../../services/chatFlowService";
import { addDownload } from "../../services/downloadService";

import PopupCard from "./PopupCard";

import { WarningToast } from "../../CustomHooks/WarningToast";
import { ErrorToast } from "../../CustomHooks/ErrorToast";
import { SuccessToast } from "../../CustomHooks/SuccessToast";
import { usePageVisibility } from "../../CustomHooks/usePageVisibility";
import {
  ConvertDate,
  ConvertDateThree,
  multiTimeZoneHelper,
} from "../../utils/utils";
import { CAMERA_SINGLE_PLAYBACK } from "../../navigation/CONSTANTS";
import {
  CHAT_CAM_CHANGES,
  CLOSE_CHATFLOW_BTN,
  FILE_DOWNLOAD_CHECK_WAIT_TIME,
  HIDE_STUFF,
  IS_PTZVISIBLE,
  MULTI_TIME_ZONE,
  SPINNER_WAIT_TIME,
  AUTO_ALERT_CLOSE
} from "../../config";

const WAIT_TIME = 20000;
const RETRY_TIME = 30000;

const WebRTCPlayer = ({
  id,
  url,
  isEncode,
  upperDivCss,
  videoCss,
  item,
  fullVideoCss,
  buttonCss,
  alertCameraHeadingCss,
  alertCameraDetailCss,
  actualTime,
  alertDetail,
  playback,
  prePostDisplay,
  liveButton,
  isSinglePlayback,
  noStreamDivCss,
  exportCurrentVideoHide,
  videoExportDownload,
  exportStartTime,
  exportEndTime,
  isMultiPlay,
  differenceSecondsMain,
  secondsDiff,
  showPlaybackBtn,
  devId,
  hideTwoWay,
  imageToChat,
  showPrePostReplay,
  showAlertCreation,
  showHooterIcon,
  associateCam,
  setAssociateCam,
  showDirectToChat,
  attachEvidence,
  buttonDisable,
  displayInfo,
  alertData,
  chatFlowClicked,
  isSelected,
  setStartDateString,
  setEndDateString,
  setStartMode,
  closedAlertBtn,
  setAlertToClose,
  showName,
  stretchVideo
}) => {
  let connect_attempts = 0;
  let peer_connection;
  let send_channel;
  let ws_conn;
  let peer_id;
  let ws_port = "8444";
  let ws_server = "127.0.0.1";
  let local_stream_promise;
  let msg;
  let sdp;
  let textarea;
  let setError;
  let scaleFactor = 0.25;
  var rtc_configuration = { iceServers: [] };
  var default_constraints = { video: false, audio: true };
  
  let history = useHistory();
  const isVisible = usePageVisibility();
  console.log("alertData", alertData);
  //#region useState start
  const [played, setPlayed] = useState(0);
  const [videoId, setVideoId] = useState(id);
  const [liveId, setLiveId] = useState(id);
  const [videoUrl, setVideoUrl] = useState(url);
  const [liveUrl, setLiveUrl] = useState(url);
  const [liveMode, setLiveMode] = useState(true);
  const [playbackMode, setPlaybackMode] = useState(false);
  const [loading, setLoading] = useState(true);
  const [tempEvent, setTempEvent] = useState(null);
  const [popOpen, setPopOpen] = useState(false);
  const [snapModal, setSnapModal] = useState(false);
  const [mode, setMode] = useState("");
  const [isSoundOn, setIsSoundOn] = useState(true);
  const [isFullscreenClick, setIsFullscreenClick] = useState(false);
  const videoContainerParentRef = useRef(null);
  const videoContainerRef = useRef(null);
  const [date, setDate] = useState(subMinutes(new Date(), 15));
  const [dateString, setDateString] = useState("");
  const [dateStringTwo, setDateStringTwo] = useState("");
  const [timeString, setTimeString] = useState("");
  const [timeStringTwo, setTimeStringTwo] = useState("");
  const [timeStringMain, setTimeStringMain] = useState("+15 min");
  const [timeStringMainTwo, setTimeStringMainTwo] = useState("+5 min");
  const [differenceTime, setDifferenceTime] = useState(15);
  const [differenceTimeTwo, setDifferenceTimeTwo] = useState(15);
  const [blankDate, setBlankDate] = useState("");
  const [blankDateTwo, setBlankDateTwo] = useState("");
  const [dateTwo, setdateTwo] = useState(new Date());
  const [isAllinOneHooterOn, setIsAllinOneHooterOn] = useState(false);
  const [streamFound, setStreamFound] = useState(true);
  const [helperState, setHelperState] = useState(false);
  const [reconnectState, setReconnectState] = useState(false);
  const [webRTCexportStartTime, setWebRTCExportStartTime] =
    useState(exportStartTime);
  const [webRTCexportEndTime, setWebRTCExportEndTime] = useState(exportEndTime);
  const [webRTCExportCurrentVideoHide, setWebRTCExportCurrentVideoHide] =
    useState(exportCurrentVideoHide);
  const [runningTime, setRunningTime] = useState("00:00:00");
  const [totalTime, setTotalTime] = useState("00:15:00");
  const [webSocketState, setWebSocketState] = useState(null);
  const [timer, setTimer] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const [isOver, setIsOver] = useState(false);
  const [differenceInSeconds, setDifferenceInSeconds] = useState(900);
  const [timerInt, setTimerInt] = useState(null);
  const [pauseTimer, setPausedTimer] = useState(null);
  const [downloadingLocal, setDownloadingLocal] = useState(false);
  const [disableAlertCreation, setDisableAlertCreation] = useState(false);
  const [disableHooterBtn, setDisableHooterBtn] = useState(false);
  const [isPtzClick, setIsPtzClick] = useState(false);
  const [infoOpen, setInfoOpen] = useState(false);
  //#endregion useState end

  //#region useEffect start
  useEffect(() => {
    if (isSinglePlayback && webSocketState != null) {
      if (isVisible) {
        webSocketState.send("Play");
        setIsPaused(false);
      } else {
        webSocketState.send("Pause");
        setIsPaused(true);
      }
    }
  }, [isVisible]);

  useEffect(() => {
    if (isSinglePlayback) {
      if (isPaused) {
        clearTimeout(pauseTimer);
        let pausedTimer = setTimeout(() => {
          setIsOver(true);
        }, 90000);
        setPausedTimer(pausedTimer);
      } else {
        clearTimeout(pauseTimer);
      }
    }
  }, [isPaused]);

  useEffect(() => {
    webSocketServerConnect();

    return () => {
      ws_conn.close();
      resetVideo();
      if (peer_connection) {
        peer_connection.close();
        peer_connection = null;
      }
    };
  }, [videoUrl]);

  useEffect(() => {
    let min = secondsDiff / 60;
    let str;
    if (min < 10) {
      str = `00:0${min}:00`;
    } else {
      str = `00:${min}:00`;
    }
    setTotalTime(str);
  }, [secondsDiff]);

  useEffect(() => {
    if (!loading) {
      if (getVideoElement()?.srcObject !== tempEvent?.streams[0]) {
        setTimer(0);
        let date = new Date(null);
        date.setSeconds(0);
        let hhmmssFormat = date.toISOString().substring(11, 19);
        setRunningTime(hhmmssFormat);
        setIsOver(false);
        if (getVideoElement() != null) {
          getVideoElement().srcObject = tempEvent?.streams[0];
        } else {
          restartPlayer();
        }
      }
    }
  }, [loading]);

  useEffect(() => {
    document.addEventListener("fullscreenchange", exitHandler);
    function exitHandler() {
      if (
        !document.fullscreenElement &&
        !document.webkitIsFullScreen &&
        !document.mozFullScreen &&
        !document.msFullscreenElement
      ) {
        setIsFullscreenClick(false);
      } else {
      }
    }
    return () => {
      document.removeEventListener("fullscreenchange", exitHandler);
    };
  }, [isFullscreenClick]);

  useEffect(() => {
    let currentTime;
    if (MULTI_TIME_ZONE) {
      currentTime = multiTimeZoneHelper(item.OffsetMinutes);
    } else {
      currentTime = new Date();
    }
    let d = subMinutes(currentTime, 15);
    let temp = ConvertDate(d);
    let temp_date = temp.slice(0, 10).replace(/-/g, "_");
    setDateString(temp_date);
  }, []);

  useEffect(() => {
    let d;
    if (MULTI_TIME_ZONE) {
      d = multiTimeZoneHelper(item.OffsetMinutes);
    } else {
      d = new Date();
    }
    let temp = ConvertDate(d);
    let temp_date = temp.slice(0, 10).replace(/-/g, "_");
    setDateStringTwo(temp_date);
  }, []);

  useEffect(() => {
    if (snapModal) {
      if (isFullscreenClick) {
        handleFullScreen();
      }
      shoot();
    }
  }, [snapModal]);

  //For Getting No Stream View
  useEffect(() => {
    let timer = setTimeout(() => {
      if (displayInfo) {
        return;
      }
      if (loading) {
        setStreamFound(false);
        setHelperState(true);
        setReconnectState(false);
        webSocketState?.close();
        resetVideo();
        if (peer_connection) {
          peer_connection.close();
          peer_connection = null;
        }
      }
    }, WAIT_TIME);

    return () => {
      clearTimeout(timer);
    };
  }, [loading, reconnectState]);

  //For Getting Loading Streaming View
  useEffect(() => {
    if (displayInfo) {
      return;
    }
    if (helperState && !liveMode) {
      setStreamFound(true);
      setLoading(true);
      setReconnectState(true);
      setHelperState(false);
      WarningToast(
        "Playback stream could not be found, going back to live stream."
      );
      goBackToLive();
      return;
    }
    if (helperState) {
      let timerTwo = setTimeout(() => {
        setStreamFound(true);
        reConnection();
        setReconnectState(true);
        setHelperState(false);
      }, RETRY_TIME);
      return () => {
        clearTimeout(timerTwo);
      };
    }
  }, [helperState]);

  useEffect(() => {
    if (isSinglePlayback) {
      if (webSocketState != null) {
        clearTimeout(timerInt);
        const newTimer = setTimeout(() => {
          if (isPaused) {
            webSocketState.send("Play");
            setIsPaused(false);
          }
          webSocketState.send("seek " + played);
        }, 500);
        setTimerInt(newTimer);
      }
    }
  }, [played]);

  useEffect(() => {
    if (isSinglePlayback) {
      let interval = setInterval(() => {
        let timePlayed;
        if (secondsDiff <= timer) {
          timePlayed = timer;
          setIsOver(true);
        } else {
          if (isPaused) {
            timePlayed = timer;
          } else {
            timePlayed = timer + 1;
          }
        }
        let date = new Date(null);
        date.setSeconds(timePlayed);
        let hhmmssFormat = date.toISOString().substring(11, 19);
        setRunningTime(hhmmssFormat);
        setTimer(timePlayed);
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [timer, isPaused]);

  useEffect(() => {
    let timer;
    if (downloadingLocal) {
      //To Set Timeout of 20 seconds and then display the loader overlay;
      timer = setTimeout(() => {
        setDownloadingLocal(false);
        SuccessToast("Check your downloads folder for exported video");
      }, SPINNER_WAIT_TIME);
    }
    return () => clearTimeout(timer);
  }, [downloadingLocal]);

  useEffect(() => {
    let indivTimer;
    if(AUTO_ALERT_CLOSE){
      if(displayInfo){
        indivTimer = setTimeout(() => {
          if(!isSelected){
            setAlertToClose((prevItems) => [...prevItems, alertData.alertId])
          }
        }, 20000)
      }
    }
    return () => clearTimeout(indivTimer);
  }, [isSelected])

  //#endregion useEffect end

  //#region functions start
  const goBackToLive = () => {
    setVideoId(liveId);
    setVideoUrl(liveUrl);
    setLiveMode(true);
    setPlaybackMode(false);
    setWebRTCExportCurrentVideoHide(true);
    setStartMode(true);
    if (CHAT_CAM_CHANGES) {
      if (associateCam != null || associateCam != undefined) {
        let text = `${
          item.CameraName || item.cameraName || item.deviceName
        } -  Live Stream played.`;
        associateAttach(text);
      }
    }
  };

  const restartPrePost = () => {
    let randomNum = Math.floor(Math.random() * 100);
    reConnectionTwo(videoId + "" + randomNum);
  };

  const restartPlayer = () => {
    let randomNum = Math.floor(Math.random() * 100);
    reConnectionTwo(videoId + "" + randomNum);
  };

  const webSocketServerConnect = () => {
    setLoading(true);
    connect_attempts++;
    if (connect_attempts > 3) {
      // setError("Too many connection attempts, aborting. Refresh page to try again");
      return;
    }
    peer_id = videoId;
    let ws_url = "ws://" + ws_server + ":" + ws_port;
    ws_conn = new WebSocket(ws_url);
    setWebSocketState(ws_conn);
    /* When connected, immediately register with the server */
    let tempIsEncode = isEncode;
    if (playbackMode) {
      tempIsEncode = item.isPlaybackTranscoded;
    }

    ws_conn.addEventListener("open", (event) => {
      let brandName = item.BrandName || item.brandName;
      let commPort = item.CommunicationPort || item.communicationPort;
      if (brandName != 0) {
        if (brandName.includes(" ")) {
          brandName = brandName.replace(/\s/g, "");
        }
      }
      let isPTZ = item.IsPTZ == "1" ? item.IsPTZ : "0";
      ws_conn.send(
        "HELLO " +
          peer_id +
          " " +
          videoUrl +
          " " +
          tempIsEncode +
          " 0" +
          " " +
          brandName +
          " " +
          isPTZ +
          " " +
          commPort
      );
    });
    ws_conn.addEventListener("error", onServerError);
    ws_conn.addEventListener("message", onServerMessage);
    ws_conn.addEventListener("close", onServerClose);
  };

  const webSocketServerConnectTwo = (localId) => {
    setLoading(true);
    connect_attempts++;
    if (connect_attempts > 3) {
      // setError("Too many connection attempts, aborting. Refresh page to try again");
      return;
    }
    // Fetch the peer localId to use
    peer_id = localId;
    let ws_url = "ws://" + ws_server + ":" + ws_port;
    ws_conn = new WebSocket(ws_url);
    setWebSocketState(ws_conn);
    /* When connected, immediately register with the server */
    ws_conn.addEventListener("open", (event) => {
      // ws_conn.send('HELLO ' + peer_id + " " + videoUrl + ' ' + isEncode + ' 0');
      let brandName = item.BrandName || item.brandName;
      let commPort = item.CommunicationPort || item.communicationPort;
      if (brandName != 0) {
        if (brandName.includes(" ")) {
          brandName = brandName.replace(/\s/g, "");
        }
      }
      let isPTZ = item.IsPTZ == "1" ? item.IsPTZ : "0";
      ws_conn.send(
        "HELLO " +
          peer_id +
          " " +
          videoUrl +
          " " +
          isEncode +
          " 0" +
          " " +
          brandName +
          " " +
          isPTZ +
          " " +
          commPort
      );
    });
    ws_conn.addEventListener("error", onServerError);
    ws_conn.addEventListener("message", onServerMessage);
    ws_conn.addEventListener("close", onServerClose);
  };

  const onServerError = (event) => {};

  const onServerMessage = (event) => {
    switch (event.data) {
      case "HELLO":
        ws_conn.send("SESSION " + videoId);
        return;
      case "SESSION_OK":
        return;
      default:
        if (event.data.startsWith("ERROR")) {
          handleIncomingError(event.data);
          return;
        }
        if (event.data.startsWith("OFFER_REQUEST")) {
          // The peer wants us to set up and then send an offer
          if (!peer_connection) {
            peer_connection = new RTCPeerConnection(rtc_configuration);
            send_channel = peer_connection.createDataChannel("label", null);
            send_channel.onopen = handleDataChannelOpen;
            send_channel.onmessage = handleDataChannelMessageReceived;
            send_channel.onerror = handleDataChannelError;
            send_channel.onclose = handleDataChannelClose;
            peer_connection.ondatachannel = onDataChannel;
            peer_connection.ontrack = onRemoteTrack;
            local_stream_promise = getLocalStream()
              .then((stream) => {
                peer_connection?.addStream(stream);
                return stream;
              })
              .catch(setError);
          }
          // createCall(null).then(generateOffer);
        } else {
          // Handle incoming JSON SDP and ICE messages
          try {
            msg = JSON.parse(event.data);
          } catch (e) {
            if (e instanceof SyntaxError) {
              handleIncomingError("Error parsing incoming JSON: " + event.data);
            } else {
              handleIncomingError(
                "Unknown error parsing response: " + event.data
              );
            }
            return;
          }

          // Incoming JSON signals the beginning of a call
          if (!peer_connection) {
            peer_connection = new RTCPeerConnection(rtc_configuration);
            send_channel = peer_connection.createDataChannel("label", null);
            send_channel.onopen = handleDataChannelOpen;
            send_channel.onmessage = handleDataChannelMessageReceived;
            send_channel.onerror = handleDataChannelError;
            send_channel.onclose = handleDataChannelClose;
            peer_connection.ondatachannel = onDataChannel;
            peer_connection.ontrack = onRemoteTrack;
            local_stream_promise = getLocalStream()
              .then((stream) => {
                //debugger
                peer_connection?.addStream(stream);
                return stream;
              })
              .catch(setError);
          }

          // createCall(msg);

          if (msg.sdp != null) {
            onIncomingSDP(msg.sdp);
          } else if (msg.ice != null) {
            onIncomingICE(msg.ice);
          } else {
            handleIncomingError("Unknown incoming JSON: " + msg);
          }
        }
    }
  };

  const generateOffer = () => {
    //debugger
    peer_connection.createOffer().then(onLocalDescription).catch(setError);
  };

  const onIncomingSDP = (sdp) => {
    peer_connection
      .setRemoteDescription(sdp)
      .then(() => {
        if (sdp.type != "offer") return;
        peer_connection.createAnswer();
        local_stream_promise
          .then((stream) => {
            peer_connection
              ?.createAnswer()
              .then(onLocalDescription)
              .catch(setError);
          })
          .catch(setError);
      })
      .catch(setError);
  };

  const onLocalDescription = (desc) => {
    peer_connection?.setLocalDescription(desc).then(function () {
      sdp = { sdp: peer_connection.localDescription };
      ws_conn.send(JSON.stringify(sdp));
    });
  };

  const onIncomingICE = (ice) => {
    let candidate = new RTCIceCandidate(ice);
    peer_connection.addIceCandidate(candidate).catch(setError);
  };

  const onServerClose = (event) => {
    resetVideo();
    if (peer_connection) {
      peer_connection.close();
      peer_connection = null;
    }
  };

  const resetVideo = () => {
    if (local_stream_promise)
      local_stream_promise.then((stream) => {
        if (stream) {
          stream.getTracks().forEach(function (track) {
            track.stop();
          });
        }
      });

    // Reset the video element and stop showing the last received frame
    var videoElement = getVideoElement();
    if (videoElement != null) {
      videoElement.pause();
      videoElement.src = "";
      videoElement.load();
    }
  };

  const getVideoElement = () => {
    return document.getElementById(videoId);
  };

  const handleIncomingError = (error) => {
    resetState();
  };

  const resetState = () => {
    ws_conn.close();
  };

  const playVideo = () => {
    webSocketState.send("Play");
    setIsPaused(false);
  };

  const pauseVideo = () => {
    webSocketState.send("Pause");
    setIsPaused(true);
  };

  function Left() {
    //send_channel.send("Replay");
    webSocketState.send("left");
  }

  function Right() {
    //send_channel.send("Replay");
    webSocketState.send("right");
  }

  function Up() {
    //send_channel.send("Replay");
    webSocketState.send("up");
  }

  function Down() {
    //send_channel.send("Replay");
    webSocketState.send("down");
  }

  function ZoomIn() {
    //send_channel.send("Replay");
    webSocketState.send("zoomin");
  }

  function ZoomOut() {
    //send_channel.send("Replay");
    webSocketState.send("zoomout");
  }

  const restartVideo = () => {
    if (isOver) {
      let randomNum = Math.floor(Math.random() * 100);
      reConnectionTwo(videoId + "" + randomNum);
      setIsOver(false);
    } else {
      if (isPaused) {
        webSocketState.send("Play");
        setIsPaused(false);
      }
      webSocketState.send("Replay");
      let date = new Date(null);
      date.setSeconds(0);
      let hhmmssFormat = date.toISOString().substring(11, 19);
      setRunningTime(hhmmssFormat);
      setTimer(0);
    }
  };

  const createCall = (msg) => {
    connect_attempts = 0;

    peer_connection = new RTCPeerConnection(rtc_configuration);
    send_channel = peer_connection.createDataChannel("label", null);
    send_channel.onopen = handleDataChannelOpen;
    send_channel.onmessage = handleDataChannelMessageReceived;
    send_channel.onerror = handleDataChannelError;
    send_channel.onclose = handleDataChannelClose;
    peer_connection.ondatachannel = onDataChannel;
    peer_connection.ontrack = onRemoteTrack;
    /* Send our video/audio to the other peer */
    local_stream_promise = getLocalStream()
      .then((stream) => {
        peer_connection.addStream(stream);
        return stream;
      })
      .catch(setError);

    if (msg != null && !msg.sdp) {
      console.log("WARNING: First message wasn't an SDP message!?");
    }

    peer_connection.onicecandidate = (event) => {
      // We have a candidate, send it to the remote party with the
      // same uuid
      //debugger
      if (event.candidate == null) {
      } else {
        ws_conn.send(JSON.stringify({ ice: event.candidate }));
      }
    };

    if (msg != null)
      console.log("Created peer connection for call, waiting for SDP");
    return local_stream_promise;
  };

  const handleDataChannelOpen = (event) => {
    //debugger
  };

  const handleDataChannelMessageReceived = (event) => {
    if (typeof event.data === "string" || event.data instanceof String) {
      textarea = document.getElementById("text");
      textarea.value = textarea.value + "\n" + event.data;
    } else {
    }
    send_channel.send("Hi! (from browser)");
  };

  const handleDataChannelError = (error) => {
    //debugger
  };

  const handleDataChannelClose = (event) => {
    //debugger
  };

  function onDataChannel(event) {
    //debugger
    let receiveChannel = event.channel;
    receiveChannel.onopen = handleDataChannelOpen;
    receiveChannel.onmessage = handleDataChannelMessageReceived;
    receiveChannel.onerror = handleDataChannelError;
    receiveChannel.onclose = handleDataChannelClose;
  }

  function onRemoteTrack(event) {
    setLoading(false);
    setTempEvent(event);
  }

  function getLocalStream() {
    var constraints;
    try {
      constraints = default_constraints;
    } catch (e) {
      console.error(e);
      constraints = default_constraints;
    }
    //return null;
    // Add local stream
    if (navigator.mediaDevices.getUserMedia) {
      return navigator.mediaDevices.getUserMedia(constraints);
    } else {
      errorUserMediaHandler();
    }
  }

  function errorUserMediaHandler() {
    //debugger
  }

  const handleFullScreen = () => {
    screenfull.toggle(videoContainerParentRef.current);
    setIsFullscreenClick(!screenfull.isFullscreen);
  };

  const dateChange = (v, e) => {
    let d = ConvertDate(v);
    let temp = d.slice(0, 10).replace(/-/g, "_");
    setDateString(temp);
    setDateStringTwo(temp);
    setDate(v);
    setdateTwo(v);
  };

  const getDataFromChild = (data) => {
    let timeStringMain = "";
    if (data === "") {
      let blankArr = blankDate.split("_");
      for (let i = 0; i < blankArr.length; i++) {
        if (blankArr[i].length == 1) {
          blankArr[i] = "0" + blankArr[i].toString();
        }
      }
      let blankStr = blankArr.join("_");
      timeStringMain = blankStr;
    } else {
      timeStringMain = data;
    }
    let temp = dateString + "_" + timeStringMain;
    let arr = temp.split("_");
    let SD = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]);
    let currentTime = new Date();
    if (MULTI_TIME_ZONE) {
      currentTime = multiTimeZoneHelper(item.OffsetMinutes);
    }
    const difference = differenceInMinutes(currentTime, SD);

    if (mode == "Playback" && difference < 15 && difference >= 0) {
      WarningToast("Selected Time is less than 15 min !");
      timeStringMain = "+" + difference + " min ";
      setDifferenceTime(parseInt(difference));
      let difSec = parseInt(difference) * 60;
      setDifferenceInSeconds(difSec);
      setTimeStringMain(timeStringMain);
    } else if (mode == "Playback") {
      setDifferenceTime(15);
      setDifferenceInSeconds(900);
      setTimeStringMain("+15 min");
    }

    if (mode == "Export" && difference < 5 && difference >= 0) {
      WarningToast("Selected Time is less than 5 min !");

      timeStringMain = "+" + difference + " min ";
      setDifferenceTimeTwo(parseInt(difference));
      setTimeStringMainTwo(timeStringMain);
    } else if (mode == "Export") {
      setDifferenceTimeTwo(5);
      setTimeStringMainTwo("+5 min");
    }

    setTimeString(data);
  };

  const getDataFromChildTwo = (data) => {
    let timeStringMainTwo = "";
    if (data === "") {
      let blankArr = blankDateTwo.split("_");
      for (let i = 0; i < blankArr.length; i++) {
        if (blankArr[i].length == 1) {
          blankArr[i] = "0" + blankArr[i].toString();
        }
      }
      let blankStr = blankArr.join("_");
      timeStringMainTwo = blankStr;
    } else {
      timeStringMainTwo = data;
    }
    let temp = dateStringTwo + "_" + timeStringMainTwo;
    let arr = temp.split("_");
    let SD = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]);
    let currentTime = new Date();
    if (MULTI_TIME_ZONE) {
      currentTime = multiTimeZoneHelper(item.OffsetMinutes);
    }
    const difference = differenceInMinutes(currentTime, SD);
    if (difference < 5 && difference >= 0) {
      WarningToast("Selected Time is less than 5 min !");

      timeStringMainTwo = difference + " min ";
      setDifferenceTimeTwo(parseInt(difference));
      setTimeStringMainTwo(timeStringMain);
    } else {
      setDifferenceTimeTwo(5);
      setTimeStringMainTwo("5 min");
    }

    setTimeStringTwo(data);
  };

  const onSubmitBtn = () => {
    let timeStringMain = "";
    if (timeString === "") {
      let blankArr = blankDate.split("_");
      for (let i = 0; i < blankArr.length; i++) {
        if (blankArr[i].length == 1) {
          blankArr[i] = "0" + blankArr[i].toString();
        }
      }
      let blankStr = blankArr.join("_");
      timeStringMain = blankStr;
    } else {
      timeStringMain = timeString;
    }
    let temp = dateString + "_" + timeStringMain;
    let arr = temp.split("_");
    let SD = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]);
    let startString = ConvertDate(SD);
    let ED = addMinutes(SD, differenceTime);
    let toDateTime = ConvertDate(ED);

    let currentTime = new Date();
    if (MULTI_TIME_ZONE) {
      currentTime = multiTimeZoneHelper(item.OffsetMinutes);
    }
    if (differenceTime == 0) {
      ErrorToast("The selected time is  greater from the current time!");
      return;
    }
    if (SD > currentTime && differenceTime > 0) {
      ErrorToast("The selected time is  greater from the current time!");
      return;
    } else {
      let tempState = {
        startTime: temp,
        endTime: toDateTime.replace(/[ :-]/g, "_"),
        deviceId: item.ParentCameraID,
        cameraName: item.CameraName || item.cameraName,
        siteName: item.SiteName,
        dateTimeOne: SD,
        dateTimeTwo: ED,
        streamName: item.StreamName,
        isTranscoded: item.isTranscoded,
      };

      if (playback) {
        setWebRTCExportStartTime(temp);
        setWebRTCExportEndTime(toDateTime.replace(/[ :-]/g, "_"));
        let obj = {
          action: "StartPlaybackUrl",
          startTime: temp,
          endTime: toDateTime.replace(/[ :-]/g, "_"),
          deviceId: item.ParentCameraID || devId,
          alertId: "",
        };
        console.log("tempvvv", temp);
        console.log("tempvvv", toDateTime);
        console.log("tempvvv", SD);
        getstreamingurl(obj)
          .then((res) => {
            if (res.status == "Ok") {
              setVideoId(id + "8");
              setVideoUrl(res.rtspURL);
              setPopOpen(false);
              setPlaybackMode(true);
              setWebRTCExportCurrentVideoHide(false);
              setLiveMode(false);
              setStartMode(false);
              setStartDateString(startString);
              setEndDateString(toDateTime);
            }
          })
          .catch((err) => console.log(err));
      } else {
        // For Single Page Route
        history.push({
          pathname: CAMERA_SINGLE_PLAYBACK,
          state: {
            startTime: temp,
            endTime: toDateTime.replace(/[ :-]/g, "_"),
            deviceId: item.ParentCameraID,
            cameraName: item.CameraName,
            siteName: item.SiteName,
            dateTimeOne: SD,
            dateTimeTwo: ED,
            streamName: item.StreamName,
            isTranscoded: item.isPlaybackTranscoded,
            item: item,
            secondsDiff: differenceInSeconds,
          },
        });
      }
    }
  };

  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  const onExportBtn = () => {
    let timeStringMain = "";
    if (timeString === "") {
      let blankArr = blankDate.split("_");
      for (let i = 0; i < blankArr.length; i++) {
        if (blankArr[i].length == 1) {
          blankArr[i] = "0" + blankArr[i].toString();
        }
      }
      let blankStr = blankArr.join("_");
      timeStringMain = blankStr;
    } else {
      timeStringMain = timeString;
    }
    let temp = dateString + "_" + timeStringMain;
    let arr = temp.split("_");
    let startD = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]);
    let SD = formatDate(
      new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5])
    );

    let timeStringMainTwo = "";
    if (timeStringTwo === "") {
      let blankArr = blankDateTwo.split("_");
      for (let i = 0; i < blankArr.length; i++) {
        if (blankArr[i].length == 1) {
          blankArr[i] = "0" + blankArr[i].toString();
        }
      }
      let blankStr = blankArr.join("_");
      timeStringMainTwo = blankStr;
    } else {
      timeStringMainTwo = timeStringTwo;
    }
    let tempTwo = dateStringTwo + "_" + timeStringMainTwo;
    let arrTwo = tempTwo.split("_");
    let endD = new Date(
      arrTwo[0],
      arrTwo[1] - 1,
      arrTwo[2],
      arrTwo[3],
      arrTwo[4],
      arrTwo[5]
    );
    let ED = formatDate(
      new Date(
        arrTwo[0],
        arrTwo[1] - 1,
        arrTwo[2],
        arrTwo[3],
        arrTwo[4],
        arrTwo[5]
      )
    );
    let currentTime = new Date();
    if (MULTI_TIME_ZONE) {
      currentTime = multiTimeZoneHelper(item.OffsetMinutes);
    }

    let diff = differenceInMinutes(endD, startD);
    let current = new Date();
    if (MULTI_TIME_ZONE) {
      current = multiTimeZoneHelper(item.OffsetMinutes);
    }
    if (startD > current) {
      ErrorToast(
        "Invalid timings, start date time is greater than current time."
      );
      return;
    }

    if (endD > current) {
      ErrorToast(
        "Invalid timings, end date time is greater than current time."
      );
      return;
    }

    if (diff > 5) {
      ErrorToast("Max limit for downloading of video is 5 mins");
      return;
    } else if (diff < 0) {
      ErrorToast("Invalid timings");
      return;
    } else if (diff == 5 && SD.slice(-2) != ED.slice(-2)) {
      ErrorToast("Max limit for downloading of video is 5 mins");
      return;
    }

    if (differenceTimeTwo == 0) {
      ErrorToast("The selected time is greater from the current time!");
      return;
    }
    if (SD > currentTime && differenceTimeTwo > 0) {
      ErrorToast("The selected time is greater from the current time!");
      return;
    } else {
      // Here will need to call the API to initiate download for the video with the parameters of its start time and end time.
      let user = JSON.parse(localStorage.getItem("user"));
      let d;
      if (MULTI_TIME_ZONE) {
        d = multiTimeZoneHelper(item.OffsetMinutes);
      } else {
        d = new Date();
      }
      // let currentDate = formatDate(new Date());
      let currentDate = formatDate(d);
      let devTemp =
        item.ParentCameraID || item.associatedCameraId || item.deviceId;
      let obj = {
        VideoExportLogID: "1",
        RequestedBy: user.UserUniqueID,
        RequestedByName: user.FullName,
        ExportRequestedOn: currentDate,
        DeviceID: devTemp.toString(),
        DeviceName: item.CameraName || item.cameraName || item.deviceName,
        ExportStatus: "0",
        ExportStatusDateTime: "",
        ExportStatusLog: "",
        DownloadLink: "",
        DownloadStatus: "",
        DownloadDateTime: "",
        DeleteFlag: "0",
        ExportFromDateTime: SD,
        ExportToDateTime: ED,
      };

      //Download API Call
      // addDownload(obj)
      //   .then((res) => {
      //     if (res.Status == "success") {
      //       SuccessToast(`The download request has been raised successfully. `)
      //       setPopOpen(false);
      //     } else {
      //       showErrorToast(res.Message)
      //     }
      //   }).catch(err => console.log(err));

      //Local API Call to Download
      let cName = item.CameraName || item.cameraName || item.deviceName;
      let rName = cName.replaceAll(" ", "_");
      let tempDate;
      if (MULTI_TIME_ZONE) {
        tempDate = multiTimeZoneHelper(item.OffsetMinutes);
      } else {
        tempDate = new Date();
      }
      let fileName = `${rName}-${ConvertDateThree(tempDate)}-VideoExport.mp4`;
      let localObj = {
        privateIpAddress: item.privateIPAddress || item.privateIpAddress,
        cam_user: item.camUserName,
        password: item.camPassword,
        brandName: item.BrandName || item.brandName,
        startTime: SD,
        endTime: ED,
        channelNo: item.channelNum,
        // playbackSubType: playbackSubType,
        communicationPort: item.CommunicationPort || item.communicationPort,
        // internetPort: obj.InternetProtocolPort
      };
      //Bug - 7098 Resolution
      // setDownloadingLocal(true);
      WarningToast(
        "Check your downloads folder for exported video after 30-40 seconds."
      );
      setPopOpen(false);
      let {
        privateIpAddress,
        cam_user,
        password,
        brandName,
        startTime,
        endTime,
        channelNo,
        communicationPort,
      } = localObj;
      localFileDownload(
        privateIpAddress,
        communicationPort,
        cam_user,
        password,
        brandName,
        fileName,
        startTime,
        endTime,
        channelNo
      )
        .then((data) => {
          //Call API to check if file is downloaded.
          setTimeout(() => {
            checkFile(fileName);
          }, FILE_DOWNLOAD_CHECK_WAIT_TIME);
          // checkFileDownloaded(fileName)
          //   .then((res) => {
          //     if(res.res == "Yes"){
          //       SuccessToast(fileName+ " video exported successfully")
          //     }else{
          //       ErrorToast(fileName+ " video failed to export")
          //     }
          //   })
          //   .catch(err => {
          //     ErrorToast(fileName+ " video failed to export")
          //   })
        })
        .catch((err) => console.log(err));
    }
  };

  const downloadDirectPlayback = () => {
    let arr = webRTCexportStartTime.split("_");
    let SD = formatDate(
      new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5])
    );
    let arrTwo = webRTCexportEndTime.split("_");
    let ED = formatDate(
      new Date(
        arrTwo[0],
        arrTwo[1] - 1,
        arrTwo[2],
        arrTwo[3],
        arrTwo[4],
        arrTwo[5]
      )
    );
    let user = JSON.parse(localStorage.getItem("user"));
    let d;
    if (MULTI_TIME_ZONE) {
      d = multiTimeZoneHelper(item.OffsetMinutes);
    } else {
      d = new Date();
    }
    let currentDate = formatDate(d);
    let obj = {
      VideoExportLogID: "1",
      RequestedBy: user.UserUniqueID,
      RequestedByName: user.FullName,
      ExportRequestedOn: currentDate,
      DeviceID: item?.ParentCameraID?.toString() || id.toString(),
      DeviceName: item?.CameraName || item?.cameraName,
      ExportStatus: "0",
      ExportStatusDateTime: "",
      ExportStatusLog: "",
      DownloadLink: "",
      DownloadStatus: "",
      DownloadDateTime: "",
      DeleteFlag: "0",
      ExportFromDateTime: SD,
      ExportToDateTime: ED,
    };

    //Download APi Call
    // addDownload(obj)
    //   .then((res) => {
    //     if (res.Status == "success") {
    //       SuccessToast(`The download request has been raised successfully. `)
    //       setPopOpen(false);
    //     }
    //   }).catch(err => console.log(err));

    //Local API Call
    let cName = item.CameraName || item.cameraName || item.deviceName;
    let rName = cName.replaceAll(" ", "_");
    let temp;
    if (MULTI_TIME_ZONE) {
      temp = multiTimeZoneHelper(item.OffsetMinutes);
    } else {
      temp = new Date();
    }
    let fileName = `${rName}-${ConvertDateThree(temp)}-VideoExport.mp4`;
    let localObj = {
      privateIpAddress: item.privateIPAddress || item.privateIpAddress,
      cam_user: item.camUserName,
      password: item.camPassword,
      brandName: item.BrandName || item.brandName,
      startTime: SD,
      endTime: ED,
      channelNo: item.channelNum,
      // playbackSubType: playbackSubType,
      communicationPort: item.CommunicationPort || item.communicationPort,
      // internetPort: obj.InternetProtocolPort
    };
    //Bug - 7098 Resolution
    // setDownloadingLocal(true);
    WarningToast(
      "Check your downloads folder for exported video after 30-40 seconds."
    );
    setPopOpen(false);
    let {
      privateIpAddress,
      cam_user,
      password,
      brandName,
      startTime,
      endTime,
      channelNo,
      communicationPort,
    } = localObj;
    localFileDownload(
      privateIpAddress,
      communicationPort,
      cam_user,
      password,
      brandName,
      fileName,
      startTime,
      endTime,
      channelNo
    )
      .then((data) => {
        setTimeout(() => {
          checkFile(fileName);
        }, FILE_DOWNLOAD_CHECK_WAIT_TIME);
        // checkFileDownloaded(fileName)
        // .then((res) => {
        //   if(res.res == "Yes"){
        //     SuccessToast(fileName+ " video exported successfully")
        //   }else{
        //     ErrorToast(fileName+ "video failed to export")
        //   }
        // })
        // .catch(err => {
        //   ErrorToast(fileName+ " video failed to export")
        // })
      })
      .catch((err) => console.log(err));
  };

  const checkFile = (fileName) => {
    checkFileDownloaded(fileName)
      .then((res) => {
        if (res.res == "Yes") {
          SuccessToast(fileName + " video exported successfully");
        } else {
          ErrorToast(fileName + "video failed to export");
        }
      })
      .catch((err) => {
        ErrorToast(fileName + " video failed to export");
      });
  };

  function capture(video, scaleFactor) {
    if (scaleFactor == null) {
      scaleFactor = 1;
    }
    let h = video.videoHeight;
    let w = video.videoWidth;
    let canvas = document.createElement("canvas");
    canvas.setAttribute("id", "canvasId");
    canvas.width = w;
    canvas.height = h;
    let ctx = canvas.getContext("2d");
    let blobd = canvas.toBlob((blob) => {
      console.log("Canvas", blob);
    });
    let url = canvas.toDataURL();
    ctx.drawImage(video, 0, 0, w, h);
    return canvas;
  }

  const downloadImage = () => {
    let canvas = document.getElementById("canvasId");
    let image = canvas.toDataURL();
    let aDownloadLink = document.createElement("a");
    aDownloadLink.download = `${
      item.CameraName || item.cameraName || item.deviceName
    }_snapshot.png`;
    aDownloadLink.href = image;
    aDownloadLink.click();
  };

  const generateSnapInChat = (e) => {
    if (e.detail != 1) {
      return;
    }
    let canvas = document.getElementById("canvasId");
    let image = canvas.toDataURL();
    let fileName = `${
      item.CameraName || item.cameraName || item.deviceName
    }_${Date.now()}_snapshot.png`;
    if (CHAT_CAM_CHANGES) {
      attachalertevidencesParent(image, fileName);
    } else {
      imageToChat(image, fileName);
    }
  };

  /**
   * Invokes the <code>capture</code> function and attaches the canvas element to the DOM.
   */
  function shoot() {
    var video = document.getElementById(videoId);
    var output = document.getElementById("output");
    var canvas = capture(video, scaleFactor);
    output.innerHTML = "";
    output.append(canvas);
  }

  const GetDetailsOfPanel = (pDeviceId, pMode) => {
    GetHooterPanelDetails(pDeviceId, pMode)
      .then((res) => {
        if (res.Status == "success") {
          // client component calling
          if (res.Data.length > 0) {
            LocalHooterRequest(
              res.Data[0].IP,
              res.Data[0].Port,
              pMode,
              res.Data[0].UserName,
              res.Data[0].Password,
              res.Data[0].Brand,
              res.Data[0].BranchId
            );
          }
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (alertDetail != null || alertDetail != undefined) {
      //Check localStorage if data is present for already started hooter.
      let arr = JSON.parse(localStorage.getItem("hooterData"));
      if (arr != undefined || arr != null) {
        let devTemp =
          item.ParentCameraID || item.associatedCameraId || item.deviceId;
        if (arr.length > 0) {
          let isHooterOn = arr.filter(
            (item) =>
              item.alertId == alertDetail.alertId && item.deviceId == devTemp
          );
          if (isHooterOn.length == 1) {
            setIsAllinOneHooterOn(true);
          }
        }
      }
    }
  }, []);

  const addToLocalStorage = (alertId, deviceId) => {
    if (alertDetail == null || alertDetail == undefined) {
      return;
    }
    let isPresent = localStorage.getItem("hooterData");
    if (isPresent) {
      //Update the existing array
      let arr = JSON.parse(localStorage.getItem("hooterData"));
      let obj = {
        alertId: alertId,
        deviceId: deviceId,
      };
      arr.push(obj);
      localStorage.setItem("hooterData", JSON.stringify(arr));
    } else {
      //Add New Data in the existing array
      let arr = [];
      let obj = {
        alertId: alertId,
        deviceId: deviceId,
      };
      arr.push(obj);
      localStorage.setItem("hooterData", JSON.stringify(arr));
    }
  };

  const removeFromLocalStorage = (alertId, deviceId) => {
    if (alertDetail == null || alertDetail == undefined) {
      return;
    }
    let isPresent = localStorage.getItem("hooterData");
    if (isPresent) {
      //Check the array and remove the alert id and deviceId related entry.
      let arr = JSON.parse(localStorage.getItem("hooterData"));
      let filteredArr = arr.filter(
        (item) => item.alertId != alertId && item.deviceId != deviceId
      );
      if (filteredArr.length == 0) {
        localStorage.removeItem("hooterData");
      } else {
        localStorage.setItem("hooterData", JSON.stringify(filteredArr));
      }
    } else {
      //Add New Data in the existing array
      let arr = [];
      let obj = {
        alertId: alertId,
        deviceId: deviceId,
      };
      arr.push(obj);
      localStorage.setItem("hooterData", JSON.stringify(arr));
    }
  };

  const HooterOnOffClickRequest = (pDeviceId, pMode) => {
    GetDetailsOfPanel(pDeviceId, pMode);
    setDisableHooterBtn(true);
    HooterOnOffClick(pDeviceId, pMode)
      .then((res) => {
        if (res.Status == "success") {
          if (pMode == "on") {
            if (!res.Message.includes("success")) {
              ErrorToast(res.Message);
            } else if (
              res.Message.includes("error") ||
              res.Message.includes("failed")
            ) {
              WarningToast(res.Message);
              setIsAllinOneHooterOn(true);
              if (alertDetail != null || alertDetail != undefined) {
                addToLocalStorage(alertDetail.alertId, pDeviceId);
              }
            } else {
              SuccessToast(res.Message);
              setIsAllinOneHooterOn(true);
              if (alertDetail != null || alertDetail != undefined) {
                addToLocalStorage(alertDetail.alertId, pDeviceId);
              }
            }
            if (CHAT_CAM_CHANGES) {
              associateAttach(
                `${
                  item.CameraName || item.cameraName || item.deviceName
                } - Hooter Started`
              );
            } else {
              if (attachEvidence != undefined) {
                attachEvidence(
                  `${
                    item.CameraName || item.cameraName || item.deviceName
                  } - Hooter Started`
                );
              }
            }
          } else {
            if (!res.Message.includes("success")) {
              ErrorToast(res.Message);
            }
            if (
              res.Message.includes("error") ||
              res.Message.includes("failed")
            ) {
              WarningToast(res.Message);
              setIsAllinOneHooterOn(false);
              if (alertDetail != null || alertDetail != undefined) {
                removeFromLocalStorage(alertDetail.alertId, pDeviceId);
              }
            } else {
              SuccessToast(res.Message);
              setIsAllinOneHooterOn(false);
              if (alertDetail != null || alertDetail != undefined) {
                removeFromLocalStorage(alertDetail.alertId, pDeviceId);
              }
            }
            if (CHAT_CAM_CHANGES) {
              associateAttach(
                `${
                  item.CameraName || item.cameraName || item.deviceName
                } - Hooter Stopped`
              );
            } else {
              if (attachEvidence != undefined) {
                attachEvidence(
                  `${
                    item.CameraName || item.cameraName || item.deviceName
                  } - Hooter Stopped`
                );
              }
            }
          }
          setDisableHooterBtn(false);
        } else {
          ErrorToast(
            `${
              item.CameraName || item.cameraName || item.deviceName
            } - Hooter Activation Failed`
          );
          setDisableHooterBtn(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setDisableHooterBtn(false);
      });
  };

  const getPanelHooterDetails = () => {};

  const associateAttach = (text) => {
    let ConversationId =
      "AlertID_" +
      alertDetail.alertId +
      "_UserID_" +
      localStorage.getItem("UserID");
    let LoggedInUser = JSON.parse(localStorage.getItem("user"));
    let myCurrentDatetime = new Date();
    let _datetime = ConvertDate(myCurrentDatetime);
    let rasaconvobj = {
      evidenceType: "comment",
      text: text,
      timestamp: _datetime,
      type: "user",
    };
    addrasaconversationindb({
      assignedTo: LoggedInUser.UserID,
      rasaConversationID: ConversationId,
      uploadType: "file",
      evidence: rasaconvobj,
      conversation: null,
      userType: "operator",
      createdOn: _datetime,
    })
      .then((resp) => {
        if (resp.status === "Ok") {
          if (CHAT_CAM_CHANGES) {
            let d = associateCam.map((item) => {
              let obj;
              if (item.alertId == alertDetail.alertId) {
                obj = {
                  ...item,
                  shouldRefresh: true,
                };
              } else {
                obj = {
                  ...item,
                };
              }
              return obj;
            });
            setAssociateCam(d);
          }
        } else {
          console.error(
            "Error while saving evidence to databse ",
            resp.message
          );
        }
      })
      .catch((err) => console.log(err));
  };

  const attachalertevidencesParent = (e, fname) => {
    let LoggedInUser = JSON.parse(localStorage.getItem("user"));
    try {
      var obj = {
        evidenceFileName: fname,
        alertId: alertDetail.alertId,
        userId: LoggedInUser.UserID,
        base64String: e,
      };
      attachalertevidences(obj).then((evidenceRes) => {
        if (evidenceRes.status === "Ok") {
          OnAttachedSuccess(evidenceRes);
        } else {
          ErrorToast(evidenceRes.message);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const OnAttachedSuccess = (evidenceRes) => {
    try {
      let ConversationId =
        "AlertID_" +
        alertDetail.alertId +
        "_UserID_" +
        localStorage.getItem("UserID");
      let LoggedInUser = JSON.parse(localStorage.getItem("user"));
      var myCurrentDatetime = new Date();
      var _datetime = ConvertDate(myCurrentDatetime);
      var rasaconvobj = {
        evidenceType: "img",
        text: evidenceRes.documentUrl,
        timestamp: _datetime,
        type: "user",
      };
      var addindbobj = {
        assignedTo: LoggedInUser.UserID,
        rasaConversationID: ConversationId,
        uploadType: "file",
        evidence: rasaconvobj,
        conversation: null,
        userType: "operator",
        createdOn: _datetime,
      };
      addrasaconversationindb(addindbobj).then((resp) => {
        if (resp.status === "Ok") {
          SuccessToast("Snapshot added to chat.");
          let d = associateCam.map((item) => {
            let obj;
            if (item.alertId == alertDetail.alertId) {
              obj = {
                ...item,
                shouldRefresh: true,
              };
            } else {
              obj = {
                ...item,
              };
            }
            return obj;
          });
          setAssociateCam(d);
        } else {
          console.error(
            "Error while saving evidence to databse ",
            resp.message
          );
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const prePostHandler = () => {
    let DeviceId = item.associatedCameraId || item.deviceId;
    let DeviceBrandId = item.cameraBrandId;

    let split1 = actualTime.split(" ");
    let split2 = split1[0].split("-");

    let newdateSplit = `${split2[2]}-${split2[1]}-${split2[0]} ${split1[1]}`;
    let alerttime = new Date(newdateSplit);

    let pretime = 60;
    let posttime = 120; //1800 sec 30min
    alerttime.setSeconds(alerttime.getSeconds() - pretime);
    let styy = alerttime.getFullYear();
    let stmm = alerttime.getMonth() + 1;
    let stdd = alerttime.getDate();
    let sth = alerttime.getHours();
    let stm = alerttime.getMinutes();
    let sts = alerttime.getSeconds();

    alerttime.setSeconds(alerttime.getSeconds() + pretime + posttime);
    let etyy = alerttime.getFullYear();
    let etmm = alerttime.getMonth() + 1;
    let etdd = alerttime.getDate();
    let eth = alerttime.getHours();
    let etm = alerttime.getMinutes();
    let ets = alerttime.getSeconds();

    if (stmm.toString().length == 1) {
      stmm = "0" + stmm.toString();
    }
    if (stdd.toString().length == 1) {
      stdd = "0" + stdd.toString();
    }
    if (sth.toString().length == 1) {
      sth = "0" + sth.toString();
    }
    if (stm.toString().length == 1) {
      stm = "0" + stm.toString();
    }
    if (sts.toString().length == 1) {
      sts = "0" + sts.toString();
    }

    if (etmm.toString().length == 1) {
      etmm = "0" + etmm.toString();
    }
    if (etdd.toString().length == 1) {
      etdd = "0" + etdd.toString();
    }
    if (eth.toString().length == 1) {
      eth = "0" + eth.toString();
    }
    if (etm.toString().length == 1) {
      etm = "0" + etm.toString();
    }
    if (ets.toString().length == 1) {
      ets = "0" + ets.toString();
    }

    let startTime;
    let endTime;
    if (DeviceBrandId == 1) {
      startTime =
        styy + "_" + stmm + "_" + stdd + "_" + sth + "_" + stm + "_" + "00";
      endTime =
        etyy + "_" + etmm + "_" + etdd + "_" + eth + "_" + etm + "_" + "00";
    } else if (DeviceBrandId == 2) {
      startTime =
        styy + "_" + stmm + "_" + stdd + "_" + sth + "_" + stm + "_" + "00";
      endTime =
        etyy + "_" + etmm + "_" + etdd + "_" + eth + "_" + etm + "_" + "00";
    } else {
      startTime =
        styy + "_" + stmm + "_" + stdd + "_" + sth + "_" + stm + "_" + "00";
      endTime =
        etyy + "_" + etmm + "_" + etdd + "_" + eth + "_" + etm + "_" + "00";
    }
    setWebRTCExportStartTime(startTime);
    setWebRTCExportEndTime(endTime);
    let obj = {
      action: "StartPlaybackUrl",
      startTime: startTime,
      endTime: endTime,
      deviceId: DeviceId,
      alertId: alertDetail.alertId,
    };
    getstreamingurl(obj).then((res) => {
      if (res.status == "Ok") {
        setVideoId(id + "5");
        setVideoUrl(res.rtspURL);
        setLiveMode(false);
        setStartMode(false);
        setWebRTCExportCurrentVideoHide(false);
        if (CHAT_CAM_CHANGES) {
          let text = `${
            item.CameraName || item.cameraName || item.deviceName
          } -  PrePost Playback played.`;
          associateAttach(text);
        }
      }
    });
  };

  function TwoWayAudioClick(audio, chat, isCloseBtn) {
    var resp = "";
    var resp1 = "";
    let tempPrivateIpAddress = chat.privateIpAddress || chat.privateIPAddress;
    let tempCommunicationPort =
      chat.communicationPort || chat.CommunicationPort;
    let tempUserName = chat.camUserName || chat.camUserName;
    let tempPassword = chat.camPassword || chat.camPassword;
    let tempBrandName = chat.brandName || chat.BrandName;
    if (audio && audio == "start") {
      setIsSoundOn(false);
      resp1 = TwoWayCommunicationReq(0, 0, "stop", 0, 0, 0);
      resp = TwoWayCommunicationReq(
        tempPrivateIpAddress,
        tempCommunicationPort,
        "start",
        tempUserName,
        tempPassword,
        tempBrandName
      );
      if (CHAT_CAM_CHANGES) {
        associateAttach(
          `${
            item.CameraName || item.cameraName || item.deviceName
          } - Twoway Started`
        );
      } else {
        if (attachEvidence != undefined) {
          attachEvidence(
            `${
              item.CameraName || item.cameraName || item.deviceName
            } - Twoway Started`
          );
        }
      }
    }
    if (audio && audio == "stop") {
      setIsSoundOn(true);
      resp = TwoWayCommunicationReq(
        tempPrivateIpAddress,
        tempCommunicationPort,
        "stop",
        tempUserName,
        tempPassword,
        tempBrandName
      );
      if (CHAT_CAM_CHANGES) {
        associateAttach(
          `${
            item.CameraName || item.cameraName || item.deviceName
          } - Twoway Stopped`
        );
      } else {
        if (attachEvidence != undefined) {
          attachEvidence(
            `${
              item.CameraName || item.cameraName || item.deviceName
            } - Twoway Stopped`
          );
        }
      }
    }
  }

  const TwoWayCommunicationReq = async (
    privateIPAddress,
    CommunicationPort,
    action,
    user,
    password,
    BrandName
  ) => {
    try {
      if (privateIPAddress && CommunicationPort && user && password) {
        TwoWayCommunicationRequest(
          privateIPAddress,
          CommunicationPort,
          action,
          user,
          password,
          BrandName
        ).then((resp) => {
          return resp;
        });
      } else {
        TwoWayCommunicationRequest(
          privateIPAddress,
          CommunicationPort,
          action,
          user,
          password,
          BrandName
        ).then((resp) => {
          return resp;
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const reConnection = () => {
    webSocketServerConnect();
  };

  const reConnectionTwo = (id) => {
    webSocketServerConnectTwo(id);
  };

  const progressHandler = (e) => {
    if (e.playedSeconds != undefined) {
      let date = new Date(null);
      date.setSeconds(e.playedSeconds);
      let hhmmssFormat = date.toISOString().substring(11, 19);
      setRunningTime(hhmmssFormat);
      setTimer(e.playedSeconds);
    }
  };

  const handleSeekMouseDown = (e) => {
    // setSeeking(true);
  };

  const handleSeekMouseUp = (e) => {};

  const handleSeekChange = (e) => {
    // let temp = parseFloat(e.target.value);
    // clearTimeout(timerInt)
    // const newTimer = setTimeout(() => {
    //   setTimer(temp)
    //   // setTimer(parseFloat(e.target.value))
    //   // webSocketState.send("seek " + parseFloat(e.target.value))
    //   webSocketState.send("seek " + temp)
    // }, 500)
    // setTimerInt(newTimer)

    // setPlayed(parseFloat(e.target.value));
    let date = new Date(null);
    date.setSeconds(e.target.value);
    let hhmmssFormat = date.toISOString().substring(11, 19);
    setRunningTime(hhmmssFormat);
    setTimer(parseFloat(e.target.value));
    setPlayed(parseFloat(e.target.value));
  };

  function getCurrentDateTime() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");
    const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    return formattedDateTime;
  }

  const showSuccessToast = (msg) => {
    toast.success(msg, {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  const showErrorToast = (msg) => {
    toast.error(msg, {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  const alertCreationfromMosaic = (deviceId) => {
    setDisableAlertCreation(true);
    let userDetails = JSON.parse(localStorage.getItem("user"));
    let UserUniqueID = userDetails.UserUniqueID;
    let alertSeverity = "Moderate";
    let alertCodeId = 0;
    let alertType = "Manual Alert";
    let alertTime = getCurrentDateTime();
    let alertSource = "Web";
    let systemDeviceid = "";
    let aleralertPlaybackTimeTime = getCurrentDateTime();

    alertCreation(
      deviceId,
      UserUniqueID,
      alertSeverity,
      alertCodeId,
      alertType,
      alertTime,
      alertSource,
      systemDeviceid,
      aleralertPlaybackTimeTime
    )
      .then((resp) => {
        if (resp && resp.status && resp.status == "Ok") {
          showSuccessToast(
            `Alert Id - ${resp.Alert.AlertID} created successfully`
          );
          setDisableAlertCreation(false);
          return resp;
        } else {
          showErrorToast(resp.message);
          setDisableAlertCreation(false);
          return resp;
        }
      })
      .catch((err) => {
        setDisableAlertCreation(false);
      });
  };

  //#endregion functions end

  return (
    <>
      {isSelected ? (
        <div className="alertInprogressDiv">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <img src={AlertInProgressImage} height={120} />
            <div className="alertInprogressDetail">
              <p>In progress</p>
              <p className="mb-0">
                Alert ID: <span>{alertData.alertId}</span>
              </p>
            </div>
          </div>
        </div>
      ) : (
        <>
          {streamFound ? (
            <div
              ref={videoContainerParentRef}
              className={upperDivCss}
              style={{ position: "relative" }}
            >
              {loading ? (
                displayInfo ? (
                  <>
                    <div
                      style={{
                        height: "100%",
                        width: "100%",
                        backgroundColor: "#000000",
                        color: "white",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      // className={videoCss}
                      className="hoverDetails"
                    >
                      {/* <p>Loading Stream...</p> */}
                      <div class="loader">
                        <h6>Loading<span class="bullets">.</span></h6>
                      </div>
                      <div
                        className={buttonCss}
                        style={{ width: "100%", justifyContent: "flex-end" }}
                      >
                        {CLOSE_CHATFLOW_BTN ? (
                          <button
                            style={{ marginRight: "1rem" }}
                            className="SelectionAlertViewButton"
                            onClick={() => closedAlertBtn(alertData.alertId)}
                          >
                            <p>Close Alert</p>
                          </button>
                        ) : null}
                        <button
                          className="SelectionAlertViewButton"
                          onClick={() => chatFlowClicked(alertData.alertId)}
                        >
                          <p>Chatflow</p>
                        </button>
                      </div>
                    </div>
                    <>
                      <div className={alertCameraHeadingCss}>
                        <div>
                          <img src={AlertIDIcon} className="mb-1" />
                          <span className="AlertCameraDetailsText ml-1">
                            {alertData.alertId}
                          </span>
                        </div>
                        <div>
                          <img src={AlertSiteIcon} className="mb-1" />
                          <span className="AlertCameraDetailsText ml-1">
                            {alertData.siteName}
                          </span>
                        </div>
                        <div>
                          <img src={AlertTypeIcon} className="mb-1" />
                          <span className="AlertCameraDetailsText ml-1">
                            {alertData.alertType}
                          </span>
                        </div>
                        <div onClick={() => setInfoOpen(true)} className="infoBlueIcon">
                          <img src={InformationWhite} style={{ height: "12px"}} />
                        </div>
                      </div>
                      {infoOpen && (
                        <>
                          <div className={alertCameraDetailCss}>
                            {/* <p className="TitleGrid">ID: {alertData.alertId}</p> */}
                            <p
                              className="OperateBy MB2"
                              style={{ color: "#fff" }}
                            >
                              Device ID:
                              <span
                                className="OperateName ml-1"
                                style={{ color: "#fff" }}
                              >
                                {alertData.deviceId}
                              </span>
                            </p>
                            <p
                              className="OperateBy MB2"
                              style={{ color: "#fff" }}
                            >
                              IP Address:
                              <span
                                className="OperateName ml-1"
                                style={{ color: "#fff" }}
                              >
                                {alertData.privateIPAddress}
                              </span>
                            </p>
                            <p
                              className="OperateBy MB2"
                              style={{ color: "#fff" }}
                            >
                              Customer:
                              <span
                                className="OperateName ml-1"
                                style={{ color: "#fff" }}
                              >
                                {alertData.customerName}
                              </span>
                            </p>

                            <p
                              className="OperateBy ReportTableFontBold MB2"
                              style={{ color: "#fff" }}
                            >
                              Device:
                              <span
                                className="OperateName ml-1"
                                style={{ color: "#fff" }}
                              >
                                {alertData.deviceName}
                              </span>
                            </p>
                            <p
                              className="OperateBy ReportTableFontBold MB2"
                              style={{ color: "#fff" }}
                            >
                              Branch Id:
                              <span
                                className="OperateName ml-1"
                                style={{ color: "#fff" }}
                              >
                                {alertData.branchId}
                              </span>
                            </p>
                            <p
                              className="OperateBy MB2"
                              style={{ color: "#fff" }}
                            >
                              ACK By:
                              <span
                                className="OperateName ml-1"
                                style={{ color: "#fff" }}
                              >
                                {alertData.ackbyName != null
                                  ? alertData.ackbyName
                                  : "NA"}
                              </span>
                            </p>

                            <p
                              className="OperateBy ReportTableFontBold MB2"
                              style={{ color: "#fff" }}
                            >
                              Date & Time:
                              <span
                                className="OperateName ml-1"
                                style={{ color: "#fff" }}
                              >
                                {alertData.reqActualTime} (
                                {alertData.abbreviation})
                              </span>
                            </p>
                            <p
                              className="OperateBy MB5"
                              style={{ color: "#fff" }}
                            >
                              Status:
                              <span
                                className="OperateName ml-1"
                                style={{ color: "#fff" }}
                              >
                                {alertData.status}
                              </span>
                            </p>
                            <p
                              className="OperateBy MB5"
                              style={{ color: "#fff" }}
                            >
                              Address:
                              <span
                                className="OperateName ml-1"
                                style={{ color: "#fff" }}
                              >
                                {alertData.address}
                              </span>
                            </p>
                            <p className="OperateBy MB5" style={{ color: "#fff" }}>
                              Emergency Contact:
                              <span
                                className="OperateName ml-1"
                                style={{ color: "#fff" }}
                                >
                                  {alertData.emergencyContact}
                                  </span>
                            </p>
                          </div>
                          <div
                            style={{
                              position: "absolute",
                              right: "-2px",
                              top: "33px",
                            }}
                            onClick={() => setInfoOpen(false)}
                          >
                            <img src={CloseIcon} />
                          </div>
                        </>
                      )}
                    </>
                  </>
                ) : (
                  <div
                    style={{
                      height: "100%",
                      width: "100%",
                      backgroundColor: "#000000",
                      color: "white",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    className={videoCss}
                  >
                    {/* <p>Loading Video...</p> */}
                    <div class="loader">
                        <h6>Loading<span class="bullets">.</span></h6>
                      </div>
                  </div>
                )
              ) : (
                <>
                  <div className="hoverDetails" style={{ height: "100%", width: "100%", display: "flex" }}>
                    <video
                      id={videoId}
                      className= {stretchVideo ? "NonStretchVideo StretchVideo" : isFullscreenClick ? fullVideoCss :  `NonStretchVideo ${videoCss}`}
                      // style={{
                      //   width: "100%",
                      //   backgroundColor: "#000000",
                      //   height: "100%",
                      //   // aspectRatio: "unset"
                      //   // margin: "0px 1px 0px 1px",
                      //   // position: "relative",
                      // }}
                      // className={isFullscreenClick ? fullVideoCss : videoCss}
                      ref={videoContainerRef}
                      autoPlay={true}
                      onProgress={(e) => progressHandler(e)}
                    ></video>

                    {playback ? (
                      <div class="overlayText">
                        {liveMode ? (
                          <p id="topText">Live</p>
                        ) : (
                          <p id="topTextRed">Rec</p>
                        )}
                      </div>
                    ) : null}

                    {displayInfo ? (
                      <div
                        className={buttonCss}
                        style={{ width: "100%", justifyContent: "flex-end" }}
                      >
                        {CLOSE_CHATFLOW_BTN ? (
                          <button
                            style={{ marginRight: "1rem" }}
                            className="SelectionAlertViewButton"
                            onClick={() => closedAlertBtn(alertData.alertId)}
                          >
                            <p>Close Alert</p>
                          </button>
                        ) : null}
                        <button
                          className="SelectionAlertViewButton"
                          onClick={() => chatFlowClicked(alertData.alertId)}
                        >
                          <p>Chatflow</p>
                        </button>
                      </div>
                    ) : (
                      <div className={buttonCss} style={{ width: "100%" }}>
                        {playback || showName ? (
                          <div
                            className={` ${
                              false == "parent5x5"
                                ? "parent5x5CameraName"
                                : "MosaicCameraName"
                            }`}
                          >
                            <p>
                              {item.CameraName ||
                                item.cameraName ||
                                item.deviceName}
                            </p>
                          </div>
                        ) : null}

                        {!isPtzClick ? (
                          <div className="mosaicbuttons">
                            {(prePostDisplay || liveButton) &&
                            (!liveMode || playbackMode) ? (
                              <button
                                data-title="Go Back To Live"
                                className="MosaicButtonDiv"
                              >
                                <img
                                  style={{ padding: "2px", height: "24px" }}
                                  src={BackToLive}
                                  alt="BacktoLive"
                                  onClick={goBackToLive}
                                />
                              </button>
                            ) : null}
                            {isSinglePlayback ? (
                              <div style={{ display: "flex", flex: 1 }}>
                                {!isPaused ? (
                                  <button
                                    data-title="Pause"
                                    className="MosaicButtonDiv"
                                    onClick={pauseVideo}
                                  >
                                    <img
                                      style={{ padding: "2px" }}
                                      src={Pause}
                                      alt="Pause"
                                    />
                                  </button>
                                ) : (
                                  <button
                                    data-title="Play"
                                    className="MosaicButtonDiv"
                                    onClick={playVideo}
                                  >
                                    <img
                                      style={{ padding: "2px" }}
                                      src={Play}
                                      alt="Play"
                                    />
                                  </button>
                                )}

                                {item.BrandName != "Hikvision" &&
                                item.BrandName != "Raysharp" &&
                                item?.BrandName != "Hikvision-Hybrid" ? (
                                  <button
                                    data-title="Restart"
                                    className="MosaicButtonDiv"
                                    onClick={restartVideo}
                                  >
                                    <img
                                      style={{
                                        padding: "2px",
                                        marginLeft: "0px",
                                      }}
                                      src={Rewind}
                                      alt="Rewind"
                                    />
                                  </button>
                                ) : null}

                                <div
                                  style={{
                                    display: "flex",
                                    flex: 1,
                                    flexDirection: "column",
                                    margin: "0px 10px",
                                  }}
                                >
                                  {item.BrandName != "Hikvision" &&
                                  item.BrandName != "Raysharp" &&
                                  item?.BrandName != "Hikvision-Hybrid" ? (
                                    <div>
                                      <div className="controls_seconds">
                                        {true ? (
                                          <span>
                                            {runningTime} / {totalTime}
                                          </span>
                                        ) : (
                                          <span></span>
                                        )}
                                      </div>
                                      <input
                                        type="range"
                                        className="controls_seekbar"
                                        min={0}
                                        max={secondsDiff}
                                        value={timer}
                                        onMouseDown={handleSeekMouseDown}
                                        onChange={handleSeekChange}
                                        onMouseUp={handleSeekMouseUp}
                                      />
                                      <span id="sliderTitle"></span>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            ) : null}

                            {prePostDisplay && (liveMode || playbackMode) ? (
                              <button
                                data-title="Pre-Post Playback"
                                className="MosaicButtonDiv"
                              >
                                <img
                                  style={{ padding: "2px", height: "24px" }}
                                  src={PrePost}
                                  alt="PrePost"
                                  onClick={prePostHandler}
                                />
                              </button>
                            ) : null}
                            {item?.IsPTZ == "1" ? (
                              // true ?
                              <button
                                data-title="PTZ"
                                className="MosaicButtonDiv"
                                onClick={() => setIsPtzClick(true)}
                              >
                                <img
                                  style={{ padding: "2px", marginLeft: "0px" }}
                                  src={PtzIcon}
                                  alt="PTZIcon"
                                />
                              </button>
                            ) : null}
                            {showPlaybackBtn ? (
                              <button
                                data-title="Playback"
                                className="MosaicButtonDiv"
                                onClick={(e) => {
                                  setPopOpen(true);
                                  let tempDate;
                                  if (MULTI_TIME_ZONE) {
                                    let offsetMinutes =
                                      item.OffsetMinutes || 330;
                                    tempDate =
                                      multiTimeZoneHelper(offsetMinutes);
                                  } else {
                                    tempDate = new Date();
                                  }
                                  let d = subMinutes(tempDate, 15);
                                  let temp = ConvertDate(d);
                                  let temp_date = temp
                                    .slice(0, 10)
                                    .replace(/-/g, "_");
                                  setDateString(temp_date);
                                  setDate(subMinutes(tempDate, 15));
                                  if (isFullscreenClick) {
                                    handleFullScreen();
                                  }
                                  setMode("Playback");
                                }}
                              >
                                <img
                                  src={PlayBack}
                                  className="camera-icon-height"
                                  alt="playback white icon"
                                  style={{ padding: "2px", height: "24px" }}
                                />
                              </button>
                            ) : null}

                            {showPrePostReplay &&
                            item?.brandName != "Hikvision" &&
                            item?.brandName != "Raysharp" &&
                            item?.brandName != "Hikvision-Hybrid" ? (
                              <button
                                data-title="Restart"
                                className="MosaicButtonDiv"
                                onClick={restartPrePost}
                              >
                                <img
                                  style={{ padding: "2px", marginLeft: "0px" }}
                                  src={Rewind}
                                  alt="Rewing"
                                />
                              </button>
                            ) : null}

                            <button
                              data-title="Snapshot"
                              className="MosaicButtonDiv"
                            >
                              <img
                                style={{ padding: "2px", height: "24px" }}
                                src={MosaicSnapshot}
                                alt="Snapshot"
                                onClick={() => setSnapModal(true)}
                              />
                            </button>
                            {showHooterIcon && liveMode ? (
                              item.ShowTwoWay && !hideTwoWay ? (
                                isSoundOn ? (
                                  <button
                                    data-title="Twoway Start"
                                    className="MosaicButtonDiv"
                                  >
                                    <img
                                      style={{ padding: "2px" }}
                                      src={MosaicTwowayStart}
                                      alt="TwowayStart"
                                      onClick={() =>
                                        TwoWayAudioClick("start", item)
                                      }
                                    />
                                  </button>
                                ) : (
                                  <button
                                    data-title="Twoway Stop"
                                    className="MosaicButtonDiv"
                                  >
                                    <img
                                      style={{ padding: "2px" }}
                                      src={MosaicTwowayStop}
                                      alt="TwowayStop"
                                      onClick={() =>
                                        TwoWayAudioClick("stop", item)
                                      }
                                    />
                                  </button>
                                )
                              ) : null
                            ) : null}
                            {showHooterIcon && liveMode ? (
                              item.ShowHooter ? (
                                !isAllinOneHooterOn ? (
                                  <button
                                    data-title="Hooter On"
                                    disabled={disableHooterBtn}
                                    className="MosaicButtonDiv"
                                  >
                                    <img
                                      style={{ padding: "2px" }}
                                      src={MosaicHooterOn}
                                      alt="HooterOn"
                                      onClick={(e) => {
                                        if (e.detail != 1) {
                                          return;
                                        }
                                        HooterOnOffClickRequest(
                                          item.ParentCameraID ||
                                            item.associatedCameraId ||
                                            item.deviceId,
                                          "on"
                                        );
                                      }}
                                    />
                                  </button>
                                ) : (
                                  <button
                                    data-title="Hooter Off"
                                    disabled={disableHooterBtn}
                                    className="MosaicButtonDivoff"
                                  >
                                    <img
                                      style={{ padding: "2px" }}
                                      src={MosaicHooterOff}
                                      alt="HooterOff"
                                      onClick={(e) => {
                                        if (e.detail != 1) {
                                          return;
                                        }
                                        HooterOnOffClickRequest(
                                          item.ParentCameraID ||
                                            item.associatedCameraId ||
                                            item.deviceId,
                                          "off"
                                        );
                                      }}
                                    />
                                  </button>
                                )
                              ) : null
                            ) : null}

                            <button
                              data-title={
                                isFullscreenClick
                                  ? "Exit Full screen"
                                  : "Full screen"
                              }
                              className="MosaicButtonDiv"
                              onClick={handleFullScreen}
                            >
                              {!isFullscreenClick ? (
                                <img
                                  style={{ padding: "2px" }}
                                  src={MosaicFullscreen}
                                  alt="Full"
                                />
                              ) : (
                                <img
                                  style={{ padding: "2px" }}
                                  src={MosaicExitFullscreen}
                                  alt="Exit"
                                />
                              )}
                            </button>

                            {!HIDE_STUFF ? (
                              <button
                                data-title="Video Export"
                                className="MosaicButtonDiv"
                              >
                                <img
                                  style={{ padding: "2px", height: "24px" }}
                                  src={Video_Export}
                                  alt="video Export Button"
                                  onClick={() => {
                                    setPopOpen(true);
                                    let d;
                                    if (MULTI_TIME_ZONE) {
                                      let offsetMinutes =
                                        item.OffsetMinutes || 330;
                                      d = multiTimeZoneHelper(offsetMinutes);
                                    } else {
                                      d = new Date();
                                    }
                                    setDate(subMinutes(d, 5));
                                    setdateTwo(d);
                                    setTimeString("");
                                    setTimeStringTwo("");
                                    if (isFullscreenClick) {
                                      handleFullScreen();
                                    }
                                    setMode("Export");
                                  }}
                                />
                              </button>
                            ) : null}
                            {showAlertCreation && liveMode ? (
                              <button
                                data-title="Alert creation"
                                className="MosaicButtonDiv"
                                onClick={(e) =>
                                  alertCreationfromMosaic(item.ParentCameraID)
                                }
                                disabled={disableAlertCreation}
                              >
                                <img
                                  style={{ padding: "2px" }}
                                  src={AlertOption1}
                                  alt="Alert Creation Button"
                                />
                              </button>
                            ) : null}
                          </div>
                        ) : (
                          <div
                            className="mosaicbuttons"
                            style={{ justifyContent: "space-between" }}
                          >
                            <button
                              data-title="Back Arrow"
                              className="MosaicButtonDiv"
                              style={{ borderRadius: "unset" }}
                              onClick={() => setIsPtzClick(false)}
                            >
                              <img
                                style={{ padding: "2px", marginLeft: "0px" }}
                                src={BackArrowDevice}
                                alt="BackArrow"
                              />
                            </button>

                            {/* <button data-title='Home' className='MosaicButtonDiv'>
                                <img style={{ padding: "2px", marginLeft: "0px" }}
                                  src={PtzHomeIcon}
                                  alt="HomeIcon"
                                />
                          </button> */}

                            <button
                              data-title="Left"
                              className="MosaicButtonDiv"
                              onClick={Left}
                            >
                              <img
                                style={{ padding: "2px", marginLeft: "0px" }}
                                src={PtzLeftArrow}
                                alt="LeftArrow"
                              />
                            </button>
                            <button
                              data-title="Right"
                              className="MosaicButtonDiv"
                              onClick={Right}
                            >
                              <img
                                style={{ padding: "2px", marginLeft: "0px" }}
                                src={PtzRightArrow}
                                alt="RightArrow"
                              />
                            </button>
                            <button
                              data-title="Up"
                              className="MosaicButtonDiv"
                              onClick={Up}
                            >
                              <img
                                // style={{ padding: "2px", marginLeft: "0px" }}
                                src={PtzUpArrow}
                                alt="TopArrow"
                                width={18}
                              />
                            </button>
                            <button
                              data-title="Down"
                              className="MosaicButtonDiv"
                              onClick={Down}
                            >
                              <img
                                // style={{ padding: "2px", marginLeft: "0px" }}
                                width={18}
                                src={PtzDownArrow}
                                alt="BottomArrow"
                              />
                            </button>

                            <button
                              data-title="Zoom in"
                              className="MosaicButtonDiv"
                              onClick={ZoomIn}
                            >
                              <img
                                style={{ padding: "2px", marginLeft: "0px" }}
                                src={PtzZoomInIcon}
                                alt="PlusIcon"
                              />
                            </button>
                            <button
                              data-title="Zoom out"
                              className="MosaicButtonDiv"
                              onClick={ZoomOut}
                            >
                              <img
                                style={{ padding: "2px", marginLeft: "0px" }}
                                src={PtzZoomOutIcon}
                                alt="MinusIcon"
                                width={21}
                              />
                            </button>
                          </div>
                        )}
                        <div>
                          <Modal
                            show={downloadingLocal}
                            backdrop="static"
                            className="loaderSpinner"
                          >
                            <Spinner
                              style={{ marginLeft: "6rem" }}
                              animation="border"
                              variant="primary"
                            />
                            <span style={{ padding: "1rem" }}>
                              Downloading file... Please wait
                            </span>
                          </Modal>
                          <Modal
                            show={popOpen}
                            onHide={() => setPopOpen(false)}
                            className={
                              webRTCExportCurrentVideoHide || mode == "Playback"
                                ? "singleplaybackexportModal"
                                : "ExportModal"
                            }
                            backdrop="static"
                          >
                            <Modal.Header closeButton>
                              {mode == "Playback" ? (
                                <Modal.Title>
                                  <p className="ModelHeaderName">
                                    {item.CameraName || item.cameraName}{" "}
                                    Playback
                                  </p>
                                </Modal.Title>
                              ) : (
                                <Modal.Title>
                                  <p className="ModelHeaderName">
                                    {item.CameraName || item.cameraName} Export
                                  </p>
                                </Modal.Title>
                              )}
                            </Modal.Header>
                            <Modal.Body>
                              <PopupCard
                                item={item}
                                dateChange={dateChange}
                                date={date}
                                getDataFromChild={getDataFromChild}
                                onSubmitBtn={
                                  mode == "Playback" ? onSubmitBtn : onExportBtn
                                }
                                setBlankDate={setBlankDate}
                                timeStringMain={timeStringMain}
                                mode={mode}
                                setBlankDateTwo={setBlankDateTwo}
                                getDataFromChildTwo={getDataFromChildTwo}
                                timeStringMainTwo={timeStringMainTwo}
                                dateTwo={dateTwo}
                                exportCurrentVideoHide={
                                  webRTCExportCurrentVideoHide
                                }
                                videoExportDownload={downloadDirectPlayback}
                              />
                            </Modal.Body>
                          </Modal>

                          <Modal
                            show={snapModal}
                            onHide={() => setSnapModal(false)}
                            size="lg"
                            style={{ zIndex: "99999" }}
                            backdrop="static"
                          >
                            <Modal.Header closeButton>
                              <p className="ModelHeaderName">
                                {item.cameraName} Snapshot
                              </p>
                            </Modal.Header>

                            <Modal.Body>
                              <div id="output"></div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  marginTop: "0.5rem",
                                }}
                              >
                                <button
                                  className="downloadbuttonNewUI"
                                  onClick={downloadImage}
                                >
                                  Download Image
                                </button>
                                {showDirectToChat ? (
                                  <button
                                    className="downloadbuttonNewUI"
                                    disabled={buttonDisable}
                                    onClick={(e) => generateSnapInChat(e)}
                                  >
                                    Add to Chat
                                  </button>
                                ) : null}
                              </div>
                            </Modal.Body>
                          </Modal>
                        </div>
                      </div>
                    )}
                  </div>
                  {displayInfo ? (
                    <>
                      <div className={alertCameraHeadingCss}>
                        <div>
                          <img src={AlertIDIcon} className="mb-1" />
                          <span className="AlertCameraDetailsText ml-1">
                            {alertData.alertId}
                          </span>
                        </div>
                        <div>
                          <img src={AlertSiteIcon} className="mb-1" />
                          <span className="AlertCameraDetailsText ml-1">
                            {alertData.siteName}
                          </span>
                        </div>
                        <div>
                          <img src={AlertTypeIcon} className="mb-1" />
                          <span className="AlertCameraDetailsText ml-1">
                            {alertData.alertType}
                          </span>
                        </div>
                        <div onClick={() => setInfoOpen(true)} className="infoBlueIcon">
                          <img src={InformationWhite} style={{ height: "12px"}} />
                        </div>
                      </div>
                      {infoOpen && (
                        <>
                          <div className={alertCameraDetailCss}>
                            {/* <p className="TitleGrid">ID: {alertData.alertId}</p> */}
                            <p
                              className="OperateBy MB2"
                              style={{ color: "#fff" }}
                            >
                              Device ID:
                              <span
                                className="OperateName ml-1"
                                style={{ color: "#fff" }}
                              >
                                {alertData.deviceId}
                                
                              </span>
                            </p>
                            <p
                              className="OperateBy MB2"
                              style={{ color: "#fff" }}
                            >
                              IP Address:
                              <span
                                className="OperateName ml-1"
                                style={{ color: "#fff" }}
                              >
                                {alertData.privateIPAddress}
                              </span>
                            </p>
                            <p
                              className="OperateBy MB2"
                              style={{ color: "#fff" }}
                            >
                              Customer:
                              <span
                                className="OperateName ml-1"
                                style={{ color: "#fff" }}
                              >
                                {alertData.customerName}
                              </span>
                            </p>

                            <p
                              className="OperateBy ReportTableFontBold MB2"
                              style={{ color: "#fff" }}
                            >
                              Device:
                              <span
                                className="OperateName ml-1"
                                style={{ color: "#fff" }}
                              >
                                {alertData.deviceName}
                              </span>
                            </p>
                            <p
                              className="OperateBy ReportTableFontBold MB2"
                              style={{ color: "#fff" }}
                            >
                              Branch Id:
                              <span
                                className="OperateName ml-1"
                                style={{ color: "#fff" }}
                              >
                                {alertData.branchId}
                              </span>
                            </p>
                            <p
                              className="OperateBy MB2"
                              style={{ color: "#fff" }}
                            >
                              ACK By:
                              <span
                                className="OperateName ml-1"
                                style={{ color: "#fff" }}
                              >
                                {alertData.ackbyName != null
                                  ? alertData.ackbyName
                                  : "NA"}
                              </span>
                            </p>

                            <p
                              className="OperateBy ReportTableFontBold MB2"
                              style={{ color: "#fff" }}
                            >
                              Date & Time:
                              <span
                                className="OperateName ml-1"
                                style={{ color: "#fff" }}
                              >
                                {alertData.reqActualTime} (
                                {alertData.abbreviation})
                              </span>
                            </p>
                            <p
                              className="OperateBy MB5"
                              style={{ color: "#fff" }}
                            >
                              Status:
                              <span
                                className="OperateName ml-1"
                                style={{ color: "#fff" }}
                              >
                                {alertData.status}
                              </span>
                            </p>
                            <p
                              className="OperateBy MB5"
                              style={{ color: "#fff" }}
                            >
                              Address:
                              <span
                                className="OperateName ml-1"
                                style={{ color: "#fff" }}
                              >
                                {alertData.address}
                              </span>
                            </p>
                            <p className="OperateBy MB5" style={{ color: "#fff" }}>
                              Emergency Contact:
                              <span
                                className="OperateName ml-1"
                                style={{ color: "#fff" }}
                                >
                                  {alertData.emergencyContact}
                                  </span>
                            </p>                            
                          </div>
                          <div
                            style={{
                              position: "absolute",
                              right: "-2px",
                              top: "33px",
                            }}
                            onClick={() => setInfoOpen(false)}
                          >
                            <img src={CloseIcon} />
                          </div>
                        </>
                      )}
                    </>
                  ) : null}
                </>
              )}
            </div>
          ) : displayInfo ? (
            <>
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  backgroundColor: "#000000",
                  color: "green",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                // className={videoCss}
                className="hoverDetails"
              >
                <p>Loading Stream...</p>
                <div
                  className={buttonCss}
                  style={{ width: "100%", justifyContent: "flex-end" }}
                >
                  {CLOSE_CHATFLOW_BTN ? (
                    <button
                      style={{ marginRight: "1rem" }}
                      className="SelectionAlertViewButton"
                      onClick={() => closedAlertBtn(alertData.alertId)}
                    >
                      <p>Close Alert</p>
                    </button>
                  ) : null}

                  <button
                    className="SelectionAlertViewButton"
                    onClick={() => chatFlowClicked(alertData.alertId)}
                  >
                    <p>Chatflow</p>
                  </button>
                </div>
              </div>
              <div className={alertCameraHeadingCss}>
                <div>
                  <img src={AlertIDIcon} className="mb-1" />
                  <span className="AlertCameraDetailsText ml-1">
                    {alertData.alertId}
                  </span>
                </div>
                <div>
                  <img src={AlertSiteIcon} className="mb-1" />
                  <span className="AlertCameraDetailsText ml-1">
                    {alertData.siteName}
                  </span>
                </div>
                <div>
                  <img src={AlertTypeIcon} className="mb-1" />
                  <span className="AlertCameraDetailsText ml-1">
                    {alertData.alertType}
                  </span>
                </div>
                <div onClick={() => setInfoOpen(true)}>
                  <img src={InfoBlue} />{" "}
                </div>
              </div>
              {infoOpen && (
                <>
                  <div className={alertCameraDetailCss}>
                    {/* <p className="TitleGrid">ID: {alertData.alertId}</p> */}
                    <p className="OperateBy MB2" style={{ color: "#fff" }}>
                      Device ID:
                      <span
                        className="OperateName ml-1"
                        style={{ color: "#fff" }}
                      >
                        {alertData.deviceId}
                      </span>
                    </p>
                    <p className="OperateBy MB2"
                      style={{ color: "#fff" }}
                            >
                              IP Address:
                              <span
                                className="OperateName ml-1"
                                style={{ color: "#fff" }}
                              >
                                {alertData.privateIPAddress}
                              </span>
                            </p>
                    <p className="OperateBy MB2" style={{ color: "#fff" }}>
                      Customer:
                      <span
                        className="OperateName ml-1"
                        style={{ color: "#fff" }}
                      >
                        {alertData.customerName}
                      </span>
                    </p>

                    <p
                      className="OperateBy ReportTableFontBold MB2"
                      style={{ color: "#fff" }}
                    >
                      Device:
                      <span
                        className="OperateName ml-1"
                        style={{ color: "#fff" }}
                      >
                        {alertData.deviceName}
                      </span>
                    </p>
                    <p
                      className="OperateBy ReportTableFontBold MB2"
                      style={{ color: "#fff" }}
                    >
                      Branch Id:
                      <span
                        className="OperateName ml-1"
                        style={{ color: "#fff" }}
                      >
                        {alertData.branchId}
                      </span>
                    </p>
                    <p className="OperateBy MB2" style={{ color: "#fff" }}>
                      ACK By:
                      <span
                        className="OperateName ml-1"
                        style={{ color: "#fff" }}
                      >
                        {alertData.ackbyName != null
                          ? alertData.ackbyName
                          : "NA"}
                      </span>
                    </p>

                    <p
                      className="OperateBy ReportTableFontBold MB2"
                      style={{ color: "#fff" }}
                    >
                      Date & Time:
                      <span
                        className="OperateName ml-1"
                        style={{ color: "#fff" }}
                      >
                        {alertData.reqActualTime} ({alertData.abbreviation})
                      </span>
                    </p>
                    <p className="OperateBy MB5" style={{ color: "#fff" }}>
                      Status:
                      <span
                        className="OperateName ml-1"
                        style={{ color: "#fff" }}
                      >
                        {alertData.status}
                      </span>
                    </p>
                    <p className="OperateBy MB5" style={{ color: "#fff" }}>
                      Address:
                      <span
                        className="OperateName ml-1"
                        style={{ color: "#fff" }}
                        >
                          {alertData.address}
                          </span>
                    </p>
                    <p className="OperateBy MB5" style={{ color: "#fff" }}>
                      Emergency Contact:
                      <span
                        className="OperateName ml-1"
                        style={{ color: "#fff" }}
                        >
                          {alertData.emergencyContact}
                          </span>
                    </p>
                  </div>
                  <div
                    style={{ position: "absolute", right: "-2px", top: "33px" }}
                    onClick={() => setInfoOpen(false)}
                  >
                    <img src={CloseIcon} />
                  </div>
                </>
              )}
            </>
          ) : (
            <div className={noStreamDivCss}>
              <img
                src={MosaicNoStream}
                alt="Video"
                className="w-100 image-bg mosaic-nostream-img"
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default WebRTCPlayer;
