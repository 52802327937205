import React from 'react'
import { Line } from 'react-chartjs-2'
import { FilterClearIcon } from '../../../assets/images';

const LineChart = () => {

  const data = {
    labels: ["Mon","Tues", "Wed", "Thurs", "Fri", "Sat", "Sun"],  
    datasets: [{
      label: 'My First Dataset',
      data: [65, 59, 80, 81, 56, 55, 40],
      fill: false,
      borderColor: 'rgb(75, 192, 192)',
      tension: 0.1
    }]
  };

  const config = {
    type: 'line',
    data: data,
  };

  return (
    <div style={{height:"100%"}}>
        <div className='ChartHeader'>
            <p>Line Chart</p>
            <button style={{background:"transparent"}}>
            <img src={FilterClearIcon} alt="Clear" />
            </button>
        </div>
        <div style={{padding:"10px", height:"90%"}}>
            <Line config={config} data={data}/>
        </div>
    </div>
  )
}

export default LineChart