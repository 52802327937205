import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import AlertTemplateMessage from "../Configuration/AlertTemplate/AlertTemplateMessage";
import {
  calendar,
  CancelSyncReply,
  ClearFilter,
  DateTimeArrow,
  DeleteIcon,
  FilterIcon,
  InfoIcon1,
  RemoveIcon,
  SaveFilter,
  SearchIcon,
  SearchIconWhite,
  SyncReply,
  ViewMosaic,
  WarningDelete,
  } from "../../assets/images";
import WebRTCPlayer from "../Cameras/WebRTCPlayer";
import SkelSinglePlayback from "../Cameras/SkelSinglePlayback";
import MultiplaybackView from "./MultiplaybackView";
import {
  addMinutesToDate,
  ConvertDate,
  formatJSDateInDDMMYYYY,
  formatJSDateObject,
  frontDateConverter,
  parseSCSEntitiesToObect,
  stringifyObjToSCSEntities,
} from "../../utils/utils";
import FilterToolDDL from "../../components/Filter/FilterToolDDL";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import {
  // deleteSavedFilter,
  fetchCameraDetailsById,
  fetchCameraDetailsByIdWD,
  // fetchClientList,
  fetchDevicesList,
  // fetchInterfaceName,
  // fetchInterfaceSubTypeList,
  // fetchSavedFiltersList,
  // fetchSiteList,
  saveFilter,
} from "../../services/camera/cameraService";
import { cameraActions } from "../../redux/features/camera/camera";
import CameraSidePanelList from "./CameraSidePanelList";
import { MOSAIC } from "../../navigation/CONSTANTS";
import { ErrorToast } from "../../CustomHooks/ErrorToast";
import { getstreamingurl } from "../../services/chatFlowService";
import { getInfoList } from "../../services/alertGridServices";
import Playback from "../../components/Playback/Playback";
import { playbackActions } from "../../redux/features/shared/playback";
import {
  deleteSavedFilter,
  fetchClientList,
  fetchInterfaceList,
  fetchInterfaceSubTypeList,
  fetchSavedFiltersList,
  fetchSiteList,
} from "../../services/filterService";
import { filterActions } from "../../redux/features/shared/filter";
import { buttonActions } from "../../redux/features/shared/button";
import { modalActions } from "../../redux/features/shared/modal";

const CLEARSELECTION = "Clear Selection";
const SELECTALL = "Select ALL";
const CAMERA = "cameras";

const CUSTOMER = "Customer";
const SITE = "Site";
const INTERFACESUBTYPE = "Interface Sub Type";
const INTERFACE = "Interface Name";
const SAVEDFILTER = "Saved Filter";

export const CameraFilterPage = ({
  childWindow,
  setChildWindow,
  isDarkTheme,
}) => {
  //#region redux state and dispatcher function
  const dispatch = useDispatch();

  const filter = useSelector((state) => state?.filter);
  const {
    savedFilters,
    customer,
    site,
    interfaceSubType,
    interface: deviceInterface,
    filterName,
  } = filter;

  const infoState = useSelector((state) => state?.camera?.camera?.infoState);
  const { customerInfo, siteInfo, interfaceSubTypeInfo, interfaceNameInfo } =
    infoState;

  const buttonVisibilityState = useSelector(
    (state) => state?.button?.buttonVisibilityState
  );
  const {
    visibleFilterBtn,
    visibleClearFilterBtn,
    visibleSaveFilterBtn,
    visibleSyncReplayBtn,
    visibleClearSyncReplayBtn,
    visibleViewMosaicBtn,
  } = buttonVisibilityState;

  const devicesList = useSelector(
    (state) => state?.camera?.camera?.devicesList
  );
  const cameraDetails = useSelector(
    (state) => state?.camera?.camera?.cameraDetails
  );
  const deviceListSearchText = useSelector(
    (state) => state?.camera?.camera?.deviceListSearchText
  );
  const modalState = useSelector((state) => state?.camera?.camera?.modalState);
  const { showInfoFilterModal } = modalState;

  const modalVisibilityState = useSelector(
    (state) => state?.modal?.modalVisibilityState
  );
  const { showSavedFilterSaveModal, showSavedFilterDeleteModal } =
    modalVisibilityState;

  // const filterName = useSelector((state) => state?.camera?.camera?.filterName);
  const filterInfoName = useSelector(
    (state) => state?.camera?.camera?.filterInfoName
  );
  const cameraPlaybackList = useSelector(
    (state) => state?.camera?.camera?.cameraPlaybackList
  );
  const visibleMultiPlaybackView = useSelector(
    (state) => state?.camera?.camera?.visibleMultiPlaybackView
  );

  const playbackComponentStates = useSelector((state) => state?.playback);
  const {
    startDate,
    endDate,
    selectedStartDate,
    selectedEndDate,
    defaultEndDate,
  } = playbackComponentStates;
  //#endregion

  console.count("CameraFilterPage component render count: ");

  // useState start
  const [selected, setSelected] = useState([]);

  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [popOpen, setPopOpen] = useState(false);

  const [startDateString, setStartDateString] = useState("");
  const [endDateString, setEndDateString] = useState("");

  const [isCheckBoxVisible, setIsCheckBoxVisible] = useState(false);
  const [isMultiPlay, setIsMultiPlay] = useState(false);

  const [displayModalDelete, setDisplayModalDelete] = useState(false);
  const [startMode, setStartMode] = useState(true);
  const [blankDate, setBlankDate] = useState("");

  // const [containerWidth, setContainerWidth] = useState(0);

  // useState end

  //#region useEffect code starts
  //Reset all states when component unmounts
  useEffect(() => {
    return () => {
      dispatch(filterActions.resetFilterStates());
      dispatch(buttonActions.resetAllButtons());
      dispatch(cameraActions.resetCamerStates());
    };
  }, []);

  useEffect(() => {
    dispatch(fetchSavedFiltersList(CAMERA));
    dispatch(fetchClientList());
  }, []);

  useEffect(() => {
    if (isNotNullOrEmpty(devicesList) && visibleClearSyncReplayBtn) {
      dispatch(buttonActions.disableSyncReplayBtn(false));
    }
  }, [devicesList]);

  //Disable sync replay and clear sync replay buttons
  useEffect(() => {
    if (
      !isNotNullOrEmpty(devicesList) &&
      !isNotNullOrEmpty(savedFilters?.selected) &&
      isNotNullOrEmpty(customer?.selected)
    ) {
      dispatch(buttonActions.disableSyncReplayBtn(true));
      dispatch(buttonActions.disableClearSyncReplayBtn(true));
    }
  }, [devicesList]);

  useEffect(() => {
    //Enabale or disable view mosaic button
    if (isViewMosaicBtnEnabled()) {
      dispatch(buttonActions.disableViewMosaicBtn(false));
    } else {
      dispatch(buttonActions.disableViewMosaicBtn(true));
    }

    //Enable or disable save filter button
    if (isSaveFilterBtnEnabled()) {
      dispatch(buttonActions.disableSaveFilterBtn(false));
    } else {
      dispatch(buttonActions.disableSaveFilterBtn(true));
    }
  }, [savedFilters?.selected, customer?.selected, devicesList]);

  useEffect(() => {
    //true is disabled, false is enabled
    //Reset all filter buttons
    if (
      !isNotNullOrEmpty(savedFilters?.selected) &&
      !isNotNullOrEmpty(customer?.selected)
    ) {
      dispatch(buttonActions.resetAllButtons());
    }

    //Enable or disable synce replay mode
    if (isNotNullOrEmpty(devicesList)) {
      dispatch(buttonActions.disableSyncReplayBtn(false));
    } else {
      dispatch(buttonActions.disableSyncReplayBtn(true));
    }

    //Enable or disable filter and clear filter buttons
    if (shouldFilterAndClearFilterBtnsBeEnabled()) {
      dispatch(buttonActions.disableFilterBtn(false));
      dispatch(buttonActions.disableClearFilterBtn(false));
    } else {
      dispatch(buttonActions.disableFilterBtn(true));
      dispatch(buttonActions.disableClearFilterBtn(true));
    }

    //Fetch saved filters list
    if (!isNotNullOrEmpty(savedFilters?.options)) {
      dispatch(fetchSavedFiltersList(CAMERA));
    }

    //Fetch customer DDL data
    if (!isNotNullOrEmpty(customer?.options)) {
      dispatch(fetchClientList());
    }

    //Fetch site DDL data
    if (isNotNullOrEmpty(customer?.selected)) {
      let ids = getCSIds(customer?.selected);
      dispatch(fetchSiteList(ids ?? ""));
    }
    if (!isNotNullOrEmpty(customer?.selected)) {
      dispatch(filterActions.resetSite());
      dispatch(filterActions.resetInterfaceSubType());
      dispatch(filterActions.resetInterface());
      dispatch(cameraActions.resetDevicesList());
    }

    if (!isNotNullOrEmpty(site?.selected)) {
      dispatch(filterActions.resetInterfaceSubType());
      dispatch(filterActions.resetInterface());
      // dispatch(cameraActions.resetDevicesList());
    }

    //Fetch interfaceSubType DDL Data
    if (isNotNullOrEmpty(site?.selected)) {
      let siteIds = getCSIds(site?.selected);
      dispatch(fetchInterfaceSubTypeList(siteIds ?? ""));
    }
    if (isNotNullOrEmpty(interfaceSubType?.selected)) {
      let siteIds = getCSIds(site?.selected);
      let interfaceSubTypeIds = getCSIds(interfaceSubType?.selected);
      dispatch(fetchInterfaceList(interfaceSubTypeIds ?? "", siteIds ?? ""));
    }
    if (!isNotNullOrEmpty(interfaceSubType?.selected)) {
      dispatch(filterActions.resetInterface());
      // dispatch(cameraActions.resetDevicesList());
    }

    // if (deviceInterface?.selected?.length === 0) {
    //   dispatch(cameraActions.resetDevicesList());
    // }
  }, [
    customer?.selected,
    site?.selected,
    interfaceSubType?.selected,
    deviceInterface?.selected,
    savedFilters?.selected,
  ]);

  //#endregion

  //#region functions
  //Check if an object or array is not null or empty
  //Returns true if finds null or empty object, false otherwise
  const isNotNullOrEmpty = (obj) => {
    return (
      obj !== null &&
      typeof obj === "object" &&
      (Array.isArray(obj) ? obj.length > 0 : Object.keys(obj).length > 0)
    );
  };

  //Returns true if at least 1 device is checked from the list
  const isAtLeastOneDeviceChecked = () => {
    return devicesList?.filter((item) => item?.isChecked)?.length > 0;
  };

  // get comma seperated Ids
  const getCSIds = (selectedData) => {
    if (selectedData?.length > 0) {
      let clientIds = selectedData?.map((item) => item.value)?.join(",");
      return clientIds;
    } else {
      return null;
    }
  };

  //get comma seperated devices Ids
  const getCSDeviceIds = () => {
    if (devicesList?.length > 0) {
      let deviceIds = devicesList
        ?.map((item) => item?.isChecked && item.DeviceId)
        ?.join(",");
      return deviceIds;
    } else {
      return null;
    }
  };

  //Handle when filter button is clicked
  const handleFilterClick = () => {
    let clientIds = getCSIds(customer?.selected);
    let siteIds = getCSIds(site?.selected);
    let interfaceSubTypeIds = getCSIds(interfaceSubType?.selected);
    let interfaceIds = getCSIds(deviceInterface?.selected);
    let devicesIds = ""; //getCSDeviceIds();
    //send false if applying from individual filters
    dispatch(
      fetchDevicesList(
        clientIds ?? "",
        siteIds ?? "",
        interfaceSubTypeIds ?? "",
        interfaceIds ?? "",
        devicesIds,
        false
      )
    );
    dispatch(cameraActions.resetCameraDetails());
  };

  const handleClearFilterClick = () => {
    dispatch(filterActions.resetSavedFilter());
    dispatch(filterActions.resetCustomer());
    dispatch(filterActions.resetSite());
    dispatch(filterActions.resetInterfaceSubType());
    dispatch(filterActions.resetInterface());
    dispatch(cameraActions.resetDevicesList());
    dispatch(cameraActions.resetCameraDetails());
    dispatch(buttonActions.resetAllButtons());
    dispatch(cameraActions.resetCameraPlaybackList());
  };

  const handleSaveFilterModalClick = () => {
    let clientIds = getCSIds(customer?.selected);
    let siteIds = getCSIds(site?.selected);
    let interfaceSubTypeIds = getCSIds(interfaceSubType?.selected);
    let interfaceIds = getCSIds(deviceInterface?.selected);
    let deviceIds = getCSDeviceIds();

    let filterText = `clientIds:${clientIds ?? ""};siteIds:${
      siteIds ?? ""
    };interfaceSubTypeIds:${interfaceSubTypeIds ?? ""};interfaceIds:${
      interfaceIds ?? ""
    };deviceIds:${deviceIds ?? ""}`;
    if (filterName == "") {
      ErrorToast("Kindly enter a filter name");
      return;
    }
    dispatch(saveFilter(filterName, CAMERA, filterText));
    dispatch(modalActions.showSavedFilterSaveModal(false));
    dispatch(filterActions.resetFilterName());
  };

  const handleDevicesListCheckBoxChange = async (e, device) => {
    // alert("checkbox changed!")

    let { checked } = e.target;

    //false = button is enabled
    if (isSyncReplayMode()) {
      let lst = [];
      let tempDeviceList = [...devicesList];

      if (
        tempDeviceList.filter((item) => item?.isChecked).length === 1 &&
        !checked
      ) {
        lst = [
          ...tempDeviceList.map((item) => {
            return { ...item, isChecked: false };
          }),
        ];
      } else {
        lst = [
          ...tempDeviceList?.map(({ isChecked, ...rest }) => {
            let item = null;
            if (rest?.Abbreviation === device?.Abbreviation) {
              item =
                rest?.DeviceId === device?.DeviceId
                  ? { isChecked: checked, ...rest }
                  : { isChecked, ...rest };
            } else {
              item = { ...rest };
            }
            return item;
          }),
        ];
      }

      //Logic for getting playback data in sync replay mode
      let playbackList = [...cameraPlaybackList];
      if (
        checked &&
        playbackList.find(
          (item) => item?.ParentCameraID === device?.DeviceId
        ) === undefined
      ) {
        let cameraObj = await fetchCameraDetailsByIdWD(device?.DeviceId);
        if (isNotNullOrEmpty(cameraObj)) {
          playbackList.push(cameraObj);
          dispatch(cameraActions.setCameraPlaybackList(playbackList));
        }
      } else {
        let lst = [
          ...playbackList?.filter(
            (item) => item?.ParentCameraID !== device?.DeviceId && item
          ),
        ];
        dispatch(cameraActions.setCameraPlaybackList(lst));
      }

      dispatch(cameraActions.setDevicesList(lst));
      dispatch(cameraActions.showMultiPlaybackView(false));
    } else {
      const list = devicesList?.map((item) => {
        return item?.DeviceId === device?.DeviceId
          ? { ...item, isChecked: checked }
          : item;
      });
      dispatch(cameraActions.setDevicesList(list));
    }
  };

  const handleDevicesListItemClick = (e, listItem) => {
    if (isSyncReplayMode()) return; //Do nothing when in sync replay mode and clicked on device list item
    dispatch(fetchCameraDetailsById(listItem?.DeviceId));
    setStartMode(true);
  };

  const handleSelectionClick = (e) => {
    let { innerText } = e.target;
    let lst = null;
    if (innerText.toLowerCase() === CLEARSELECTION.toLowerCase()) {
      lst = devicesList?.map((item) => {
        return {
          ...item,
          isChecked: false,
        };
      });
    } else {
      lst = devicesList?.map((item) => {
        return {
          ...item,
          isChecked: true,
        };
      });
    }
    dispatch(cameraActions.setDevicesList(lst));
  };

  const handleDeviceListSearchChange = (e) => {
    let { value } = e.target;
    dispatch(cameraActions.setDeviceListSearchText(value));
  };

  const handleSavedFilterItemSelectClick = (e, item) => {
    enableSyncReplayMode(false); //Disable sync replay mode if enabled.
    dispatch(filterActions.setSavedFilterSelected(item));
    dispatch(filterActions.resetCustomer());
    dispatch(cameraActions.resetCameraDetails());
    dispatch(cameraActions.resetCameraPlaybackList());

    let filterData = parseSCSEntitiesToObect(item?.filterValues);
    if (isNotNullOrEmpty(filterData)) {
      let clientIds = filterData?.Client;
      let siteIds = filterData?.Site;
      let interfaceSubTypeIds = filterData?.Interfacesubtype;
      let interfaceIds = filterData?.Interface;
      let devicesIds = filterData?.Device;
      //send true if applying from saved filter
      dispatch(
        fetchDevicesList(
          clientIds ?? "",
          siteIds ?? "",
          interfaceSubTypeIds ?? "",
          interfaceIds ?? "",
          devicesIds ?? "",
          true
        )
      );
    }
  };

  const handleFilterNameInputChange = (e) => {
    let { value } = e.target;
    dispatch(filterActions.setFilterName(value));
  };

  const handleDeleteSavedFilterModalYesClick = (e) => {
    let filterId = savedFilters?.selected?.filterId;
    dispatch(deleteSavedFilter(filterId));
  };

  const handleDeleteSavedFilterNoClick = (e) => {
    dispatch(modalActions.showSavedFilterDeleteModal(false));
  };

  const handleViewMosaicClick = () => {
    let windowWidth = window.innerWidth;
    let windowHeight = window.innerHeight;
    let windowFeatures = `menubar=no, fullscreen=yes, location=no, toolbar=no, scrollbars=yes, resizable=yes, status=no, width=${windowWidth}}, height=${windowHeight}`;
    openChildWindow(windowFeatures);
  };

  const openChildWindow = (windowFeatures) => {
    //debugger
    let newChildWindow;
    if (childWindow == null || childWindow.name == "") {
      let data = null;
      if (isNotNullOrEmpty(savedFilters?.selected)) {
        data = parseSCSEntitiesToObect(savedFilters?.selected?.filterValues);
      } else {
        data = {
          Client: getCSIds(customer?.selected) ?? "",
          Site: getCSIds(site?.selected) ?? "",
          Interfacesubtype: getCSIds(interfaceSubType?.selected) ?? "",
          Interface: getCSIds(deviceInterface?.selected) ?? "",
          Device: getCSDeviceIds() ?? "",
        };
      }
      const filterData = {
        clientIds: data?.Client,
        siteIds: data?.Site,
        interfaceSubTypeIds: data?.Interfacesubtype,
        interfaceIds: data?.Interface,
        deviceIds: data?.Device,
      };
      let paramsStr = stringifyObjToSCSEntities(filterData);
      let filterParams = `filterText=${paramsStr}`;
      const baseUrl = MOSAIC;
      const params = new URLSearchParams(filterParams).toString();
      const url = `${baseUrl}?${params}`;
      newChildWindow = window.open(
        url,
        "mosaicWindow",
        "_blank",
        windowFeatures
      );
      setChildWindow(newChildWindow);
      sessionStorage.setItem("mosaicWindowKey", "mosaicWindow");
    } else {
      childWindow.focus();
    }
  };

  const handleClearSavedFilterClick = () => {
    // enableSyncReplayMode(false);
    dispatch(buttonActions.disableSyncReplayBtn(true));
    dispatch(buttonActions.disableClearSyncReplayBtn(true));
    dispatch(filterActions.resetSavedFilter());
    dispatch(cameraActions.resetDevicesList());
    dispatch(cameraActions.resetCameraPlaybackList());
  };

  const handleFilterItemChange = (value, filter) => {
    dispatch(cameraActions.resetCameraPlaybackList());
    // dispatch(cameraActions.resetPlaybackState());

    switch (filter) {
      case CUSTOMER:
        dispatch(filterActions?.setCustomerSelected(value));
        dispatch(filterActions.resetSavedFilter());
        dispatch(cameraActions.resetDevicesList());
        break;
      case SITE:
        dispatch(filterActions?.setSiteSelected(value));
        break;
      case INTERFACESUBTYPE:
        dispatch(filterActions.setInterfaceSubTypeSelected(value));
        break;
      case INTERFACE:
        dispatch(filterActions?.setInterfaceSelected(value));
        break;
      default:
        break;
    }
  };

  const handleSyncReplayClick = () => {
    if (devicesList?.every((obj) => obj?.hasOwnProperty("isChecked"))) {
      let lst = devicesList?.map((item) => {
        return { ...item, isChecked: false };
      });

      dispatch(cameraActions.setDevicesList(lst));
    } else {
      let lst = devicesList?.map((item) => {
        return { ...item, isChecked: false };
      });

      dispatch(cameraActions.setDevicesList(lst));
    }

    dispatch(buttonActions.disableSyncReplayBtn(true));
    dispatch(buttonActions.disableClearSyncReplayBtn(false));
    dispatch(playbackActions.resetPlaybackState());
    dispatch(cameraActions.resetCameraDetails()); //Reset camera details object when in sync replay mode
  };

  const handleClearSyncReplayClick = () => {
    if (isNotNullOrEmpty(savedFilters?.selected)) {
      let lst = devicesList?.map(({ isChecked, ...rest }) => {
        let item = null;
        if (isChecked !== undefined || isChecked !== null) {
          item = { ...rest };
        } else {
          item = { ...rest };
        }

        return item;
      });
      dispatch(cameraActions.setDevicesList(lst));
    } else {
      let lst = devicesList?.map(({ isChecked, ...rest }) => {
        return { isChecked: true, ...rest };
      });
      dispatch(cameraActions.setDevicesList(lst));
    }

    dispatch(buttonActions.disableSyncReplayBtn(false));
    dispatch(buttonActions.disableClearSyncReplayBtn(true));
    dispatch(cameraActions.showMultiPlaybackView(false));
    dispatch(cameraActions.resetCameraPlaybackList());
    dispatch(playbackActions.resetPlaybackState());
  };

  const validatePlayBackDatePickerVisibility = () => {
    return (
      devicesList?.filter(
        (item) => item?.hasOwnProperty("isChecked") && item?.isChecked && item
      ).length < 2 ||
      devicesList?.filter(
        (item) => item?.hasOwnProperty("isChecked") && item?.isChecked && item
      ).length > 4
    );
  };

  const isViewMosaicBtnEnabled = () => {
    let isEnabled = false;
    if (isSyncReplayMode()) {
      isEnabled = false;
    } else {
      if (
        isNotNullOrEmpty(savedFilters?.selected) &&
        isNotNullOrEmpty(devicesList)
      ) {
        isEnabled = true;
        // dispatch(buttonActions.disableViewMosaicBtn(false));
      } else if (
        isNotNullOrEmpty(customer?.selected) &&
        isNotNullOrEmpty(devicesList) &&
        isAtLeastOneDeviceChecked()
      ) {
        isEnabled = true;
      } else {
        isEnabled = false;
        // dispatch(buttonActions.disableViewMosaicBtn(true));
      }
    }

    return isEnabled;
  };

  //Disable sync replay mode
  const enableSyncReplayMode = (isEnabled) => {
    dispatch(buttonActions.disableSyncReplayBtn(isEnabled));
    dispatch(buttonActions.disableClearSyncReplayBtn(!isEnabled));
  };

  //Check if sync replay mode is enabled
  const isSyncReplayMode = () => {
    return visibleSyncReplayBtn && !visibleClearSyncReplayBtn;
  };

  const isSaveFilterBtnEnabled = () => {
    let isEnabled = false;
    if (isNotNullOrEmpty(savedFilters?.selected)) {
      isEnabled = false;
    } else if (isNotNullOrEmpty(customer?.selected)) {
      if (isNotNullOrEmpty(devicesList) && isAtLeastOneDeviceChecked()) {
        isEnabled = true;
      }
    } else {
      isEnabled = false;
    }
    return isEnabled;
  };

  const shouldFilterAndClearFilterBtnsBeEnabled = () => {
    let isEnabled = false;
    if (isNotNullOrEmpty(savedFilters?.selected)) {
      isEnabled = false;
    }

    if (isNotNullOrEmpty(customer?.selected)) {
      isEnabled = true;
    }
    return isEnabled;
  };

  //playback function

  const handlePlaybackClick = () => {
    if (new Date(startDate) > new Date(endDate)) {
      ErrorToast("Start time should not be greater than the end time!");
      return;
    }

    //Force playback to take end date 15 minutes ahead of start date
    let playbackEndDate = null;
    if (new Date(startDate) < new Date(endDate)) {
      playbackEndDate = addMinutesToDate(startDate, defaultEndDate).toString();
    }

    let startDateStr = formatJSDateObject(startDate);
    let endDateStr = formatJSDateObject(playbackEndDate);

    let playbackUrl = [];
    for (let i = 0; i < cameraPlaybackList?.length; i++) {
      let obj = {
        action: "StartPlaybackUrl",
        startTime: startDateStr.replace(/[ :-]/g, "_"),
        endTime: endDateStr.replace(/[ :-]/g, "_"),
        deviceId: cameraPlaybackList[i]?.ParentCameraID,
        alertId: "",
      };

      playbackUrl.push(obj);
    }

    let tempPlaybackList = [...cameraPlaybackList];
    Promise.all(playbackUrl.map((item) => getstreamingurl(item)))
      .then((res) => {
        let lst = [
          ...tempPlaybackList.map((item, index) => {
            return { ...item, playbackURL: res[index]?.rtspURL };
          }),
        ];

        dispatch(cameraActions.setCameraPlaybackList(lst));
        setPopOpen(false);
        dispatch(cameraActions.showMultiPlaybackView(true));
      })
      .catch((err) => console.log("Error while fertching stream url => ", err));

    dispatch(playbackActions.setSelectedStartDate(startDate));
    dispatch(playbackActions.setSelectedEndDate(playbackEndDate));

    dispatch(playbackActions.setStartDate(""));
    dispatch(playbackActions.setEndDate(""));
    dispatch(playbackActions.setTimerInputTimeString(""));
  };

  //#endregion

  const onClickSyncReplay = () => {
    setIsCheckBoxVisible(true);
  };
  const onClickPrevList = () => {
    setIsCheckBoxVisible(false);
  };

  const filterToolDDLProps = {
    visibleDDLSingleSelect: true,
    visibleDDLMultiSelect: true,

    DDLSingleSelectProps: {
      labelledBy:
        savedFilters?.selected?.filterName !== undefined
          ? `${SAVEDFILTER}: ` + savedFilters?.selected?.filterName
          : `${SAVEDFILTER}`,
      selected: isNotNullOrEmpty(savedFilters?.selected)
        ? savedFilters?.selected
        : null,
      emptyOptionLabel: "No Saved Filters",
      handleClearSelectedClick: handleClearSavedFilterClick,
      clearDisabled: !isNotNullOrEmpty(savedFilters?.selected),
      options: savedFilters?.options,
      valueRenderer: null,
      // onChange: (value) => setSelected(value),
      valueRenderer: null,
      itemRenderer: (item) => (
        <>
          <div
            className="SavedFilterOption"
            onClick={(e) => handleSavedFilterItemSelectClick(e, item)}
            key={item?.filterId}
          >
            <span>{item?.filterName}</span>
            <div className="SaveFilterOtionHover">
              <button
                style={{ background: "transparent" }}
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(filterActions.setSavedFilterSelected(item));
                  dispatch(modalActions.showSavedFilterDeleteModal(true));
                }}
              >
                <img src={DeleteIcon} />
              </button>
              <button
                style={{ background: "transparent" }}
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(cameraActions.toggleInfoFilterModal(true));
                  infoDataGet(item);
                  dispatch(cameraActions.setFilterInfoName(item.filterName));
                }}
              >
                <img src={InfoIcon1} />
              </button>
            </div>
          </div>
        </>
      ),
    },
    filterList: [
      {
        labelledBy: CUSTOMER,
        options: customer?.options,
        value: customer?.selected,
        // onChange: (value) => setSelected(value),
        onChange: (value) => handleFilterItemChange(value, CUSTOMER),
        valueRenderer: null,
        className:
          customer?.selected.length > 0
            ? "dropdownSelectionContainer multi-select"
            : "multi-select",
      },
      {
        labelledBy: SITE,
        options: site?.options,
        value: site?.selected,
        onChange: (value) => handleFilterItemChange(value, SITE),
        valueRenderer: null,
        //disabled: customer?.selected?.length === 0
        className:
          site?.selected.length > 0
            ? "dropdownSelectionContainer multi-select"
            : "multi-select",
      },
      {
        labelledBy: INTERFACESUBTYPE,
        options: interfaceSubType?.options,
        value: interfaceSubType?.selected,
        onChange: (value) => handleFilterItemChange(value, INTERFACESUBTYPE),
        valueRenderer: null,
        //disabled: site?.selected?.length === 0
        className:
          interfaceSubType?.selected.length > 0
            ? "dropdownSelectionContainer multi-select"
            : "multi-select",
      },
      {
        labelledBy: INTERFACE,
        options: deviceInterface?.options,
        value: deviceInterface?.selected,
        onChange: (value) => handleFilterItemChange(value, INTERFACE),
        valueRenderer: null,
        //disabled: interfaceSubType?.selected?.length === 0
        className:
          deviceInterface?.selected.length > 0
            ? "dropdownSelectionContainer multi-select"
            : "multi-select",
      },
    ],
  };

  const data = [
    "Eugenia",
    "Bryan",
    "Linda",
    "Nancy",
    "Lloyd",
    "Alice",
    "Julia",
    "Albert",
  ].map((item) => ({ label: item, value: item }));

  const CLIENT = "Client";
  const SITEINFO = "Site";
  const INTERFACESUBTYPEINFO = "Interface sub type";
  const INTERFACENAME = "Interface";

  const searchHandler = (e, type) => {
    if (type == CLIENT) {
      dispatch(cameraActions?.setCustomerSearch(e.target.value));
      let tempCustomer = customerInfo?.master.filter((x) =>
        x.Values.toLowerCase().includes(e.target.value.toLowerCase())
      );
      dispatch(cameraActions?.setCustomerChildInfo(tempCustomer));
    } else if (type == SITEINFO) {
      dispatch(cameraActions?.setSiteSearch(e.target.value));
      let tempSite = siteInfo?.master.filter((x) =>
        x.Values.toLowerCase().includes(e.target.value.toLowerCase())
      );
      dispatch(cameraActions?.setSiteChildInfo(tempSite));
    } else if (type == INTERFACESUBTYPEINFO) {
      dispatch(cameraActions?.setInterfaceTypeSearch(e.target.value));
      let tempInterfaceType = interfaceSubTypeInfo?.master.filter((x) =>
        x.Values.toLowerCase().includes(e.target.value.toLowerCase())
      );
      dispatch(cameraActions?.setInterfaceTypeChildInfo(tempInterfaceType));
    } else if (type == INTERFACENAME) {
      dispatch(cameraActions?.setInterfaceNameSearch(e.target.value));
      let tempInterfaceName = interfaceNameInfo?.master.filter((x) =>
        x.Values.toLowerCase().includes(e.target.value.toLowerCase())
      );
      dispatch(cameraActions?.setInterfaceNameChildInfo(tempInterfaceName));
    }
  };

  const infoDataGet = (item) => {
    getInfoList(item?.filterId)
      .then((res) => {
        if (res.Status == "success") {
          let temp = JSON.parse(res.Data);
          infoDataFilter(temp);
        } else {
        }
      })
      .catch((err) => console.log(err));
  };

  const infoDataFilter = (arr) => {
    let customerArr = arr.filter((item) => item.Entity == CLIENT);
    dispatch(cameraActions?.setCustomerMasterInfo(customerArr));
    dispatch(cameraActions?.setCustomerChildInfo(customerArr));
    let siteArr = arr.filter((item) => item.Entity == SITEINFO);
    dispatch(cameraActions?.setSiteMasterInfo(siteArr));
    dispatch(cameraActions?.setSiteChildInfo(siteArr));
    let interfaceSubTypeArr = arr.filter(
      (item) => item.Entity == INTERFACESUBTYPEINFO
    );
    dispatch(cameraActions?.setInterfaceTypeMasterInfo(interfaceSubTypeArr));
    dispatch(cameraActions?.setInterfaceTypeChildInfo(interfaceSubTypeArr));
    let interfaceNameArr = arr.filter((item) => item.Entity == INTERFACENAME);
    dispatch(cameraActions?.setInterfaceNameMasterInfo(interfaceNameArr));
    dispatch(cameraActions?.setInterfaceNameChildInfo(interfaceNameArr));
  };

  return (
    <>
      <div className="MainContentMargin">
        <div
          className="row col-lg-12"
          style={{ margin: "0 auto", padding: "0px" }}
        >
          <div className="col-lg-12 dashboardHeadingInnerDiv">
            <div className="NameHeading1">
              <p>Camera</p>
            </div>
          </div>
        </div>
        <div className="col-lg-12 mt-2 mb-2">
          <div className="SiteMainContainer">
            <div
              className="soundContainer cameraFilterPage"
              style={{ padding: "10px" }}
            >
              <div className="FilteredCameraListDiv">
                <FilterToolDDL {...filterToolDDLProps} />
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip>Apply Filter</Tooltip>}
                >
                  <button
                    className={`${
                      visibleFilterBtn ? "buttonDisable" : "IconsButtonDiv"
                    }`}
                    name="filterBtn"
                    disabled={visibleFilterBtn}
                    onClick={handleFilterClick}
                  >
                    <img
                      src={FilterIcon}
                      className="camera-icon-height"
                      alt="Save filter icon"
                    />
                  </button>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip>Clear Filter</Tooltip>}
                >
                  <button
                    className={`${
                      visibleClearFilterBtn ? "buttonDisable" : "IconsButtonDiv"
                    }`}
                    name="clearFilterBtn"
                    disabled={visibleClearFilterBtn}
                    onClick={handleClearFilterClick}
                  >
                    <img
                      src={ClearFilter}
                      className="camera-icon-height"
                      alt="Save filter icon"
                    />
                  </button>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip>Save Filter</Tooltip>}
                >
                  <button
                    className={`${
                      visibleSaveFilterBtn ? "buttonDisable" : "IconsButtonDiv"
                    }`}
                    name="saveFilterBtn"
                    disabled={visibleSaveFilterBtn}
                    onClick={() =>
                      dispatch(modalActions.showSavedFilterSaveModal(true))
                    }
                  >
                    <img
                      src={SaveFilter}
                      className="camera-icon-height"
                      alt="Save filter icon"
                    />
                  </button>
                </OverlayTrigger>
                {!visibleSyncReplayBtn && (
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip>Sync Replay</Tooltip>}
                  >
                    {isMultiPlay == false && (
                      <button
                        className={`${
                          visibleSyncReplayBtn ? "buttonDisable" : "IconsButtonDiv"
                        }`}
                        name="syncReplayBtn"
                        onClick={handleSyncReplayClick}
                        // disabled={visibleSyncReplayBtn}
                      >
                        <img
                          src={SyncReply}
                          className="camera-icon-height"
                          alt="Sync reply icon"
                        />
                      </button>
                    )}
                  </OverlayTrigger>
                )}
                {!visibleClearSyncReplayBtn && (
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip>Clear Sync Replay</Tooltip>}
                  >
                    {isMultiPlay == false && (
                      <button
                        className={`${
                          visibleClearSyncReplayBtn ? "buttonDisable" : "IconsButtonDiv"
                        }`}
                        name="clearSyncReplayBtn"
                        onClick={handleClearSyncReplayClick}
                        // disabled={visibleClearSyncReplayBtn}
                      >
                        <img
                          src={CancelSyncReply}
                          className="camera-icon-height"
                          alt="Sync reply icon"
                        />
                      </button>
                    )}
                  </OverlayTrigger>
                )}
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip>View Mosaic</Tooltip>}
                >
                  <button
                    className={`${
                      visibleViewMosaicBtn ? "buttonDisable" : "IconsButtonDiv"
                    }`}
                    name="viewMosaicBtn"
                    disabled={visibleViewMosaicBtn}
                    // disabled={!isNotNullOrEmpty(savedFilters.selected) && isNotNullOrEmpty(devicesList)}
                    onClick={handleViewMosaicClick}
                  >
                    <img
                      src={ViewMosaic}
                      className="camera-icon-height"
                      alt="Alert filter icon"
                    />
                  </button>
                </OverlayTrigger>
              </div>
              <div className="ListFilterCamera">
                {/* <div style={{ display: "none" }}>
                  <AlertTemplateMessage
                    message={
                      "Apply a saved filter or use other criteria filters to view the device list.."
                    }
                  />
                </div> */}
                {/* <div style={{ display: "none" }}>
                  <AlertTemplateMessage
                    message={
                      "Check one or more cameras from the list to save as a filter. Click a camera name to view live feed and perform actions."
                    }
                  />
                </div> */}
                {/* <div style={{ display: "none" }}>
                  <AlertTemplateMessage
                    message={
                      "Click a camera name to view live feed and perform actions."
                    }
                  />
                </div> */}
                {devicesList?.length === 0 ? (
                  <>
                    <div style={{ width: "100%" }}>
                      <AlertTemplateMessage
                        message={
                          "Apply a saved filter or use other criteria filters to view the device list.."
                        }
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="CameraLeftContainer mr-3">
                      {devicesList?.some((item) =>
                        item?.hasOwnProperty("isChecked")
                      ) && (
                        <div className="selectCountClearSelection">
                          <div>
                            <p className="ListofText">
                              {
                                devicesList?.filter((item) => item?.isChecked)
                                  ?.length
                              }{" "}
                              <span className="SiteNameText"> Selected of</span>{" "}
                              {devicesList?.length}
                            </p>
                          </div>
                          {!isSyncReplayMode() && (
                            <div
                              className="clearSelection"
                              onClick={handleSelectionClick}
                            >
                              {devicesList?.some((item) => item?.isChecked)
                                ? CLEARSELECTION
                                : SELECTALL}
                            </div>
                          )}
                        </div>
                      )}
                      <CameraSidePanelList
                        data={devicesList}
                        // isCheckBoxVisible={isNotNullOrEmpty(devicesList) && devicesList?.some(item => item?.hasOwnProperty("isChecked"))}
                        handleCheckBoxChange={handleDevicesListCheckBoxChange}
                        handleListItemClick={handleDevicesListItemClick}
                        handleListSearchChange={handleDeviceListSearchChange}
                        listSearchText={deviceListSearchText}
                      />
                    </div>

                    <div className="CameraRightContainer">
                      {visibleClearSyncReplayBtn &&
                        !isNotNullOrEmpty(savedFilters?.selected) &&
                        devicesList?.filter((item) => item?.isChecked)
                          ?.length === 0 &&
                        cameraDetails === null && (
                          <>
                            <div style={{ marginBottom: "10px" }}>
                              <AlertTemplateMessage
                                message={
                                  "Check one or more cameras from the list to save as a filter."
                                }
                              />
                            </div>
                          </>
                        )}

                      {visibleClearSyncReplayBtn &&
                        // (isNotNullOrEmpty(savedFilters?.selected) || devicesList?.filter(item => item?.isChecked)?.length === 0) &&
                        cameraDetails === null && (
                          <>
                            <div style={{ marginBottom: "10px" }}>
                              <AlertTemplateMessage
                                message={
                                  "Click a camera name to view live feed and perform actions."
                                }
                              />
                            </div>
                          </>
                        )}

                      {!visibleClearSyncReplayBtn &&
                        validatePlayBackDatePickerVisibility() && (
                          <>
                            <div
                              style={{
                                padding: "0px 20px",
                                display: "block",
                                marginBottom: "10px",
                              }}
                            >
                              <AlertTemplateMessage
                                message={
                                  "Select a minimum of 2 and a maximum of 4 cameras of the same time zone to sync and replay."
                                }
                              />
                            </div>
                          </>
                        )}

                      {isSyncReplayMode() &&
                        !validatePlayBackDatePickerVisibility() &&
                        isNotNullOrEmpty(
                          cameraPlaybackList?.filter(
                            (item) => !isNotNullOrEmpty(item?.playbackURL)
                          )
                        ) && (
                          <>
                            <div
                              className="playback__datepickers"
                              // style={{ boxShadow: "none", display: "block" }}
                            >
                              <div className="singleInputDiv">
                                <span>
                                  {selectedStartDate
                                    ? formatJSDateInDDMMYYYY(selectedStartDate)
                                    : "Select date and time"}
                                </span>
                                <img
                                  src={calendar}
                                  onClick={() => setPopOpen(true)}
                                />
                              </div>
                              {selectedEndDate && (
                                <>
                                  <img
                                    className="mx-3"
                                    src={DateTimeArrow}
                                    alt="DateTimeArrow"
                                  />
                                  <p
                                    className="mx-3"
                                    id="endDateStringSinglePlayback"
                                  >
                                    {/* {frontDateConverter(endDateString)} */}
                                    {/* {ConvertDate(dateTwo)} */}
                                    {formatJSDateInDDMMYYYY(selectedEndDate)}
                                  </p>
                                </>
                              )}
                              {/* <form className="singleInputDiv">
                                  <input
                                    // value={frontDateConverter(startDateString)}
                                    value={frontDateConverter(new Date().toISOString())}
                                    className="singleInput"
                                  />
                                  <img src={calendar} onClick={() => setPopOpen(true)} />
                                </form> */}
                            </div>
                          </>
                        )}

                      {/* <div className="CameraRightContainer">
                          <div style={{ position: "relative", display: "block" }}> */}

                      {!isSyncReplayMode() &&
                        isNotNullOrEmpty(cameraDetails) && (
                          <>
                            <div className="MainContainerSectionCamera">
                              <div className="cameraDetailDiv" style={{height:"auto", marginBottom:"0"}}>
                                <div className="PanelDetailMain">
                                  <div className="PanelDetailDiv">
                                    <p>
                                      Camera Name{" "}
                                      <span>
                                        {" "}
                                        - {cameraDetails?.CameraName}
                                      </span>
                                    </p>
                                  </div>
                                  <div className="PanelDetailDiv">
                                    <p>
                                      Customer Name{" "}
                                      <span>
                                        {" "}
                                        - {cameraDetails?.ClientName}
                                      </span>
                                    </p>
                                  </div>
                                  <div className="PanelDetailDiv">
                                    <p>
                                      Site Name{" "}
                                      <span> - {cameraDetails?.SiteName}</span>
                                    </p>
                                  </div>
                                  <div className="PanelDetailDiv">
                                    <p>
                                      Brand Name{" "}
                                      <span> - {cameraDetails?.BrandName}</span>
                                    </p>
                                  </div>
                                  <div className="PanelDetailDiv">
                                    <p>
                                      Communication Port{" "}
                                      <span>
                                        {" "}
                                        - {cameraDetails?.CommunicationPort}
                                      </span>
                                    </p>
                                  </div>
                                  <div className="PanelDetailDiv">
                                    <p>
                                      Http Port{" "}
                                      <span> - {cameraDetails?.httpPort}</span>
                                    </p>
                                  </div>
                                  <div className="PanelDetailDiv">
                                    <p>
                                      Rtsp Port{" "}
                                      <span> - {cameraDetails?.camPort}</span>
                                    </p>
                                  </div>
                                  <div className="PanelDetailDiv">
                                    <p>
                                      Timezone{" "}
                                      <span>
                                        {" "}
                                        - {cameraDetails?.Abbreviation}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                                <div className="PanelDetailMain">
                                  
                                  {!startMode ? (
                                    <div
                                      className="playback__datepickers"
                                      style={{ boxShadow: "none" }}
                                    >
                                      <form className="singleInputDiv">
                                        <input
                                          value={frontDateConverter(
                                            startDateString
                                          )}
                                          className="singleInput"
                                        />
                                      </form>
                                      <img
                                        src={DateTimeArrow}
                                        alt="DateTimeArrow"
                                      />
                                      <p id="endDateStringSinglePlayback">
                                        {frontDateConverter(endDateString)}
                                      </p>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              <>
                                {!cameraDetails ? (
                                  <SkelSinglePlayback></SkelSinglePlayback>
                                ) : (
                                  <WebRTCPlayer
                                    key={cameraDetails?.ParentCameraID}
                                    id={cameraDetails.ParentCameraID}
                                    url={cameraDetails.RTSPURL}
                                    isEncode={cameraDetails.isTranscoded}
                                    item={cameraDetails}
                                    upperDivCss="singleCameraHover"
                                    videoCss="mosaic"
                                    fullVideoCss="player-responsive-fullScreen"
                                    buttonCss="mosaicButtonsMainDiv"
                                    noStreamDivCss="singleplaybackNoStream"
                                    playback={true}
                                    showPlaybackBtn={true}
                                    // prePostDisplay={false}
                                    liveButton={true}
                                    setStartDateString={setStartDateString}
                                    setEndDateString={setEndDateString}
                                    setStartMode={setStartMode}
                                    showHooterIcon={startMode ? true : false}
                                    showAlertCreation={startMode ? true : false}
                                    exportCurrentVideoHide={true}
                                    // isSinglePlayback={true}
                                    // videoExportDownload={() => onSubmitBtn()}
                                    // exportStartTime={() => exportStartTime()}
                                    // exportEndTime={() =>exportEndTime()}
                                    // secondsDiff={() =>differenceTimeSec()}
                                  />
                                )}
                              </>
                            </div>
                          </>
                        )}
                      {/* {visibleSyncReplayBtn && !visibleClearSyncReplayBtn && isNotNullOrEmpty(cameraPlaybackList) && */}
                      {visibleMultiPlaybackView && (
                        <MultiplaybackView playbackList={cameraPlaybackList} />
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Modal for Playback */}
        <Modal
          show={popOpen}
          onHide={() => {
            setPopOpen(false);
            dispatch(playbackActions.setStartDate(""));
            dispatch(playbackActions.setEndDate(""));
            dispatch(playbackActions.setTimerInputTimeString(""));
            // dispatch(playbackActions.resetPlaybackState())
          }}
          className="singleplaybackexportModal"
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <p className="ModelHeaderName">Playback</p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Playback
              handlePlaybackClick={handlePlaybackClick}
              selectedDeviceTimeZoneOffsetMinutes={
                cameraPlaybackList[0]?.OffsetMinutes
              }
            />
          </Modal.Body>
        </Modal>

        {/* Modal popup for save filter */}
        <Modal
          size="lg"
          show={showSavedFilterSaveModal}
          onHide={() => dispatch(modalActions.showSavedFilterSaveModal(false))}
          dialogClassName="modal-90w"
          backdrop="static"
          className="saveCameraFilterModal"
        >
          <>
            <Modal.Header>
              <Modal.Title id="example-custom-modal-styling-title">
                Save Filter
              </Modal.Title>
              <img
                src={RemoveIcon}
                alt="Remove"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  dispatch(modalActions.showSavedFilterSaveModal(false))
                }
              />
            </Modal.Header>

            <Modal.Body>
              <div className="inputMainContainer w40dot75rem w-100">
                <div className="inputTypeLabelContainer">
                  <label className="formFieldLabels">Filter Name</label>
                  <input
                    style={{ width: "40vw" }}
                    type="text"
                    className="inputType"
                    value={filterName}
                    placeholder="Ex. Filter for xyz company with 10 sites"
                    // {...register("AddCustomerName")}
                    onChange={handleFilterNameInputChange}
                  />
                  <p className="Error_P" style={{ float: "left" }}>
                    <span>{}</span>
                  </p>
                </div>
              </div>
              <div style={{textAlign: "center"}}>
              <button
                //disabled={filterName === ""}
                className="DataAccessEdit mt-3"
                style={{ width: "250px" }}
                onClick={handleSaveFilterModalClick}
              >
                <span className="ViewUpdate">Save</span>
              </button>
              </div>
            </Modal.Body>
          </>
        </Modal>

        {/* Modal popup for delete of scheduler */}
        <Modal
          size="lg"
          show={showSavedFilterDeleteModal}
          onHide={() =>
            dispatch(modalActions.showSavedFilterDeleteModal(false))
          }
          className="deleteModalMainClass"
          backdrop="static"
          // centered
        >
          <Modal.Body>
            <div className="ModalBodyDiv">
              <div style={{ padding: "37px" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "1rem",
                  }}
                >
                  <img
                    className="informationIcon infoorangeicon"
                    src={WarningDelete}
                    alt="Info"
                  />
                  <div style={{ width: "100%" }}>
                    <h3 className="No-Data-Access-Group">
                      Delete Saved Filter!
                    </h3>
                  </div>
                </div>
                <p className="DeleteText mb-4">
                  Are you sure you want to delete the saved filter
                  <br />
                  <span className="font-weight-bold">
                    {savedFilters?.selected?.filterName}
                  </span>
                </p>
                <div
                  style={{
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <button
                    className="DataAccessEdit ml-0"
                    style={{ width: "45%", backgroundColor: "#FB3030" }}
                    onClick={handleDeleteSavedFilterModalYesClick}
                  >
                    <span className="ViewUpdate">Yes</span>
                  </button>
                  <button
                    onClick={handleDeleteSavedFilterNoClick}
                    className="DataAccessEdit ml-0"
                    style={{ width: "45%" }}
                  >
                    <span className="ViewUpdate">No</span>
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* Modal popup for info */}
        <Modal
          size="xl"
          show={showInfoFilterModal}
          onHide={() => dispatch(cameraActions.toggleInfoFilterModal(false))}
          className="deleteModalMainClass"
          backdrop="static"
          // centered
          scrollable
        >
          <Modal.Header>
            <Modal.Title id="example-custom-modal-styling-title">
              {filterInfoName} - info
            </Modal.Title>
            <img
              src={RemoveIcon}
              alt="Remove"
              style={{ cursor: "pointer" }}
              onClick={() => {
                dispatch(cameraActions.toggleInfoFilterModal(false));
              }}
            />
          </Modal.Header>
          <Modal.Body>
            <div id="mainContainer" className="main-container">
              {customerInfo?.master.length > 0 ? (
                <div className="SiteLeftContainer ml-2 mr-2">
                  <div className="infoFilterHeader">
                    <p>Customer</p>
                  </div>
                  <div className="m-2 HeaderSiteList">
                    <input
                      id="Search_txt_site"
                      className="SearchViewInput1"
                      type="text"
                      placeholder="Search Customer"
                      value={customerInfo.customerSearch}
                      onChange={(e) => searchHandler(e, CLIENT)}
                    />
                    <div className="SearchTextDivConfigTool">
                      {isDarkTheme ? (
                        <img
                          className="EyeIcon"
                          src={SearchIcon}
                          alt="search icon"
                        />
                      ) : (
                        <img
                          className="EyeIcon"
                          src={SearchIconWhite}
                          alt="search icon"
                          height="15"
                        />
                      )}
                    </div>
                  </div>
                  <div className="SaveFilterInfoList">
                    {customerInfo?.child && customerInfo?.child?.length > 0 ? (
                      <div>
                        {customerInfo?.child.map((item) => (
                          <p className="ListofText">{item.Values}</p>
                        ))}
                      </div>
                    ) : (
                      <div className="no-data-found">
                        <p>No Customer Found.</p>
                      </div>
                    )}
                    {!customerInfo?.child && (
                      <div className="no-data-found">
                        No Customer Available.
                      </div>
                    )}
                  </div>
                </div>
              ) : null}

              {siteInfo?.master.length > 0 ? (
                <div className="SiteLeftContainer ml-2 mr-2">
                  <div className="infoFilterHeader">
                    <p>Site</p>
                  </div>
                  <div className="m-2 HeaderSiteList">
                    <input
                      id="Search_txt_site"
                      className="SearchViewInput1"
                      type="text"
                      placeholder="Search Site"
                      value={siteInfo.siteSearch}
                      onChange={(e) => searchHandler(e, SITEINFO)}
                    />
                    <div className="SearchTextDivConfigTool">
                      {isDarkTheme ? (
                        <img
                          className="EyeIcon"
                          src={SearchIcon}
                          alt="search icon"
                        />
                      ) : (
                        <img
                          className="EyeIcon"
                          src={SearchIconWhite}
                          alt="search icon"
                          height="15"
                        />
                      )}
                    </div>
                  </div>
                  <div className="SaveFilterInfoList">
                    {siteInfo?.child && siteInfo?.child.length > 0 ? (
                      <div>
                        {siteInfo?.child.map((item) => (
                          <p className="ListofText">{item.Values}</p>
                        ))}
                      </div>
                    ) : (
                      <div className="no-data-found">
                        <p>No Site Found.</p>
                      </div>
                    )}
                    {!siteInfo.child && (
                      <div className="no-data-found">No Sites Available.</div>
                    )}
                  </div>
                </div>
              ) : null}

              {interfaceSubTypeInfo?.master.length > 0 ? (
                <div className="SiteLeftContainer ml-2 mr-2">
                  <div className="infoFilterHeader">
                    <p>Interface Sub Type</p>
                  </div>
                  <div className="m-2 HeaderSiteList">
                    <input
                      id="Search_txt_site"
                      className="SearchViewInput1"
                      type="text"
                      placeholder="Search Interface Sub Type"
                      value={interfaceSubTypeInfo.interfaceSubTypeSearch}
                      onChange={(e) => searchHandler(e, INTERFACESUBTYPEINFO)}
                    />
                    <div className="SearchTextDivConfigTool">
                      {isDarkTheme ? (
                        <img
                          className="EyeIcon"
                          src={SearchIcon}
                          alt="search icon"
                        />
                      ) : (
                        <img
                          className="EyeIcon"
                          src={SearchIconWhite}
                          alt="search icon"
                          height="15"
                        />
                      )}
                    </div>
                  </div>
                  <div className="SaveFilterInfoList">
                    {interfaceSubTypeInfo.child &&
                    interfaceSubTypeInfo.child?.length > 0 ? (
                      <div>
                        {interfaceSubTypeInfo.child.map((item) => (
                          <p className="ListofText">{item.Values}</p>
                        ))}
                      </div>
                    ) : (
                      <div className="no-data-found">
                        <p>No Interface Sub Type Found.</p>
                      </div>
                    )}
                    {!interfaceSubTypeInfo.child && (
                      <div className="no-data-found">
                        No Interface Sub Type Available.
                      </div>
                    )}
                  </div>
                </div>
              ) : null}
              {interfaceNameInfo.master.length > 0 ? (
                <div className="SiteLeftContainer ml-2 mr-2">
                  <div className="infoFilterHeader">
                    <p>Interface Name</p>
                  </div>
                  <div className="m-2 HeaderSiteList">
                    <input
                      id="Search_txt_site"
                      className="SearchViewInput1"
                      type="text"
                      placeholder="Search Interface Name"
                      value={interfaceNameInfo.interfaceNameSearch}
                      onChange={(e) => searchHandler(e, INTERFACENAME)}
                    />
                    <div className="SearchTextDivConfigTool">
                      {isDarkTheme ? (
                        <img
                          className="EyeIcon"
                          src={SearchIcon}
                          alt="search icon"
                        />
                      ) : (
                        <img
                          className="EyeIcon"
                          src={SearchIconWhite}
                          alt="search icon"
                          height="15"
                        />
                      )}
                    </div>
                  </div>
                  <div className="SaveFilterInfoList">
                    {interfaceNameInfo.child &&
                    interfaceNameInfo.child?.length > 0 ? (
                      <div>
                        {interfaceNameInfo.child.map((item) => (
                          <p className="ListofText">{item.Values}</p>
                        ))}
                      </div>
                    ) : (
                      <div className="no-data-found">
                        <p>No Interface Name Found.</p>
                      </div>
                    )}
                    {!interfaceNameInfo.child && (
                      <div className="no-data-found">
                        No interface Name Available.
                      </div>
                    )}
                  </div>
                </div>
              ) : null}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};
